import { exhaustive } from "exhaustive"
import { cloneDeep, isEmpty, map, sum, sumBy } from "lodash"
import { FC, MutableRefObject, useCallback, useEffect, useRef, useState } from "react"
import { useMediaQuery } from "react-responsive"
import { useLocation, useSearchParams } from "react-router-dom"
import { z } from "zod"
import { AbsolutCentered } from "../../AbsolutCentered/AbsolutCentered"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import { ProductCategoryInstance } from "../../Client/ClientInstance"
import { useConsumerCatalog } from "../../Client/ConsumerCatalogContext"
import {
	GoodsProductDefinition,
	ProductSelectionConfigSecondaryStep,
	ProductSelectionConfigStep,
	ProductServiceUnit,
	WasteProductDefinition,
} from "../../Client/ProductDefinitionsByCategories"
import { GetProject } from "../../CustomerPortal/CustomerPortalProjectsManager/CustomerPortalProjectsManager"
import { ArrowLeftIcon, VarukorgExtraWideIcon, VarukorgIcon, VarukorgWideIcon } from "../../Icons/Icon"
import { Loader } from "../../Loader/Loader"
import { cls } from "../../Shared/cls"
import { indexWithinBounds } from "../../Shared/indexWithinBounds"
import { notNull } from "../../Shared/notNull"
import { currencyFormatter } from "../../Shared/numberFormatter"
import { useBrandedLocalStorage } from "../../Shared/useBrandedLocalStorage"
import { when } from "../../Shared/when"
import { WidgetRendererV2 } from "../../Shared/Widget/Widgets"
import { ExpandableDropdownRefProps } from "../Components/ExpandableDropdown/ExpandableDropdown"
import { ExpandableIncrementorRefProps } from "../Components/ExpandableIncrementor/ExpandableIncrementor"
import { FinalizeButton } from "../Components/Form/Buttons/Buttons"
import { MultiStateSwitch } from "../Components/Form/MultiStateSwitch/MultiStateSwitch"
import { ExpandableIncrementorPillRefProps } from "../Components/IncrementorPill/IncrementorPill"
import { DateSelectModule } from "../DateSelectModule/DateSelectModule"
import { getBy } from "../Helpers"
import { Project } from "../order-data-model"
import {
	goodsCategoryPackagingSelectionElement,
	ProductInformationAndSelectionModule,
	ProductSelectionReturnValue,
	wasteCategoryAmountSelectionElement,
	wasteCategoryNonDiscreteAmountSelectionElement,
	wasteCategoryWasteSelectionElement,
} from "../ProductInformationAndSelectionModule/ProductInformationAndSelectionModule"
import { TimeSelectModule } from "../TimeSelectModule/TimeSelectModule"
import { useBasket } from "./BasketProvider"
import style from "./FullPageProductSelection.module.css"
import { getAmountOfProductsInOrderItem } from "./Logic"
import { OpenModalTypes, removeModalOpen } from "./OrderContainer"
import { modalOpenOrderItemIndexKey, modalOpenQueryKey } from "./OrderContainerWrapper"
import { allowedDateOrTimeSelectValues, handleQueryParamsOrderItemEditTime } from "./ProductSelectionLogic"
import {
	AmountWasteProductDefinitionWithPrice,
	ProductDefinitionWithPrice,
	resolveProductDefinitionsWithPriceData,
	WasteProductDefinitionWithPrice,
	WasteWasteProductDefinitionWithPrice,
} from "./resolveProductDefinitionsWithPriceData"
import { ProjectSelection, ProjectSelectionRefProps } from "./SubComponents/ProjectSelection"

type Props = {
	product: WasteProductDefinition | GoodsProductDefinition
	category: ProductCategoryInstance
	onCancel: () => void
}
export const FullPageProductSelection: FC<Props> = (props: Props) => {
	const client = useClient()
	const auth = useAuth()
	const location = useLocation()
	const consumerCatalog = useConsumerCatalog()
	const basket = useBasket()
	const isMobileSize = useMediaQuery({ query: `(max-width: 1100px)` })

	const [queryParams, setQueryParams] = useSearchParams()
	const previousQueryParams = useRef<URLSearchParams>(queryParams)

	const projectSelectionComponentRef: MutableRefObject<ProjectSelectionRefProps | null> = useRef(null)

	const [selectorRefs, setSelectorRefs] = useState<
		(ExpandableIncrementorRefProps | ExpandableIncrementorPillRefProps | ExpandableDropdownRefProps)[]
	>([])

	const [showDateSelectModule, setShowDateSelectModule] = useState(false)
	const [showTimeSelectModule, setShowTimeSelectModule] = useState(false)

	const closeAllModals = useCallback(
		(closeMobileBasket: boolean = true) => {
			projectSelectionComponentRef.current?.setShowProjectSelectModule(false, () => {})
			projectSelectionComponentRef.current?.setEditProject(null)

			setShowDateSelectModule(false)
			setShowTimeSelectModule(false)

			if (closeMobileBasket) {
				basket.functions.setMobileBasketShown(false)
			}
		},
		[basket.functions],
	)

	useEffect(() => {
		const element = document.getElementById("mainLayoutPageContent")
		if (element) {
			element.scrollTop = 0
		}
	}, [])

	useEffect(() => {
		const indexParam = queryParams.get(modalOpenOrderItemIndexKey)
		const orderItemIndexRaw = indexParam ? parseInt(indexParam, 10) : undefined
		const orderItemIndex = indexWithinBounds(orderItemIndexRaw, basket.orderItems)

		const modalOpenParam = queryParams.get(modalOpenQueryKey) as OpenModalTypes | null
		if (modalOpenParam) {
			when(modalOpenParam, {
				[OpenModalTypes.NewProject]: () => {},
				[OpenModalTypes.OldProject]: () => {
					if (projectSelectionComponentRef?.current?.isOpen === false) {
						projectSelectionComponentRef?.current?.setShowProjectSelectModule(true, () => {})
					}
				},
				[OpenModalTypes.OrderItemEditDate]: () => {
					if (orderItemIndex == null) {
						return
					}

					if (isMobileSize) {
						basket.functions.setMobileBasketShown(true)
					}
					basket.functions.setSelectedOrderItem(orderItemIndex)
					setTimeout(() => {
						setShowDateSelectModule(true)
					}, 50)
				},
				[OpenModalTypes.OrderItemEditTime]: () => {
					handleQueryParamsOrderItemEditTime(orderItemIndex, basket, client, isMobileSize, () => {
						setShowTimeSelectModule(true)
					})
				},
				[OpenModalTypes.OrderItemEditProject]: () => {
					if (orderItemIndex == null) {
						return
					}

					if (isMobileSize) {
						basket.functions.setMobileBasketShown(true)
					}
					basket.functions.setSelectedOrderItem(orderItemIndex)
					setTimeout(() => {
						projectSelectionComponentRef.current?.setShowProjectSelectModule(true, (project) => {
							basket.functions.onProjectSelected(project, false, null, orderItemIndex)
						})
					}, 50)
				},
			})
		}
	}, [basket, client, isMobileSize, queryParams])

	useEffect(() => {
		const typeOfClosedModal = (previousQueryParams.current?.get(modalOpenQueryKey) || null) as OpenModalTypes | null
		if (typeOfClosedModal && !queryParams.has(modalOpenQueryKey)) {
			// Don't close mobile basket if the modal being closed is a modal that was opened in context of said basket
			closeAllModals(
				!(
					typeOfClosedModal === OpenModalTypes.OrderItemEditDate ||
					typeOfClosedModal === OpenModalTypes.OrderItemEditTime ||
					typeOfClosedModal === OpenModalTypes.OrderItemEditProject
				),
			)
			removeModalOpen(queryParams, setQueryParams)
		}

		previousQueryParams.current = new URLSearchParams(queryParams)
	}, [closeAllModals, queryParams, setQueryParams])

	const [showRegularBasket] = useBrandedLocalStorage("show-regular-basket", z.boolean(), {
		defaultValue: false,
	})

	const [primaryStepProduct, setPrimaryStepProduct] = useState<ProductDefinitionWithPrice | null>(() => {
		let serviceId = null

		if (props.category.type === "WasteCategory" && props.category.productSelectionConfig?.steps) {
			serviceId = (Object.values(props.category.productSelectionConfig.steps)[0]?.services || [])[0] || null
		}

		const ret = resolveProductDefinitionsWithPriceData([props.product], serviceId, consumerCatalog, client, auth)

		if (!ret || isEmpty(ret)) {
			return null
		}

		return ret[0] || null
	})
	const [secondaryStepProduct, setSecondaryStepProduct] = useState<ProductDefinitionWithPrice | null>(() => {
		let serviceId = null

		if (props.category.type === "WasteCategory" && props.category.productSelectionConfig?.steps) {
			const secondaryStep = (Object.values(props.category.productSelectionConfig.steps)[0]?.secondarySteps || [])[0]

			if (secondaryStep?.type === "Service") {
				serviceId = secondaryStep.serviceId
			}
		}

		const ret = resolveProductDefinitionsWithPriceData([props.product], serviceId, consumerCatalog, client, auth)

		if (!ret || isEmpty(ret)) {
			return null
		}

		return ret[0] || null
	})

	const [selectedPrimaryStep, setSelectedPrimaryStep] = useState<ProductSelectionConfigStep | null>(null)
	const [selectedPrimaryStepService, setSelectedPrimaryStepService] = useState<string | null>(null)

	const [selectedSecondaryStep, setSelectedSecondaryStep] = useState<ProductSelectionConfigSecondaryStep | null>(null)

	const [productSelectionReturnValues, setProductSelectionReturnValues] = useState<{
		[id: string]: ProductSelectionReturnValue
	}>({})

	function incrementorDefaultValue(
		productId: string,
		wasteTypeId?: string | null,
		packagingMethodId?: string | null,
	): number {
		if (
			basket.values.selectedOrderItemIndex === null ||
			(primaryStepProduct?.type === "WasteProductDefinition" && selectedPrimaryStepService === null)
		) {
			return 0
		}

		const orderItem = basket.orderItems[basket.values.selectedOrderItemIndex]

		if (!orderItem) {
			return 0
		}

		let productIndex

		if (wasteTypeId) {
			productIndex = orderItem.products.findIndex(
				(x) => x.productId === productId && x.wasteType?.wasteTypeId === wasteTypeId,
			)
		} else if (packagingMethodId) {
			productIndex = orderItem.products.findIndex(
				(x) => x.productId === productId && x.packagingMethod?.id === packagingMethodId,
			)
		} else {
			productIndex = orderItem.products.findIndex(
				(x) => x.productId === productId && x.serviceId === selectedPrimaryStepService,
			)
		}

		if (productIndex > -1) {
			const product = orderItem.products[productIndex]
			if (product) {
				if (product.wasteType) {
					return product.wasteType.amount
				}

				if (product.packagingMethod) {
					return product.packagingMethod.amount
				}

				return product.amount || 0
			}
		}

		return 0
	}

	function primaryStepSelectionBoxes() {
		if (!primaryStepProduct) {
			return null
		}

		return exhaustive(primaryStepProduct, "type", {
			WasteProductDefinition: (it) => {
				if (!it.category.productSelectionConfig) {
					return null
				}

				return (
					<div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
						<div className={style.selectionBox}>
							<div className={style.title}>{it.category.productSelectionConfig.stepsTitle}</div>
							<hr />
							<MultiStateSwitch
								onSelect={(option) => {
									if (!it.category.productSelectionConfig) {
										return
									}

									setProductSelectionReturnValues({})
									const step = getBy("id", option.key, it.category.productSelectionConfig.steps)
									if (step) {
										setSelectedPrimaryStep(step.value)

										const primaryStepService = (step.value?.services || [])[0]
										setSelectedPrimaryStepService(primaryStepService || null)

										const products = resolveProductDefinitionsWithPriceData(
											[props.product],
											primaryStepService || null,
											consumerCatalog,
											client,
											auth,
										)
										const product = products && products[0]
										if (product) {
											setPrimaryStepProduct(product)
										}

										if (step.value.secondarySteps && step.value.secondarySteps[0]) {
											setSelectedSecondaryStep(step.value.secondarySteps[0])
										} else if (!step.value.secondarySteps || step.value.secondarySteps.length === 0) {
											setSelectedSecondaryStep(null)
										}
									}
								}}
								options={map(it.category.productSelectionConfig.steps, (step) => {
									if (!step) {
										return null
									}
									return { key: step.id, value: step.name }
								}).filter(notNull)}
							/>
						</div>
						{primaryStepWasteSelectionElement(it)}
					</div>
				)
			},
			GoodsProductDefinition: (it) => {
				const title = it.category?.productSelectionConfig?.title
				const subTitle = it.category?.productSelectionConfig?.subTitle
				return (
					<div className={style.selectionBox}>
						<div className={style.title}>{title || "Leveranssätt"}</div>
						{subTitle ? <div className={style.subTitle}>{subTitle}</div> : null}
						<hr />
						<div className={cls(style.flexContentColumn, style.goodsSelectionContent)}>
							{
								goodsCategoryPackagingSelectionElement(
									it,
									isMobileSize ? productSelectionReturnValues[it.id] : null,
									(selectionReturnValue, action, componentId, clickedPackagingMethodId) =>
										categorySetProductSelectionReturnValue(
											it,
											null,
											it.id,
											selectionReturnValue,
											null,
											clickedPackagingMethodId,
											"goods",
											action,
										),
									consumerCatalog,
									(ref) => {
										if (ref) {
											if (!selectorRefs.find((x) => x.id === ref.id)) {
												setSelectorRefs((x) => [...x, ref])
											}
										}
									},
									!isMobileSize
										? (productId, packagingMethodId) => {
												return incrementorDefaultValue(productId, null, packagingMethodId)
										  }
										: null,
								).element
							}
						</div>
					</div>
				)
			},
		})
	}

	function secondaryStepSelectionBoxes() {
		if (
			!secondaryStepProduct ||
			!selectedPrimaryStep ||
			!selectedPrimaryStep.secondarySteps ||
			(selectedPrimaryStep.secondarySteps && selectedPrimaryStep.secondarySteps.length === 0)
		) {
			return null
		}

		return exhaustive(secondaryStepProduct, "type", {
			WasteProductDefinition: (it) => {
				if (!it.category.productSelectionConfig) {
					return null
				}

				return (
					<div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
						<div className={style.selectionBox}>
							<div className={style.title}>{selectedPrimaryStep.secondaryStepsTitle}</div>
							<hr />
							{(selectedPrimaryStep.secondarySteps || []).length > 1 ? (
								<MultiStateSwitch
									onSelect={(option) => {
										if (!it.category.productSelectionConfig) {
											return
										}

										const secondaryStep = getBy(
											"id",
											option.key,
											selectedPrimaryStep.secondarySteps || [],
										)
										if (secondaryStep) {
											setSelectedSecondaryStep(secondaryStep.value)
											const products = resolveProductDefinitionsWithPriceData(
												[props.product],
												secondaryStep.value.type === "Service"
													? secondaryStep.value.serviceId
													: null,
												consumerCatalog,
												client,
												auth,
											)

											const product = products && products[0]
											if (product) {
												setSecondaryStepProduct(product)
											}
										}
									}}
									options={map(selectedPrimaryStep.secondarySteps || [], (step) => {
										if (!step) {
											return null
										}
										return { key: step.id, value: step.name }
									}).filter(notNull)}
								/>
							) : null}

							{secondaryStepWasteSelectionElement(it)}
						</div>
					</div>
				)
			},
			GoodsProductDefinition: () => {
				return null
			},
		})
	}

	function primaryStepWasteSelectionElement(product: WasteProductDefinitionWithPrice) {
		if (!selectedPrimaryStep) {
			return null
		}

		if (!selectedPrimaryStepService) {
			return null
		}

		return (
			<div className={style.selectionBox}>
				<div className={style.title}>{selectedPrimaryStep.title}</div>
				{selectedPrimaryStep.services && selectedPrimaryStep.services.length > 1 ? (
					<>
						<br />
						<MultiStateSwitch
							onSelect={(option) => {
								setSelectedPrimaryStepService(option.key)
								const products = resolveProductDefinitionsWithPriceData(
									[props.product],
									option.key,
									consumerCatalog,
									client,
									auth,
								)

								const product = products && products[0]
								if (product) {
									setPrimaryStepProduct(product)
								}
							}}
							options={map(selectedPrimaryStep.services || [], (serviceId) => {
								if (!serviceId) {
									return null
								}
								return { key: serviceId, value: product.category.services[serviceId]?.name || "" }
							}).filter(notNull)}
						/>
					</>
				) : null}
				<hr />
				<div className={cls(style.flexContentColumn, style.wasteSelectionContent)}>
					{exhaustive(product, "quantityType", {
						None: () => {
							return null
						},
						Waste: (product: WasteWasteProductDefinitionWithPrice) => {
							const productSelectReturnId = `${product.id}_${selectedPrimaryStepService}`
							return (
								wasteCategoryWasteSelectionElement(
									product,
									selectedPrimaryStepService,
									isMobileSize ? productSelectionReturnValues[productSelectReturnId] : null,
									(selectionReturnValue, action, clickedWasteTypeId) => {
										categorySetProductSelectionReturnValue(
											product,
											selectedPrimaryStepService,
											productSelectReturnId,
											selectionReturnValue,
											clickedWasteTypeId,
											null,
											"waste",
											action,
										)
									},
									"",
									null,
									consumerCatalog,
									(ref) => {
										if (ref) {
											if (!selectorRefs.find((x) => x.id === ref.id)) {
												setSelectorRefs((x) => [...x, ref])
											}
										}
									},
									!isMobileSize
										? (productId, wasteTypeId) => {
												return incrementorDefaultValue(productId, wasteTypeId)
										  }
										: null,
								)?.element || null
							)
						},
						Amount: (product: AmountWasteProductDefinitionWithPrice) => {
							const productSelectReturnId = `${product.id}_${selectedPrimaryStepService}`
							if (product.service.unit === ProductServiceUnit.Piece) {
								return (
									wasteCategoryAmountSelectionElement(
										product,
										selectedPrimaryStepService,
										(selectionReturnValue, action) => {
											categorySetProductSelectionReturnValue(
												product,
												selectedPrimaryStepService,
												productSelectReturnId,
												selectionReturnValue,
												null,
												null,
												"discrete-amount",
												action,
											)
										},
										consumerCatalog,
										(ref) => {
											if (ref) {
												if (!selectorRefs.find((x) => x.id === ref.id)) {
													setSelectorRefs((x) => [...x, ref])
												}
											}
										},
										!isMobileSize ? incrementorDefaultValue(product.id) : null,
									)?.element || null
								)
							} else {
								return (
									wasteCategoryNonDiscreteAmountSelectionElement(
										product,
										selectedPrimaryStepService,
										(selectionReturnValue, action) => {
											categorySetProductSelectionReturnValue(
												product,
												selectedPrimaryStepService,
												productSelectReturnId,
												selectionReturnValue,
												null,
												null,
												"non-discrete-amount",
												action,
											)
										},
										consumerCatalog,
										(ref) => {
											if (ref) {
												if (!selectorRefs.find((x) => x.id === ref.id)) {
													setSelectorRefs((x) => [...x, ref])
												}
											}
										},
										!isMobileSize ? incrementorDefaultValue(product.id) : null,
									)?.element || null
								)
							}
						},
					})}
				</div>
			</div>
		)
	}

	function secondaryStepWasteSelectionElement(product: WasteProductDefinitionWithPrice) {
		if (!selectedSecondaryStep) {
			return null
		}

		return (
			<div style={{ marginTop: "30px" }}>
				<div className={style.secondaryTitle}>{selectedSecondaryStep.title}</div>
				<div className={style.secondarySubTitle}>{selectedSecondaryStep.subTitle}</div>
				<br />
				{selectedSecondaryStep.type === "Service" ? (
					<div className={cls(style.flexContentColumn, style.wasteSelectionContent)}>
						{exhaustive(product, "quantityType", {
							None: () => {
								return null
							},
							Waste: (product: WasteWasteProductDefinitionWithPrice) => {
								const productSelectReturnId = `${product.id}_${selectedSecondaryStep.serviceId}`
								return (
									wasteCategoryWasteSelectionElement(
										product,
										selectedSecondaryStep.serviceId,
										isMobileSize ? productSelectionReturnValues[productSelectReturnId] : null,
										(selectionReturnValue, action, clickedWasteTypeId) => {
											categorySetProductSelectionReturnValue(
												product,
												selectedSecondaryStep.serviceId,
												productSelectReturnId,
												selectionReturnValue,
												clickedWasteTypeId,
												null,
												"waste",
												action,
											)
										},
										"",
										null,
										consumerCatalog,
										(ref) => {
											if (ref) {
												if (!selectorRefs.find((x) => x.id === ref.id)) {
													setSelectorRefs((x) => [...x, ref])
												}
											}
										},
										!isMobileSize
											? (productId, wasteTypeId) => {
													return incrementorDefaultValue(productId, wasteTypeId)
											  }
											: null,
									)?.element || null
								)
							},
							Amount: (product: AmountWasteProductDefinitionWithPrice) => {
								const productSelectReturnId = `${product.id}_${selectedSecondaryStep.serviceId}`
								if (product.service.unit === ProductServiceUnit.Piece) {
									return (
										wasteCategoryAmountSelectionElement(
											product,
											selectedSecondaryStep.serviceId,
											(selectionReturnValue, action) => {
												categorySetProductSelectionReturnValue(
													product,
													selectedSecondaryStep.serviceId,
													productSelectReturnId,
													selectionReturnValue,
													null,
													null,
													"discrete-amount",
													action,
												)
											},
											consumerCatalog,
											(ref) => {
												if (ref) {
													if (!selectorRefs.find((x) => x.id === ref.id)) {
														setSelectorRefs((x) => [...x, ref])
													}
												}
											},
											!isMobileSize ? incrementorDefaultValue(product.id) : null,
										)?.element || null
									)
								} else {
									return (
										wasteCategoryNonDiscreteAmountSelectionElement(
											product,
											selectedSecondaryStep.serviceId,
											(selectionReturnValue, action) => {
												categorySetProductSelectionReturnValue(
													product,
													selectedSecondaryStep.serviceId,
													productSelectReturnId,
													selectionReturnValue,
													null,
													null,
													"non-discrete-amount",
													action,
												)
											},
											consumerCatalog,
											(ref) => {
												if (ref) {
													if (!selectorRefs.find((x) => x.id === ref.id)) {
														setSelectorRefs((x) => [...x, ref])
													}
												}
											},
											!isMobileSize ? incrementorDefaultValue(product.id) : null,
										)?.element || null
									)
								}
							},
						})}
					</div>
				) : null}
			</div>
		)
	}

	function categorySetProductSelectionReturnValue(
		product: ProductDefinitionWithPrice,
		serviceId: string | null,
		productSelectReturnId: string,
		selectionReturnValue: ProductSelectionReturnValue | null,
		clickedWasteTypeId: string | null,
		clickedPackagingMethodId: string | null,
		type: "goods" | "waste" | "discrete-amount" | "non-discrete-amount",
		action: "text" | "addClick" | "removeClick" | "blur",
	) {
		if (isMobileSize) {
			if (!selectionReturnValue) {
				const methods = productSelectionReturnValues[productSelectReturnId].packagingMethods
				if (clickedPackagingMethodId && methods) {
					delete methods[clickedPackagingMethodId]
					productSelectionReturnValues[productSelectReturnId].packagingMethods = methods
				} else {
					delete productSelectionReturnValues[productSelectReturnId]
				}
			} else {
				productSelectionReturnValues[productSelectReturnId] = selectionReturnValue
			}
			setProductSelectionReturnValues(Object.assign({}, productSelectionReturnValues))
			return
		}

		if (!selectionReturnValue) {
			let obj: ProductSelectionReturnValue | null = null

			if (type === "waste" && serviceId && clickedWasteTypeId) {
				obj = {
					productId: product.id,
					name: product.name,
					category: product.category.name,
					serviceId: serviceId,
					amount: undefined,
					selectedWasteTypeAmounts: { [clickedWasteTypeId]: 1 },
				}
			} else if (serviceId && (type === "discrete-amount" || type === "non-discrete-amount")) {
				obj = {
					productId: product.id,
					name: product.name,
					category: product.category.name,
					serviceId: serviceId,
					amount: 1,
				}
			} else if (clickedPackagingMethodId && type === "goods") {
				obj = {
					productId: product.id,
					name: product.name,
					category: product.category.name,
					packagingMethods: { [clickedPackagingMethodId]: 1 },
				}
			}

			if (obj) {
				decrementProduct([obj])
			}

			return
		}

		productSelectionReturnValues[productSelectReturnId] = selectionReturnValue
		setProductSelectionReturnValues(Object.assign({}, productSelectionReturnValues))

		when(action, {
			addClick: () => {
				if (type === "non-discrete-amount") {
					const func = (project?: Project | GetProject) => {
						const num = incrementorDefaultValue(
							selectionReturnValue.productId,
							clickedWasteTypeId,
							clickedPackagingMethodId,
						)
						if (selectionReturnValue.amount !== undefined) {
							selectionReturnValue.amount = selectionReturnValue.amount - num
							if (selectionReturnValue.amount > 0) {
								basket.functions.addProduct([selectionReturnValue], selectionReturnValue.amount, project)
							}
						}
					}

					if (basket.values.selectedProject) {
						func()
					} else {
						queryParams.set(modalOpenQueryKey, OpenModalTypes.OldProject)
						setQueryParams(queryParams)
						projectSelectionComponentRef?.current?.setShowProjectSelectModule(true, (project) => {
							if (project) {
								func(project)
							}
						})
					}
				} else if (type === "goods") {
					const func = (project?: Project | GetProject) => {
						const oldValue = incrementorDefaultValue(
							selectionReturnValue.productId,
							clickedWasteTypeId,
							clickedPackagingMethodId,
						)

						if (selectionReturnValue.packagingMethods && clickedPackagingMethodId) {
							let newValue = cloneDeep(selectionReturnValue.packagingMethods[clickedPackagingMethodId]) || 0
							selectionReturnValue.packagingMethods[clickedPackagingMethodId] = 1

							let amount: number | null = newValue - oldValue

							if (amount !== null) {
								selectionReturnValue.amount = undefined
								basket.functions.addProduct([selectionReturnValue], amount, project)
							}
						}
					}

					if (basket.values.selectedProject) {
						func()
					} else {
						queryParams.set(modalOpenQueryKey, OpenModalTypes.OldProject)
						setQueryParams(queryParams)
						projectSelectionComponentRef?.current?.setShowProjectSelectModule(true, (project) => {
							if (project) {
								func(project)
							}
						})
					}
				} else {
					onProductSelected([selectionReturnValue])
				}
			},
			blur: () => {
				if (type === "goods") {
					const func = (project?: Project | GetProject) => {
						const oldValue = incrementorDefaultValue(
							selectionReturnValue.productId,
							clickedWasteTypeId,
							clickedPackagingMethodId,
						)

						if (selectionReturnValue.packagingMethods && clickedPackagingMethodId) {
							let newValue = cloneDeep(selectionReturnValue.packagingMethods[clickedPackagingMethodId]) || 0
							selectionReturnValue.packagingMethods[clickedPackagingMethodId] = 1

							let amount: number = newValue - oldValue

							if (amount !== null) {
								if (amount < 0) {
									const arr = []
									for (let i = amount; i < 0; i++) {
										arr.push(selectionReturnValue)
									}
									decrementProduct(arr)
								} else if (amount > 0) {
									selectionReturnValue.amount = undefined
									basket.functions.addProduct([selectionReturnValue], amount, project)
								}
							}
						}
					}

					if (basket.values.selectedProject) {
						func()
					} else {
						queryParams.set(modalOpenQueryKey, OpenModalTypes.OldProject)
						setQueryParams(queryParams)
						projectSelectionComponentRef?.current?.setShowProjectSelectModule(true, (project) => {
							if (project) {
								func(project)
							}
						})
					}
				}
			},
			removeClick: () => {
				if (type === "non-discrete-amount") {
					const num = incrementorDefaultValue(
						selectionReturnValue.productId,
						clickedWasteTypeId,
						clickedPackagingMethodId,
					)
					if (selectionReturnValue.amount !== undefined) {
						selectionReturnValue.amount = selectionReturnValue.amount - num
						if (selectionReturnValue.amount < 0) {
							const arr = []
							for (let i = selectionReturnValue.amount; i < 0; i++) {
								arr.push(selectionReturnValue)
							}
							decrementProduct(arr)
						}
					}
				} else if (type === "goods") {
					const oldValue = incrementorDefaultValue(
						selectionReturnValue.productId,
						clickedWasteTypeId,
						clickedPackagingMethodId,
					)

					if (selectionReturnValue.packagingMethods && clickedPackagingMethodId) {
						let newValue = cloneDeep(selectionReturnValue.packagingMethods[clickedPackagingMethodId]) || 0
						selectionReturnValue.packagingMethods[clickedPackagingMethodId] = 1

						let amount: number = newValue - oldValue

						if (amount < 0) {
							const arr = []
							for (let i = amount; i < 0; i++) {
								arr.push(selectionReturnValue)
							}
							decrementProduct(arr)
						}
					}
				} else {
					decrementProduct([selectionReturnValue])
				}
			},
			text: () => {
				const oldValue = incrementorDefaultValue(
					selectionReturnValue.productId,
					clickedWasteTypeId,
					clickedPackagingMethodId,
				)

				let newValue: number

				if (selectionReturnValue.selectedWasteTypeAmounts && clickedWasteTypeId) {
					newValue = cloneDeep(selectionReturnValue.selectedWasteTypeAmounts[clickedWasteTypeId]) || 0
					selectionReturnValue.selectedWasteTypeAmounts[clickedWasteTypeId] = 1
				} else if (selectionReturnValue.packagingMethods && clickedPackagingMethodId) {
					newValue = cloneDeep(selectionReturnValue.packagingMethods[clickedPackagingMethodId]) || 0
					selectionReturnValue.packagingMethods[clickedPackagingMethodId] = 1
				} else {
					newValue = selectionReturnValue.amount || 0
				}

				let amount: number = newValue - oldValue

				if (amount !== null) {
					if (amount < 0) {
						const arr = []
						for (let i = amount; i < 0; i++) {
							arr.push(selectionReturnValue)
						}
						decrementProduct(arr)
					} else if (amount > 0) {
						selectionReturnValue.amount = undefined
						basket.functions.addProduct([selectionReturnValue], amount)
					}
				}
			},
		})
	}

	function onProductSelected(data: ProductSelectionReturnValue[]): boolean {
		if (isMobileSize) {
			// When we're in mobile size, the data returned is correct in terms of sizes
			// As such we just send the data as is
			const res = basket.functions.addProduct(data)

			if (res === "no-project") {
				projectSelectionComponentRef?.current?.setShowProjectSelectModule(true, (project) => {
					if (project) {
						basket.functions.addProduct(data, 1, project)

						selectorRefs.forEach((ref) => {
							ref.reset()
						})

						setProductSelectionReturnValues({})
					}
				})
				return false
			} else if (res === "added") {
				selectorRefs.forEach((ref) => {
					ref.reset()
				})

				setProductSelectionReturnValues({})

				return true
			}

			return false
		} else {
			const newData = data.map((retValue) => {
				if (retValue.amount !== undefined) {
					retValue.amount = 1
				}

				Object.keys(retValue.selectedWasteTypeAmounts || {}).forEach((key) => {
					if (retValue.selectedWasteTypeAmounts && retValue.selectedWasteTypeAmounts[key]) {
						retValue.selectedWasteTypeAmounts[key] = 1
					}
				})
				Object.keys(retValue.packagingMethods || {}).forEach((key) => {
					if (retValue.packagingMethods && retValue.packagingMethods[key]) {
						retValue.packagingMethods[key] = 1
					}
				})

				return retValue
			})

			if (basket.values.selectedProject === null && basket.values.selectedOrderItemIndex === null) {
				queryParams.set(modalOpenQueryKey, OpenModalTypes.OldProject)
				setQueryParams(queryParams)
				projectSelectionComponentRef?.current?.setShowProjectSelectModule(true, (project) => {
					newData.forEach((x) => {
						basket.functions.addProduct([x], 1, project)
					})
				})
				return false
			}
			// When we're not in mobile size, we add the data one at a time, since the items
			// Are added to the basket when pressing buttons directly
			newData.forEach((x) => {
				basket.functions.addProduct([x])
			})
			return true
		}
	}

	function decrementProduct(values: ProductSelectionReturnValue[]) {
		let orderItemIndex = null
		let orderItemProductIndex = null
		let newValue: number | null = null

		values.forEach((data) => {
			for (let i = basket.orderItems.length - 1; i > -1; i--) {
				let shouldBreak = false

				const orderItem = basket.orderItems[i]

				let serviceValid = false
				if (data.packagingMethods) {
					const first = Object.entries(data.packagingMethods)[0]
					serviceValid =
						orderItem?.products?.find((x) => x.packagingMethod?.id && x.packagingMethod?.id === first[0]) !==
						undefined
				} else {
					serviceValid = orderItem.serviceId === data.serviceId
				}

				if (orderItem && orderItem.category === data.category && serviceValid) {
					for (let j = 0; j < orderItem.products.length; j++) {
						const packagingMethodKeys = data.packagingMethods ? Object.keys(data.packagingMethods) : []
						if (packagingMethodKeys.length > 0) {
							const packagingMethod = orderItem.products[j]?.packagingMethod
							if (packagingMethod && packagingMethod?.id === packagingMethodKeys[0]) {
								orderItemIndex = i
								orderItemProductIndex = j

								if (newValue === null) {
									newValue = packagingMethod.amount - 1
								} else {
									newValue -= 1
								}
								shouldBreak = true
								break
							}
						}

						const orderItemProduct = orderItem.products[j]

						if (orderItemProduct) {
							if (data.productId && data.serviceId && !data.selectedWasteTypeAmounts) {
								orderItemIndex = i
								orderItemProductIndex = j
								if (newValue === null) {
									// if no amount is defined, which it should never be...
									// but if it is, default to 1, so that, it becomes 1-1 = 0
									newValue = (orderItemProduct.amount || 1) - 1
								} else {
									newValue -= 1
								}
								shouldBreak = true
								break
							}

							if (
								data.productId &&
								data.serviceId &&
								data.selectedWasteTypeAmounts &&
								Object.keys(data.selectedWasteTypeAmounts).length > 0 &&
								orderItemProduct.wasteType &&
								orderItemProduct.wasteType?.wasteTypeId === Object.keys(data.selectedWasteTypeAmounts)[0]
							) {
								orderItemIndex = i
								orderItemProductIndex = j

								if (newValue === null) {
									newValue = orderItemProduct.wasteType.amount - 1
								} else {
									newValue -= 1
								}
								shouldBreak = true
								break
							}
						}
					}
				}

				if (shouldBreak) {
					break
				}
			}
		})

		if (orderItemIndex !== null && orderItemProductIndex !== null && newValue !== null) {
			const finalOrderItem = basket.orderItems[orderItemIndex]
			const finalProduct = finalOrderItem?.products[orderItemProductIndex]

			if (finalOrderItem && finalProduct) {
				if (finalProduct.packagingMethod) {
					basket.functions.updateOrderItemPackagingAmount(finalOrderItem.id, finalProduct.uniqueId, newValue)
				} else if (finalProduct.wasteType) {
					basket.functions.updateOrderItemWasteTypeAmount(finalOrderItem.id, finalProduct.uniqueId, newValue)
				} else {
					basket.functions.updateOrderItemAmount(finalOrderItem.id, finalProduct.uniqueId, newValue, true)
				}
			}
		}
	}

	function isOrderPage(): boolean {
		return location.pathname === `/${client.identifier}/order`
	}

	function showMobileFinalize() {
		if (!isMobileSize) {
			return false
		}

		return (
			basket.values.selectedProject ||
			Object.keys(productSelectionReturnValues).length > 0 ||
			basket.orderItems.length > 0
		)
	}

	function amountOfThingsToBeAdded() {
		if (Object.keys(productSelectionReturnValues).length === 0) {
			return 0
		}

		return sumBy(Object.values(productSelectionReturnValues), (x) => {
			if (x.selectedWasteTypeAmounts) {
				return sumBy(Object.values(x.selectedWasteTypeAmounts), (y) => y)
			} else if (x.packagingMethods) {
				return sumBy(Object.values(x.packagingMethods), (amount) => {
					return amount
				})
			} else {
				return x.amount || 0
			}
		})
	}

	function mobileFinalizeButton() {
		if (!showMobileFinalize()) {
			return null
		}

		let amountOfThings = sum(
			basket.orderItems.map((item) => {
				return getAmountOfProductsInOrderItem(item, client)
			}),
		)
		const disabledState =
			(!basket.values.selectedProject && basket.orderItems.length === 0) || basket.orderItems.length === 0

		return (
			<div className={style.mobileFinalizeButtonWrapper}>
				<div className={style.mobileFinalizeButtonsContainer}>
					<FinalizeButton
						className={style.mobileFinalizeButton}
						disabled={disabledState}
						onClick={() => {
							basket.functions.setMobileBasketShown(true)
						}}>
						<span
							className={cls(
								style.mobileFinalizeIconWrapper,
								{ [style.mobileFinalizeIconWide]: amountOfThings > 9 && amountOfThings <= 99 },
								{ [style.mobileFinalizeIconExtraWide]: amountOfThings > 99 },
							)}>
							{amountOfThings <= 9 ? <VarukorgIcon size={26} /> : null}
							{amountOfThings > 9 && amountOfThings <= 99 ? <VarukorgWideIcon size={32} /> : null}
							{amountOfThings > 99 ? <VarukorgExtraWideIcon size={36} /> : null}
							<span className={style.orderItemAmount}>{amountOfThings}</span>
						</span>
						<span className={style.leftTextFirst}>
							{amountOfThings === 0 ? "Varukorgen är tom" : null}
							{amountOfThings > 0 ? "Gå till varukorg" : null}
						</span>
						<span className={style.leftTextSecond}>
							{amountOfThings === 0 ? "Inga varor" : null}
							{amountOfThings > 0 ? "Varukorg" : null}
						</span>

						<span style={{ marginLeft: "auto" }}>
							{consumerCatalog.pricesEnabled && amountOfThings > 0
								? consumerCatalog.renderVAT
									? currencyFormatter(
											sum(
												Object.values(basket.values.orderItemTotalPrices).map(
													(x) => x.price + x.tax,
												),
											),
									  )
									: currencyFormatter(
											sum(Object.values(basket.values.orderItemTotalPrices).map((x) => x.price)),
									  )
								: null}
						</span>
					</FinalizeButton>
					{amountOfThingsToBeAdded() > 0 ? (
						<button
							className={style.mobileAddProductSelectionsButton}
							onClick={() => {
								if (amountOfThingsToBeAdded() === 0) {
									return
								}

								onProductSelected(Object.values(productSelectionReturnValues))
							}}>
							<span className={style.rightTextFirst}>Lägg till i varukorg</span>
							<span className={style.rightTextSecond}>Lägg till</span>
							<span style={{ marginLeft: "10px" }}>+{amountOfThingsToBeAdded()}</span>
						</button>
					) : null}
				</div>
			</div>
		)
	}

	function modalElements(): JSX.Element | null {
		const orderItem =
			basket.values.selectedOrderItemIndex !== null ? basket.orderItems[basket.values.selectedOrderItemIndex] : null
		const categoryOfSelectedOrderItem = orderItem ? client.categories[orderItem.category] : null

		return (
			<>
				{showDateSelectModule && orderItem ? (
					<DateSelectModule
						onDateSelected={(date) =>
							basket.functions.setDateOnOrderItem(basket.values.selectedOrderItemIndex, date)
						}
						allowedValues={allowedDateOrTimeSelectValues(
							basket,
							client,
							categoryOfSelectedOrderItem || null,
							"date",
						)}
						defaultValue={orderItem.date}
						onClose={() => {
							setShowDateSelectModule(false)
							removeModalOpen(queryParams, setQueryParams)
						}}
					/>
				) : null}
				{showTimeSelectModule && orderItem ? (
					<TimeSelectModule
						onTimeSelected={(timeslotId, timeName, specificTime) =>
							basket.functions.setTimeOnOrderItem(
								basket.values.selectedOrderItemIndex,
								timeslotId,
								timeName,
								specificTime,
							)
						}
						allTimeSlots={client.possibleTimeSlots}
						allowedValues={allowedDateOrTimeSelectValues(
							basket,
							client,
							categoryOfSelectedOrderItem || null,
							"time",
						)}
						defaultValue={orderItem.time}
						onClose={() => {
							setShowTimeSelectModule(false)
							removeModalOpen(queryParams, setQueryParams)
						}}
					/>
				) : null}
			</>
		)
	}

	if (!primaryStepProduct) {
		return (
			<AbsolutCentered>
				<Loader />
			</AbsolutCentered>
		)
	}

	return (
		<>
			{modalElements()}
			<div>
				<ArrowLeftIcon
					size={28}
					className={style.backArrow}
					onClick={() => {
						props.onCancel()
					}}
				/>
				<ProjectSelection
					invisible={true}
					project={basket.values.selectedProject}
					onSetShowProjectSelectModule={() => {}}
					onSetShowProjectInputModule={() => {}}
					ref={projectSelectionComponentRef}
					currentOrderItemProject={
						basket.values.selectedOrderItemIndex != null &&
						basket.orderItems[basket.values.selectedOrderItemIndex]
							? basket.orderItems[basket.values.selectedOrderItemIndex]?.project || null
							: basket.values.selectedProject || null
					}
					onProjectSelected={(project, isNew, productSelectionReturnValues) => {
						basket.functions.onProjectSelected(project, isNew, productSelectionReturnValues, null)
					}}
					onProjectInputClose={() => {}}
					onProjectSelectClose={(reason) => {
						if (
							!isMobileSize &&
							!basket.values.selectedProject &&
							(reason === "esc" || reason === "backdropClick" || reason === "closeCross")
						) {
							selectorRefs.forEach((ref) => {
								ref.reset()
							})
						} else if (
							isMobileSize &&
							(reason === "esc" || reason === "backdropClick" || reason === "closeCross")
						) {
							removeModalOpen(queryParams, setQueryParams)
						}
					}}
				/>
				<div
					className={cls(style.wrapper, {
						[style.regularBasketVisible]: showRegularBasket && isOrderPage(),
					})}>
					<div className={style.productBox}>
						<ProductInformationAndSelectionModule
							nameAndDimensionsClass={style.productNameAndDimensionsClass}
							renderInline={true}
							product={primaryStepProduct}
							descriptionOpen={false}
							category={props.category}
							possibleServices={
								props.category?.type === "WasteCategory" ? props.category?.services || {} : {}
							}
							onClose={() => {}}
							onProductSelected={() => {}}
							onSetSelectedServiceId={() => {}}
						/>
					</div>
					<div className={cls(style.flexContentColumn, style.selectionBoxesColumn)}>
						{primaryStepSelectionBoxes()}
						{secondaryStepSelectionBoxes()}
					</div>
				</div>
				<div style={{ width: "100%" }}>
					{map(props.category.productSelectionConfig?.widgets, (widget) => {
						if (!widget) {
							return null
						}
						return (
							<div key={widget.title + props.category.id} className={style.widgetSection}>
								<div className={style.widgetTitle}>{widget.title}</div>
								<WidgetRendererV2 apiWidgets={[widget.widget]} scrollToTopOnWidgetChange={false} />
							</div>
						)
					})}
				</div>
			</div>
			{mobileFinalizeButton()}
		</>
	)
}
