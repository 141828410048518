import { useJsApiLoader } from "@react-google-maps/api"
import { cloneDeep, isArray, isEqual } from "lodash"
import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { v4 } from "uuid"
import { z } from "zod"
import { AbsolutCentered } from "../../AbsolutCentered/AbsolutCentered"
import { useClient } from "../../Client/ClientAndUserProvider"
import { useConsumerCatalog } from "../../Client/ConsumerCatalogContext"
import { ProductDefinition } from "../../Client/ProductDefinitionsByCategories"
import { GetProject } from "../../CustomerPortal/CustomerPortalProjectsManager/CustomerPortalProjectsManager"
import { Loader } from "../../Loader/Loader"
import { lets } from "../../Shared/lets"
import { useBrandedLocalStorage } from "../../Shared/useBrandedLocalStorage"
import { GetVerifiedDiscountCode } from "../Components/OrderConfirmCheckout/DiscountCodeVerifier"
import {
	OrderItem,
	OrderItemDateType,
	OrderItemProduct,
	OrderItemProductType,
	OrderItems,
	OrderItemType,
	Project,
} from "../order-data-model"
import { ProductSelectionReturnValue } from "../ProductInformationAndSelectionModule/ProductInformationAndSelectionModule"
import { libraries } from "../ProjectInputModule/ProjectInputModule"
import {
	addProductToOrderItem,
	calculateTotalPrices,
	getOrderItemPriceArticles,
	getZoneIdFromPoint,
	readOrderItemsFromLocalStorage,
	setArticlesOnOrderItem,
} from "./Logic"
import { OrderItemTotalPrices } from "./OrderContainer"
import { convertGetProjectToInternalProject } from "./ProductSelectionLogic"

export const BasketContext = React.createContext<BasketInstance | null>(null)

export const useBasketContext = () => useContext(BasketContext)

export const useBasket = () => {
	const context = useBasketContext()
	if (!context) {
		throw new Error("Basket context does not exist")
	}
	return context
}

type BasketInstanceValues = {
	currentDiscountCode: GetVerifiedDiscountCode | null
	mobileBasketShown: boolean
	orderItemTotalPrices: OrderItemTotalPrices
	selectedOrderItemIndex: number | null
	selectedProject: Project | GetProject | null
}

type BasketInstanceFunctions = {
	addProduct: (
		productSelectionReturnValues: ProductSelectionReturnValue[],
		repeat?: number,
		project?: Project | GetProject,
	) => "added" | "no-project"
	onOrderItemProductIncrementorChange: (
		orderItemId: string,
		uniqueId: string,
		value: number,
		type: "amount" | "waste" | "goods",
	) => void
	onProductIncrementorChange: (
		buttonClicked: "addClick" | "removeClick" | "text",
		product: ProductDefinition,
		value: number,
		currentValue: number,
		category: string,
		service: string,
		project?: Project | GetProject,
	) => "added" | "no-project" | "not-added"
	onProjectSelected: (
		project: Project | GetProject,
		isNew: boolean,
		productSelectionReturnValue: ProductSelectionReturnValue[] | null,
		orderItemIndex: number | null,
	) => void
	removeOrderItem: (index: number) => void
	removeProductFromOrderItem: (orderItemId: string, uniqueId: string) => void
	setCurrentDiscountCode: (code: GetVerifiedDiscountCode | null) => void
	setDateOnOrderItem: (orderItemIndex: number | null, date: OrderItemDateType) => void
	setOrderItems: (orderItems: OrderItem[]) => void
	setSelectedOrderItem: (index: number | null) => void
	setSelectedProject: (project: BasketInstanceValues["selectedProject"]) => void
	setTimeOnOrderItem: (orderItemIndex: number | null, timeslotId: string, timeName: string, specificTime: boolean) => void
	updateOrderItemAmount: (orderItemId: string, uniqueId: string, amount: number, updateId: boolean) => void
	updateOrderItemPackagingAmount: (orderItemId: string, uniqueId: string, amount: number) => void
	updateOrderItemWasteTypeAmount: (orderItemId: string, uniqueId: string, amount: number) => void
	setMobileBasketShown: (shown: boolean) => void
}

export class BasketInstance {
	constructor(
		public readonly orderItems: OrderItem[],
		public readonly values: BasketInstanceValues,
		public readonly functions: BasketInstanceFunctions,
	) {}

	public isEqualTo(other: BasketInstance | null): boolean {
		if (!other) {
			return false
		}

		return isEqual(this.orderItems, other.orderItems) && isEqual(this.values, other.values)
	}
}

type Props = {
	element: React.ReactNode
}

export const BasketProvider = ({ element }: Props) => {
	const client = useClient()
	const consumerCatalog = useConsumerCatalog()

	const [currentDiscountCode, setCurrentDiscountCode] = useState<GetVerifiedDiscountCode | null>(null)

	const [orderItems, _setOrderItems] = useState<OrderItem[]>(() => {
		return readOrderItemsFromLocalStorage(client, consumerCatalog, null).map((item) => {
			return setArticlesOnOrderItem(
				client,
				consumerCatalog,
				item,
				lets(currentDiscountCode, (it) => it.templateId),
			)
		})
	})
	const [orderItemTotalPrices, setOrderItemTotalPrices] = useState<OrderItemTotalPrices>(
		calculateTotalPrices(orderItems, client),
	)

	const [selectedOrderItemIndex, _setSelectedOrderItemIndex] = useState<number | null>(null)
	const [selectedProject, setSelectedProject] = useState<Project | GetProject | null>(null)
	const [mobileBasketShown, _setMobileBasketShown] = useState<boolean>(false)

	const [showRegularBasket, setShowRegularBasket] = useBrandedLocalStorage("show-regular-basket", z.boolean(), {
		defaultValue: false,
	})

	const savedOrderItemsChecked = useRef(false)
	const [basketInstance, setBasketInstance] = useState<BasketInstance | null>(null)

	const { isLoaded: googleMapsLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
		libraries: libraries,
		language: "sv",
	})

	const setSelectedOrderItemIndex = useCallback(
		(index: number | null) => {
			if (index !== null) {
				const project = orderItems[index]?.project

				if (project) {
					setSelectedProject(project)
				}
			}
			_setSelectedOrderItemIndex(index)
		},
		[orderItems],
	)

	/**
	 * A wrapper that will populate Articles before updating orderItems in the state
	 * @param orderItems
	 */
	const setOrderItems = useCallback(
		(items: OrderItem[]) => {
			const newItems = cloneDeep(items).map((item) => {
				return setArticlesOnOrderItem(
					client,
					consumerCatalog,
					item,
					lets(currentDiscountCode, (it) => it.templateId),
				)
			})

			if (isEqual(newItems, orderItems)) {
				return
			}

			let calculateTotalPrices1 = calculateTotalPrices(newItems, client)

			setOrderItemTotalPrices(calculateTotalPrices1)
			_setOrderItems(newItems)
		},
		[client, consumerCatalog, currentDiscountCode, orderItems],
	)

	const addProduct = useCallback(
		(
			productSelectionReturnValues: ProductSelectionReturnValue[],
			repeat: number = 1,
			project?: Project | GetProject,
		): "added" | "no-project" => {
			let projectToUse: Project | GetProject | null = project || selectedProject
			if (projectToUse === null && selectedOrderItemIndex === null) {
				return "no-project"
			}

			if (!projectToUse) {
				return "no-project"
			}

			const newOrderItems: OrderItem[] = []
			productSelectionReturnValues.forEach((productSelectionReturnValue) => {
				newOrderItems.push(
					...addProductToOrderItem(
						client,
						consumerCatalog,
						cloneDeep(orderItems),
						productSelectionReturnValue,
						productSelectionReturnValue.amount !== undefined ? productSelectionReturnValue.amount : repeat,
						convertGetProjectToInternalProject(projectToUse!),
						selectedOrderItemIndex,
						productSelectionReturnValue.serviceId || null,
						_setSelectedOrderItemIndex,
						lets(currentDiscountCode, (it) => it.templateId),
					),
				)
			})

			setOrderItems(newOrderItems)
			setShowRegularBasket(true)
			if (orderItems.length !== newOrderItems.length) {
				setSelectedOrderItemIndex(newOrderItems.length - 1)
			}
			return "added"
		},
		[
			client,
			consumerCatalog,
			currentDiscountCode,
			orderItems,
			selectedOrderItemIndex,
			selectedProject,
			setOrderItems,
			setShowRegularBasket,
		],
	)

	const removeOrderItem = useCallback(
		(index: number) => {
			const newOrderItems = orderItems.filter((value, i) => i !== index)
			setOrderItems(newOrderItems)
			setSelectedOrderItemIndex(null)
		},
		[orderItems, setOrderItems, setSelectedOrderItemIndex],
	)

	function onProjectSelected(
		project: Project | GetProject,
		isNew: boolean,
		productSelectionReturnValue: ProductSelectionReturnValue[] | null,
		orderItemIndex: number | null,
	) {
		if (isNew || orderItemIndex === null) {
			setSelectedProject(project)
			_setSelectedOrderItemIndex(null)
		} else {
			if (orderItems[orderItemIndex]) {
				const newItems = cloneDeep(orderItems)
				let orderItemProject: Project

				if ("id" in project) {
					orderItemProject = {
						street: project.address.street,
						city: project.address.city,
						zipcode: project.address.zipCode,
						coordinates: project.address.coordinates,
						...project,
					}
				} else {
					orderItemProject = project
				}
				let newItem = newItems[orderItemIndex]

				if (newItem) {
					newItem = { ...newItem, project: orderItemProject }

					if (newItem.project.coordinates) {
						newItem.zoneId = getZoneIdFromPoint(client, newItem.project.coordinates!)
					}

					if (newItem.zoneId) {
						newItem.articles = getOrderItemPriceArticles(
							client,
							consumerCatalog,
							newItem,
							lets(currentDiscountCode, (it) => it.templateId),
						)
					} else {
						newItem.articles?.removeTransportationArticle()
					}

					newItems[orderItemIndex] = newItem
				}

				setOrderItems([...newItems])
			}

			setSelectedProject(project)
		}

		if (productSelectionReturnValue) {
			addProduct(productSelectionReturnValue, 1, project)
		}
	}

	const setDateOnOrderItem = useCallback(
		(orderItemIndex: number | null, date: OrderItemDateType) => {
			if (orderItemIndex === null) {
				return
			}

			const newOrderItems = cloneDeep(orderItems)
			const orderItem = newOrderItems[orderItemIndex]

			if (orderItem) {
				orderItem.date = date
				orderItem.id = v4()
				newOrderItems[orderItemIndex] = orderItem
				setOrderItems([...newOrderItems])
			}
		},
		[client, consumerCatalog, currentDiscountCode, orderItems, setOrderItems],
	)

	const setTimeOnOrderItem = useCallback(
		(orderItemIndex: number | null, timeslotId: string, timeName: string, specificTime: boolean) => {
			if (orderItemIndex === null) {
				return
			}

			const newOrderItems = cloneDeep(orderItems)
			const orderItem = newOrderItems[orderItemIndex]

			if (orderItem) {
				orderItem.id = v4()

				if (specificTime) {
					orderItem.time = { timeslotId, timeValue: timeName, specificTime }
				} else {
					orderItem.time = { timeslotId, timeName, specificTime }
				}
				newOrderItems[orderItemIndex] = orderItem
				setOrderItems([...newOrderItems])
			}
		},
		[orderItems, setOrderItems],
	)

	const removeProductFromOrderItem = useCallback(
		(orderItemId: string, uniqueId: string) => {
			let newOrderItems = cloneDeep(orderItems)
			const orderItemIndex = newOrderItems.findIndex((x) => x.id === orderItemId)

			if (orderItemIndex > -1) {
				const newOrderItem = newOrderItems[orderItemIndex]

				if (newOrderItem) {
					const productIndex = newOrderItem.products.findIndex((x) => x.uniqueId === uniqueId)

					if (productIndex > -1) {
						newOrderItem.products.splice(productIndex, 1)
					}

					if (newOrderItem.products.length === 0) {
						// remove the order item since it no longer has any products
						newOrderItems = newOrderItems.filter((value, i) => i !== orderItemIndex)
						setSelectedOrderItemIndex(null)
					} else if (newOrderItems[orderItemIndex]) {
						newOrderItem.id = v4()
						newOrderItems[orderItemIndex] = newOrderItem
					}
					setOrderItems([...newOrderItems])
				}
			}
		},
		[orderItems, setOrderItems, setSelectedOrderItemIndex],
	)

	const updateOrderItemAmount = useCallback(
		(orderItemId: string, uniqueId: string, amount: number, updateId: boolean) => {
			let newOrderItems = cloneDeep(orderItems)
			const orderItemIndex: number = newOrderItems.findIndex((x) => x.id === orderItemId)
			let productIndex: number | undefined = newOrderItems[orderItemIndex]?.products.findIndex(
				(x) => x.uniqueId === uniqueId,
			)
			if (productIndex === undefined || productIndex < 0) {
				return
			}

			const item = newOrderItems[orderItemIndex]
			if (item) {
				if (amount === 0) {
					item.products.splice(productIndex, 1)
					if (item.products.length === 0) {
						// remove the order item since it no longer has any products
						newOrderItems = newOrderItems.filter((value, i) => i !== orderItemIndex)
						setSelectedOrderItemIndex(null)
					}
				} else {
					const product = item.products[productIndex]

					if (product) {
						product.amount = amount
					}
				}

				if (updateId) {
					item.id = v4()
				}
				setOrderItems([...newOrderItems])
			}
		},
		[orderItems, setOrderItems, setSelectedOrderItemIndex],
	)

	const onProductIncrementorChange = useCallback(
		(
			buttonClicked: "addClick" | "removeClick" | "text",
			product: ProductDefinition,
			value: number,
			currentValue: number,
			category: string,
			service: string,
			project?: Project | GetProject,
		): "added" | "no-project" | "not-added" => {
			if (buttonClicked === "addClick") {
				return addProduct(
					[
						{
							productId: product.id,
							name: product.name,
							selectedWasteTypeAmounts: undefined,
							category: category,
							serviceId: service,
						},
					],
					1,
					project,
				)
			} else if (selectedOrderItemIndex !== null && buttonClicked === "removeClick") {
				lets(orderItems[selectedOrderItemIndex], (it) => {
					lets(
						it.products.find((x) => x.productId === product.id && x.serviceId === service)?.uniqueId,
						(productUniqueId) => {
							updateOrderItemAmount(it.id, productUniqueId, value, true)
						},
					)
				})
				return "added"
			} else if (selectedOrderItemIndex !== null && buttonClicked === "text") {
				if (value === 0 || value - currentValue < 0) {
					lets(orderItems[selectedOrderItemIndex], (it) => {
						lets(
							it.products.find((x) => x.productId === product.id && x.serviceId === service)?.uniqueId,
							(productUniqueId) => {
								updateOrderItemAmount(
									it.id,
									productUniqueId,
									value === 0 ? 0 : currentValue + (value - currentValue),
									true,
								)
							},
						)
					})
					return "added"
				} else {
					return addProduct(
						[
							{
								productId: product.id,
								name: product.name,
								selectedWasteTypeAmounts: undefined,
								category: category,
								serviceId: service,
							},
						],
						value - currentValue,
					)
				}
			}
			return "not-added"
		},
		[addProduct, orderItems, selectedOrderItemIndex, updateOrderItemAmount],
	)

	const updateOrderItemWasteTypeAmount = useCallback(
		(orderItemId: string, uniqueId: string, amount: number) => {
			let newOrderItems = cloneDeep(orderItems)
			if (amount === 0) {
				const idx = newOrderItems
					.find((x) => x.id === orderItemId)
					?.products.findIndex((x) => x.uniqueId === uniqueId)

				if (idx !== undefined && idx > -1) {
					newOrderItems.find((x) => x.id === orderItemId)?.products.splice(idx, 1)

					if (newOrderItems.find((x) => x.id === orderItemId)?.products.length === 0) {
						// remove the order item since it no longer has any products
						newOrderItems = newOrderItems.filter((value) => value.id !== orderItemId)
						setSelectedOrderItemIndex(null)
					}
				}
			} else {
				const item = newOrderItems.find((x) => x.id === orderItemId)

				if (item) {
					const product = item.products.find((x) => x.uniqueId === uniqueId)

					if (product && product.wasteType) {
						product.wasteType.amount = amount
					}
				}
			}
			setOrderItems([...newOrderItems])
		},
		[orderItems, setOrderItems, setSelectedOrderItemIndex],
	)

	const updateOrderItemPackagingAmount = useCallback(
		(orderItemId: string, uniqueId: string, amount: number) => {
			let newOrderItems = cloneDeep(orderItems)
			const newItem = newOrderItems.find((x) => x.id === orderItemId)

			if (!newItem) {
				return
			}

			if (amount === 0) {
				const idx = newItem.products.findIndex((x) => x.uniqueId === uniqueId)

				if (idx !== undefined && idx > -1) {
					newItem.products.splice(idx, 1)

					if (newItem.products.length === 0) {
						// remove the order item since it no longer has any products
						newOrderItems = newOrderItems.filter((value) => value.id !== orderItemId)
						setSelectedOrderItemIndex(null)
					}
				}
			} else {
				const product = newItem.products.find((x) => x.uniqueId === uniqueId)
				if (product && product.packagingMethod) {
					product.packagingMethod.amount = amount
				}
			}

			setOrderItems([...newOrderItems])
		},
		[orderItems, setOrderItems, setSelectedOrderItemIndex],
	)

	const onOrderItemProductIncrementorChange = useCallback(
		(orderItemId: string, uniqueId: string, value: number, type: "amount" | "waste" | "goods") => {
			if (type === "amount") {
				updateOrderItemAmount(orderItemId, uniqueId, value, false)
			} else if (type === "waste") {
				updateOrderItemWasteTypeAmount(orderItemId, uniqueId, value)
			} else if (type === "goods") {
				updateOrderItemPackagingAmount(orderItemId, uniqueId, value)
			}
		},
		[updateOrderItemAmount, updateOrderItemPackagingAmount, updateOrderItemWasteTypeAmount],
	)

	const setMobileBasketShown = useCallback((show: boolean) => {
		_setMobileBasketShown(show)
	}, [])

	useEffect(() => {
		if (orderItems.length === 0 && showRegularBasket) {
			setShowRegularBasket(false)
		}
	}, [])

	useEffect(() => {
		const orderItemsWithoutArticles: OrderItems = orderItems.map((x): OrderItemType => {
			const { articles, products, ...rest } = x
			const orderItemProducts = products as OrderItemProduct[]

			const orderItemProductsWithoutArticles: OrderItemProductType[] = orderItemProducts.map((x) => {
				const { articles, ...rest } = x
				return { ...rest }
			})

			return {
				products: orderItemProductsWithoutArticles,
				...rest,
			}
		})

		localStorage.setItem(`${client.identifier}.order-items`, JSON.stringify(orderItemsWithoutArticles))
	}, [client.identifier, orderItems])

	useEffect(() => {
		const values: BasketInstanceValues = {
			currentDiscountCode: currentDiscountCode,
			mobileBasketShown: mobileBasketShown,
			orderItemTotalPrices: orderItemTotalPrices,
			selectedOrderItemIndex: selectedOrderItemIndex,
			selectedProject: selectedProject,
		}

		const functions: BasketInstanceFunctions = {
			addProduct: addProduct,
			onOrderItemProductIncrementorChange: onOrderItemProductIncrementorChange,
			onProductIncrementorChange: onProductIncrementorChange,
			onProjectSelected: onProjectSelected,
			removeOrderItem: removeOrderItem,
			removeProductFromOrderItem: removeProductFromOrderItem,
			setCurrentDiscountCode: setCurrentDiscountCode,
			setDateOnOrderItem: setDateOnOrderItem,
			setMobileBasketShown: setMobileBasketShown,
			setOrderItems: setOrderItems,
			setSelectedOrderItem: setSelectedOrderItemIndex,
			setSelectedProject: setSelectedProject,
			setTimeOnOrderItem: setTimeOnOrderItem,
			updateOrderItemAmount: updateOrderItemAmount,
			updateOrderItemPackagingAmount: updateOrderItemPackagingAmount,
			updateOrderItemWasteTypeAmount: updateOrderItemWasteTypeAmount,
		}

		const instance = new BasketInstance(orderItems, values, functions)

		if (instance.isEqualTo(basketInstance)) {
			return
		}
		console.log("creating new basket instance")

		setBasketInstance(instance)
	}, [
		basketInstance,
		addProduct,
		client,
		consumerCatalog,
		currentDiscountCode,
		onOrderItemProductIncrementorChange,
		onProductIncrementorChange,
		orderItemTotalPrices,
		removeOrderItem,
		removeProductFromOrderItem,
		selectedOrderItemIndex,
		selectedProject,
		setDateOnOrderItem,
		setOrderItems,
		setTimeOnOrderItem,
		updateOrderItemWasteTypeAmount,
		setMobileBasketShown,
		mobileBasketShown,
		orderItems,
	])

	useEffect(() => {
		if (googleMapsLoaded && isArray(orderItems) && !savedOrderItemsChecked.current) {
			savedOrderItemsChecked.current = true

			const items = cloneDeep(orderItems)

			items.forEach((item) => {
				if (item.project.coordinates) {
					item.zoneId = getZoneIdFromPoint(client, item.project.coordinates)
				}
			})

			setOrderItems(items)
		}
	}, [orderItems, googleMapsLoaded, setOrderItems, client])

	/**
	 * Re-set articles on order items if the consumerCatalog or any other relevant parameter is updated.
	 */
	useEffect(() => {
		setOrderItems(orderItems)
	}, [client, consumerCatalog, currentDiscountCode])

	if (!basketInstance) {
		return (
			<AbsolutCentered>
				<Loader />
			</AbsolutCentered>
		)
	}

	return <BasketContext.Provider value={basketInstance}>{element}</BasketContext.Provider>
}
