import { Colors } from "Client/GetClientResponse"
import { colorMapping } from "Client/setBrandingCssVariables"
import { ReactNode, useState } from "react"
import { cls } from "Shared/cls"
import { getLogger } from "../../Logging/getLogger"
import style from "./BrandBuilder.module.css"

const logger = getLogger("BrandBuilder")

export const applyStyleIfDefined = (cssVariable: string, value?: string) => {
	if (!!value) {
		document.documentElement.style.setProperty(`--${cssVariable}`, value)
	}
}

function getColor(cssVariable: string) {
	const extracted = getComputedStyle(document.documentElement).getPropertyValue("--" + cssVariable)
	logger.debug("get color for, " + cssVariable + ": " + extracted)
	return extracted
}

function rebuildBrandingData() {
	const v = Object.entries(colorMapping).map(([color, cssVariable]) => {
		return [color, getColor(cssVariable)]
	})
	return Object.fromEntries(v)
}

export const BrandBuilder = () => {
	const [show, setShow] = useState(false)
	const [, setInc] = useState(0)
	const [selectedGroup, setSelectedGroup] = useState<BrandingGroups>(BrandingGroups.Elements)
	// const [colors, setColors] = useState<Colors>(rebuildBrandingData)

	let timer: NodeJS.Timeout | null = null
	const setColor = (color: keyof Colors, cssVariable: string, value: string) => {
		logger.debug("set new value on, " + cssVariable + ": " + value)
		applyStyleIfDefined(cssVariable, value)
		if (timer) clearTimeout(timer)
		timer = setTimeout(() => {
			setInc((old) => old + 1)
			// setColors((prev: Colors) => {
			// 	return { ...prev, [color]: value }
			// })
		}, 50)
	}

	const outputColors = () => {
		const colors = rebuildBrandingData()
		const colorsJson = JSON.stringify(colors, undefined, 2)
		logger.debug(colorsJson)
		navigator.clipboard.writeText(colorsJson).then(
			() => {
				alert("Branding datan finns nu i urklipp")
			},
			() => {
				alert("Gick inte att klistra in Branding datan i urklipp, så kolla i console loggen")
			},
		)
	}

	const colorToShow = Object.entries(colorDefs).filter(([colorKey, colorDef]) => {
		const group = GroupSectionMapping[colorDef.section]
		return group === selectedGroup
	})

	const groupColors: Map<BrandingSections, [string, ColorDef][]> = new Map()

	colorToShow.forEach(([colorKey, colorDef]) => {
		const section = colorDef.section
		const list = groupColors.get(section) ?? []
		list.push([colorKey, colorDef])
		groupColors.set(section, list)
	})

	const sections = []
	for (const key of groupColors.keys()) {
		sections.push(key)
	}

	return (
		<div className={cls(style.wrapper, { [style.show]: show })}>
			<div className={style.toggler} onClick={() => setShow(!show)}>
				&lt;
			</div>
			<div className={style.contents}>
				<h2>Colors</h2>
				<div className={style.groups}>
					{Object.values(BrandingGroups).map((group) => (
						<div
							style={{ cursor: "pointer" }}
							aria-selected={group === selectedGroup}
							onClick={() => setSelectedGroup(group)}
							key={group}>
							{group}
						</div>
					))}
				</div>
				<div className={style.sections}>
					{sections.map((sectionName) => {
						return (
							<FoldableSection title={sectionName} key={sectionName}>
								{groupColors.get(sectionName)?.map(([colorString, colorDef]) => {
									const colorKey = colorString as keyof Colors
									const cssVariable = colorMapping[colorKey]
									const name = colorDef.name ?? colorKey

									return (
										<div key={cssVariable} className={style.colorRow}>
											<label>{name}</label>
											<input
												type="color"
												value={getColor(cssVariable)}
												onChange={(e) => setColor(colorKey, cssVariable, e.target.value)}
											/>
										</div>
									)
								})}
							</FoldableSection>
						)
					})}
				</div>
			</div>
			<button type="button" onClick={outputColors}>
				Kopiera Branding
			</button>
		</div>
	)
}

function FoldableSection({ title, children }: { title: string; children: ReactNode }) {
	const [expanded, setExpanded] = useState(true)
	return (
		<div className={style.foldableSection} aria-expanded={expanded}>
			<div className={style.foldableTitle} onClick={() => setExpanded(!expanded)}>
				{title}
				<span>&lt;</span>
			</div>
			<div className={style.foldableContent}>{children}</div>
		</div>
	)
}

enum BrandingGroups {
	Elements = "Elements",
	Buttons = "Buttons",
	Other = "Other",
}

enum BrandingSections {
	Header = "Header",
	UnderHeader = "UnderHeader",
	Footer = "Footer",
	Main = "Main",
	Section = "Section",
	Module = "Module",
	Sidebar = "Sidebar",
	Basket = "Basket",
	AddButtons = "AddButtons",
	ConfirmButtons = "ConfirmButtons",
	DisableButtons = "DisableButtons",
	DateTimeButtons = "DateTimeButtons",
	FilterButtons = "FilterButtons",
	RadioButtons = "RadioButtons",
	ScrollButtons = "ScrollButtons",
	Collapsible = "Collapsible",
	ToggleButtons = "ToggleButtons",
	Confirmed = "Confirmed",
	Status = "Status",
	Product = "Product",
	Alerts = "Alerts",
	Delivery = "Delivery",
	Other = "Other",
}

const GroupSectionMapping: Record<BrandingSections, BrandingGroups> = {
	Header: BrandingGroups.Elements,
	UnderHeader: BrandingGroups.Elements,
	Footer: BrandingGroups.Elements,
	Main: BrandingGroups.Elements,
	Section: BrandingGroups.Elements,
	Module: BrandingGroups.Elements,
	Sidebar: BrandingGroups.Elements,
	Basket: BrandingGroups.Elements,
	Collapsible: BrandingGroups.Elements,
	AddButtons: BrandingGroups.Buttons,
	ConfirmButtons: BrandingGroups.Buttons,
	DisableButtons: BrandingGroups.Buttons,
	DateTimeButtons: BrandingGroups.Buttons,
	FilterButtons: BrandingGroups.Buttons,
	RadioButtons: BrandingGroups.Buttons,
	ScrollButtons: BrandingGroups.Buttons,
	ToggleButtons: BrandingGroups.Buttons,
	Confirmed: BrandingGroups.Other,
	Status: BrandingGroups.Other,
	Product: BrandingGroups.Other,
	Alerts: BrandingGroups.Other,
	Delivery: BrandingGroups.Other,
	Other: BrandingGroups.Other,
}

// Färgen, Sektionen, Namnet

const colorDefs: Record<keyof Colors, ColorDef> = {
	//Header
	header: color(BrandingSections.Header),
	headerDivider: color(BrandingSections.Header),
	headerShadow: color(BrandingSections.Header),
	headerIcon: color(BrandingSections.Header),
	headerChosenColor: color(BrandingSections.Header),
	headerTextColor: color(BrandingSections.Header),
	headerHoverColor: color(BrandingSections.Header),
	headerNotification: color(BrandingSections.Header),
	headerNotificationInside: color(BrandingSections.Header),
	headerButtonColor: color(BrandingSections.Header),
	headerButtonInsideColor: color(BrandingSections.Header),
	headerButtonInsideHoverColor: color(BrandingSections.Header),
	headerDropdownMain: color(BrandingSections.Header, "headerDp Main"),
	headerDropdownMainText: color(BrandingSections.Header, "headerDp MainText"),
	headerDropdownHover: color(BrandingSections.Header, "headerDp Hover"),
	headerDropdownChosen: color(BrandingSections.Header, "headerDp Chosen"),
	headerDropdownChosenInside: color(BrandingSections.Header, "headerDp ChosenInside"),
	headerDropdownSecondText: color(BrandingSections.Header, "headerDp SecondText"),
	headerDropdownAccent: color(BrandingSections.Header, "headerDp Accent"),
	headerDropdownAccentInside: color(BrandingSections.Header, "headerDp AccentInside"),
	headerDropdownAccentHover: color(BrandingSections.Header, "headerDp AccentHover"),
	headerDropdownAccentHoverInside: color(BrandingSections.Header, "headerDp AccentHoverInside"),
	headerDropdownSelectedColor: color(BrandingSections.Header, "headerDp SelectedColor"),
	headerDropdownSelectedColorInside: color(BrandingSections.Header, "headerDp SelectedInside"),
	headerDropdownSelectedHoverColor: color(BrandingSections.Header, "headerDp SelectedHover"),
	headerDropdownSelectedHoverColorInside: color(BrandingSections.Header, "headerDp SelectedHoverInside"),
	//Under Header
	underHeaderColor: color(BrandingSections.UnderHeader),
	underHeaderInsideColor: color(BrandingSections.UnderHeader),
	underHeaderChosenColor: color(BrandingSections.UnderHeader),
	underHeaderChosenInsideColor: color(BrandingSections.UnderHeader),
	underHeaderHoverColor: color(BrandingSections.UnderHeader),
	underHeaderHoverInsideColor: color(BrandingSections.UnderHeader),
	//Footer
	footer: color(BrandingSections.Footer),
	footerLogo: color(BrandingSections.Footer),
	//Main
	mainBackground: color(BrandingSections.Main),
	mainBackgroundText: color(BrandingSections.Main),
	inputFieldColorMback: color(BrandingSections.Main),
	//Section
	sectionBackground: color(BrandingSections.Section),
	selectedSectionBackground: color(BrandingSections.Section),
	sectionIcon: color(BrandingSections.Section),
	sectionBackgroundText: color(BrandingSections.Section),
	sectionHeaderColor: color(BrandingSections.Section),
	sectionHeaderInsideColor: color(BrandingSections.Section),
	sectionDiscountColor: color(BrandingSections.Section),
	sectionBorderColor: color(BrandingSections.Section),
	sectionHoverColor: color(BrandingSections.Section),
	//Module
	moduleBox: color(BrandingSections.Module),
	moduleBoxIcon: color(BrandingSections.Module),
	moduleBoxText: color(BrandingSections.Module),
	moduleBoxDiscountColor: color(BrandingSections.Module),
	moduleBubbleClientColor: color(BrandingSections.Module),
	moduleBubbleClientTextColor: color(BrandingSections.Module),
	moduleBubbleConsumerColor: color(BrandingSections.Module),
	moduleBubbleConsumerTextColor: color(BrandingSections.Module),
	//Sidebar
	sidebarColor: color(BrandingSections.Sidebar),
	sidebarChosenColor: color(BrandingSections.Sidebar),
	sidebarIconColor: color(BrandingSections.Sidebar),
	sidebarTextColor: color(BrandingSections.Sidebar),
	sidebarLogoColor: color(BrandingSections.Sidebar),
	sidebarHoverColor: color(BrandingSections.Sidebar),
	//Basket
	basketMainColor: color(BrandingSections.Basket),
	basketMainTextColor: color(BrandingSections.Basket),
	basketMainIconColor: color(BrandingSections.Basket),
	basketBackgroundColor: color(BrandingSections.Basket),
	basketBackgroundTextColor: color(BrandingSections.Basket),
	basketBackgroundIconColor: color(BrandingSections.Basket),
	basketModuleColor: color(BrandingSections.Basket),
	basketModuleTextColor: color(BrandingSections.Basket),
	basketModuleIconColor: color(BrandingSections.Basket),
	basketSectionColor: color(BrandingSections.Basket),
	basketSectionTextColor: color(BrandingSections.Basket),
	basketSectionIconColor: color(BrandingSections.Basket),
	basketFinalizeColor: color(BrandingSections.Basket),
	basketFinalizeInsideColor: color(BrandingSections.Basket),
	basketFinalizeIconColor: color(BrandingSections.Basket),
	basketFinalizeAccentColor: color(BrandingSections.Basket),
	basketFinalizeAccentInsideColor: color(BrandingSections.Basket),
	//AddButtons
	accent: color(BrandingSections.AddButtons),
	accentHover: color(BrandingSections.AddButtons),
	accentHoverInside: color(BrandingSections.AddButtons),
	accentInside: color(BrandingSections.AddButtons),
	//ConfirmButtons
	finalize: color(BrandingSections.ConfirmButtons),
	finalizeHover: color(BrandingSections.ConfirmButtons),
	finalizeHoverInside: color(BrandingSections.ConfirmButtons),
	finalizeInside: color(BrandingSections.ConfirmButtons),
	//DisableButtons
	disable: color(BrandingSections.DisableButtons),
	disableInside: color(BrandingSections.DisableButtons),
	//DateTimeButtons
	sectionValueBox: color(BrandingSections.DateTimeButtons),
	sectionValueBoxHover: color(BrandingSections.DateTimeButtons),
	sectionValueBoxInside: color(BrandingSections.DateTimeButtons),
	sectionValueBoxHoverInside: color(BrandingSections.DateTimeButtons),
	selectedSectionValueBox: color(BrandingSections.DateTimeButtons),
	selectedSectionValueBoxHover: color(BrandingSections.DateTimeButtons),
	selectedSectionValueBoxInside: color(BrandingSections.DateTimeButtons),
	//Filterbuttons
	filterButtonColor: color(BrandingSections.FilterButtons),
	filterButtonHoverColor: color(BrandingSections.FilterButtons),
	filterButtonInsideColor: color(BrandingSections.FilterButtons),
	filterButtonHoverInsideColor: color(BrandingSections.FilterButtons),
	//RadioButtons
	accentSecondaryColor: color(BrandingSections.RadioButtons),
	accentSecondaryHoverColor: color(BrandingSections.RadioButtons),
	accentSecondaryInsideColor: color(BrandingSections.RadioButtons),
	//ScrollButtons
	imageScrollAccentInsideColor: color(BrandingSections.ScrollButtons),
	imageScrollAccentHoverColor: color(BrandingSections.ScrollButtons),
	imageScrollAccentHoverInsideColor: color(BrandingSections.ScrollButtons),
	//Collapsible
	collapsibleTitleBackgroundColor: color(BrandingSections.Collapsible),
	collapsibleTitleColor: color(BrandingSections.Collapsible),
	collapsibleContentBackgroundColor: color(BrandingSections.Collapsible),
	collapsibleContentColor: color(BrandingSections.Collapsible),
	//Confirmed
	confirmedAmount: color(BrandingSections.Confirmed),
	confirmedAmountInside: color(BrandingSections.Confirmed),
	//Status
	statusNotManagedColor: color(BrandingSections.Status),
	statusNotManagedInsideColor: color(BrandingSections.Status),
	statusAcceptedColor: color(BrandingSections.Status),
	statusAcceptedInsideColor: color(BrandingSections.Status),
	statusDeniedColor: color(BrandingSections.Status),
	statusDeniedInsideColor: color(BrandingSections.Status),
	statusDoneColor: color(BrandingSections.Status),
	statusDoneInsideColor: color(BrandingSections.Status),
	//Product
	productBase: color(BrandingSections.Product),
	productSecondary: color(BrandingSections.Product),
	productDetails: color(BrandingSections.Product),
	productImageBackgroundColor: color(BrandingSections.Product),
	productSectionColor: color(BrandingSections.Product),
	productSectionTextColor: color(BrandingSections.Product),
	productBorderColor: color(BrandingSections.Product),
	//Alerts
	invalid: color(BrandingSections.Alerts),
	valid: color(BrandingSections.Alerts),
	//Other
	inputField: color(BrandingSections.Other),
	inputFieldInside: color(BrandingSections.Other),
	hoverBorder: color(BrandingSections.Other),
	chosenBorder: color(BrandingSections.Other),
	clientNotificationColor: color(BrandingSections.Other),
	clientNotificationInsideColor: color(BrandingSections.Other),
	deliveryColor: color(BrandingSections.Other),
	chatNotificationColor: color(BrandingSections.Other),
	chatNotificationInsideColor: color(BrandingSections.Other),
	chatNotificationHoverColor: color(BrandingSections.Other),
	//ToggleButtons
	toggleBackgroundColor: color(BrandingSections.ToggleButtons),
	toggleBackgroundInsideColor: color(BrandingSections.ToggleButtons),
	toggleSelectedColor: color(BrandingSections.ToggleButtons),
	toggleSelectedInsideColor: color(BrandingSections.ToggleButtons),
}

function color(section: BrandingSections, name?: string): ColorDef {
	return { section, name }
}

type ColorDef = {
	section: BrandingSections
	name?: string
}
