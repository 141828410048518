import { faClock, faFile } from "@fortawesome/free-regular-svg-icons"
import { faAngleDown, faAngleRight, faArrowsLeftRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { exhaustive } from "exhaustive"
import _, { forEach, isArray, isEmpty, isEqual, isNumber, isString, sum, toNumber } from "lodash"
import React, { FC, useCallback, useEffect, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { v4 } from "uuid"
import { z } from "zod"
import { MeResponseAccountType } from "../../../Auth/Auth.types"
import { useAuth } from "../../../Auth/AuthContext"
import { useClient } from "../../../Client/ClientAndUserProvider"
import { ClientInstance, WasteProductCategoryInstance } from "../../../Client/ClientInstance"
import { ConsumerCatalogInstance, useConsumerCatalog } from "../../../Client/ConsumerCatalogContext"
import { SelectedConsumerUserSchema } from "../../../Client/ConsumerContext"
import { ProductService, ProductServiceUnit } from "../../../Client/ProductDefinitionsByCategories"
import { findDiscountDescription } from "../../../CustomerPortal/OrderDetails/ExplainDiscount/ExplainDiscount"
import {
	ArrowLeftIcon,
	CrossIcon,
	DatumIcon,
	InformationIcon,
	KontaktIcon,
	MarkingIcon,
	PenIcon,
	TrashIcon,
	VarukorgIcon,
	ZonkartaIcon,
} from "../../../Icons/Icon"
import { getLogger } from "../../../Logging/getLogger"
import { useNavigator } from "../../../Navigator/useNavigator"
import { PermissionAreaLocation, usePermissions } from "../../../PermissionContext"
import { ProductImageType } from "../../../ProductDefinitions"
import { anyToLoggableString } from "../../../Shared/anyToLoggableString"
import { cls } from "../../../Shared/cls"
import { EventQueue } from "../../../Shared/eventQueue"
import { getKey } from "../../../Shared/getKey"
import { lets } from "../../../Shared/lets"
import { currencyFormatter } from "../../../Shared/numberFormatter"
import { DiscountEntry } from "../../../Shared/PriceDisplay/DiscountEntry"
import { Price } from "../../../Shared/PriceDisplay/Price"
import { StdTooltip } from "../../../Shared/StdTooltip/StdTooltip"
import { throwIllegalState } from "../../../Shared/throwIllegalState"
import { useBrandedLocalStorage } from "../../../Shared/useBrandedLocalStorage"
import { DropdownItem, ExpandableDropdown } from "../../Components/ExpandableDropdown/ExpandableDropdown"
import { FinalizeButton } from "../../Components/Form/Buttons/Buttons"
import { HorizontalScrollBox } from "../../Components/HorizontalScrollBox/HorizontalScrollBox"
import { Incrementor } from "../../Components/Incrementor/Incrementor"
import { IncrementorPill } from "../../Components/IncrementorPill/IncrementorPill"
import { MobileBasketFooter } from "../../Components/MobileBasketFooter/MobileBasketFooter"
import { addModalOpenClass, isModalOpen } from "../../Components/ModulePopup/ModulePopup"
import { ProductImage } from "../../Components/ProductImage/ProductImage"
import { MbtH5 } from "../../Components/Text/MbtH5/MbtH5"
import { SbtH2 } from "../../Components/Text/SbtH2/SbtH2"
import { SbtH6 } from "../../Components/Text/SbtH6/SbtH6"
import { getDatesFromDateSlotAsString } from "../../DateSelectModule/DateSelectModule"
import { OrderItem, OrderItemProduct, OrderItemTime, Project } from "../../order-data-model"
import { unitFormatter } from "../../unit-formatter"
import { useBasket } from "../BasketProvider"
import {
	getAmountOfProductsInOrderItem,
	getAmountOfProductsInOrderItemProduct,
	productServiceUnitToHumanText,
	productServiceUnitToShortHumanText,
} from "../Logic"
import { removeModalOpen } from "../OrderContainer"
import orderContainerStyle from "../OrderContainer.module.css"
import { modalOpenQueryKey } from "../OrderContainerWrapper"
import style from "./BasketSection.module.css"

const logger = getLogger("BasketSection")

const basketTooltipHelperText: string =
	"Observera att en beställning separeras i olika ordrar beroende på vilken transport som krävs." +
	" " +
	"Endast en transport-typ per order är möjlig och beroende på val så kan ytterligare ordrar läggas till automatiskt"

type Props = {
	hideShowBasketButton: boolean
	submitDisabled: boolean
	onOrderItemSelected: (index: number) => void
	onOrderItemEditProject: (index: number) => void
	onRemoveOrderItem: (index: number) => void
	onOrderItemEditDate: (index: number) => void
	onOrderItemEditTime: (index: number) => void
	onRemoveProductFromOrderItem: (orderItemId: string, uniqueId: string) => void
	onOrderItemProductIncrementorChange: (
		orderItemId: string,
		uniqueId: string,
		value: number,
		type: "amount" | "waste" | "goods",
	) => void
}

export function orderItemZoneElement(
	orderItem: OrderItem,
	client: ClientInstance,
	consumerCatalog: ConsumerCatalogInstance,
	skipPrices: boolean = false,
	className?: string,
): JSX.Element | null {
	if (!client.features.orderUiRenderTransportZones) {
		return null
	}

	if (!orderItem.zoneId || (orderItem.zoneId && !client.transportZones[orderItem.zoneId])) {
		return (
			<div className={cls(orderContainerStyle.orderItemZone, orderContainerStyle.orderItemZoneNotFound, className)}>
				<div className={orderContainerStyle.orderItemZoneName}>
					Ingen transportzon kunde hittas för adressen, extra avgifter kan tillkomma i efterhand
				</div>
			</div>
		)
	}

	let priceElement: JSX.Element | null = null

	if (consumerCatalog.pricesEnabled && !skipPrices) {
		let article = orderItem?.articles?.getTransportationArticle()

		if (article) {
			let price
			if (consumerCatalog.renderVAT) {
				price = article.price * (1 + article.taxPercentage)
			} else {
				price = article.price
			}
			priceElement = (
				<div style={{ marginTop: "10px", fontSize: "16px" }}>
					<strong>{currencyFormatter(price)}</strong>
				</div>
			)
		} else {
			priceElement = (
				<div style={{ marginTop: "10px", fontSize: "16px" }}>
					<strong>Inget pris kunde hittas för transportzonen, extra avgifter kan tillkomma i efterhand</strong>
				</div>
			)
		}
	}

	return (
		<div className={cls(orderContainerStyle.orderItemZone, className)}>
			<div className={orderContainerStyle.orderItemZoneName}>
				<div>
					Transportzon: <strong>{client.transportZones[orderItem.zoneId]?.name || "(namnlös)"}</strong>
				</div>
				{priceElement}
			</div>
			<div style={{ display: "grid" }}>
				<ZonkartaIcon
					className={orderContainerStyle.zoneMapIconWrapper}
					iconClassName={orderContainerStyle.zoneMapIcon}
					size={44}
				/>
			</div>
		</div>
	)
}

export function orderItemDateSlotPriceElement(
	orderItem: OrderItem,
	client: ClientInstance,
	consumerCatalog: ConsumerCatalogInstance,
	className?: string,
): JSX.Element | null {
	let date = orderItem.date
	if (!date || isString(date) || !consumerCatalog.pricesEnabled) {
		return null
	}

	let article = orderItem?.articles?.getDateSlotArticle()

	if (article) {
		let price

		if (consumerCatalog.renderVAT) {
			price = article.price * (1 + article.taxPercentage)
		} else {
			price = article.price
		}
		return (
			<div className={cls(orderContainerStyle.orderItemZone, className)} style={{ marginTop: "10px" }}>
				<div className={orderContainerStyle.orderItemZoneName}>
					<div>
						Datum: <strong>{client.possibleDateSlots[date.dateSlotId]?.name || "(namnlös)"}</strong>
					</div>
					<div style={{ marginTop: "10px", fontSize: "16px" }}>
						<strong>{currencyFormatter(price)}</strong>
					</div>
				</div>
				<div style={{ display: "grid" }}>
					<DatumIcon
						className={orderContainerStyle.zoneMapIconWrapper}
						iconClassName={orderContainerStyle.zoneMapIcon}
						size={38}
					/>
				</div>
			</div>
		)
	} else {
		return null
	}
}

/**
 * Contact information for a project on an order item.
 * Concatenated and clamped to one string and line, respectively
 * @param project
 * @param strongName whether or not to wrap name in <strong>
 */
export function contactInformationOneLineText(project: Project, strongName: boolean = false): JSX.Element | null {
	let contactInformation = null

	if (project?.contactName || project?.contactPhone) {
		contactInformation = (
			<span className="oneLineClamp">
				{strongName ? <strong>{project.contactName}</strong> : project.contactName}, {project.contactPhone}
			</span>
		)
	}

	const contactPersons = project?.contactPersons
	if (isArray(contactPersons) && contactPersons.length > 0) {
		contactInformation = (
			<span className="oneLineClamp">
				{contactPersons.map((item, index) => {
					return (
						<React.Fragment key={item.id}>
							{index > 0 ? "; " : ""}
							{strongName ? <strong>{item.name}</strong> : item.name}, {item.phone}
						</React.Fragment>
					)
				})}
			</span>
		)
	}

	return contactInformation
}

export const BasketSection: FC<Props> = ({
	hideShowBasketButton,
	submitDisabled,
	onOrderItemSelected,
	onOrderItemEditProject,
	onRemoveOrderItem,
	onOrderItemEditDate,
	onOrderItemEditTime,
	onRemoveProductFromOrderItem,
	onOrderItemProductIncrementorChange,
}) => {
	const client = useClient()
	const auth = useAuth()
	const consumerCatalog = useConsumerCatalog()
	const navigator = useNavigator()
	const permissions = usePermissions()
	const basket = useBasket()

	const [queryParams, setQueryParams] = useSearchParams()

	const [orderItems, setOrderItems] = useState<OrderItem[]>(basket.orderItems)

	const regularBasketWrapper = useRef<HTMLDivElement>(null)
	const regularBasketContentWrapper = useRef<HTMLDivElement>(null)
	const basketItemsWrapper = useRef<HTMLDivElement>(null)

	const [clientSelectedConsumerId] = useBrandedLocalStorage("client-selected-consumer-id", z.string(), {
		defaultValue: "",
	})
	const [selectedUserLocalstorage] = useBrandedLocalStorage(
		"client-selected-consumer-user",
		z.string().or(SelectedConsumerUserSchema),
		{
			defaultValue: "",
		},
	)

	const [hiddenOrderItems, setHiddenOrderItems] = useState<{ [id: string]: boolean }>({})

	const [showRegularBasket, setShowRegularBasket] = useBrandedLocalStorage("show-regular-basket", z.boolean(), {
		defaultValue: false,
	})

	const [orderItemSelectionListElement, setOrderItemSelectionListElement] = useState<HTMLDivElement | null>(null)
	const [mobileBasketHeaderRef, setMobileBasketHeaderRef] = useState<HTMLDivElement | null>(null)

	const [showBasketErrorsList, setShowBasketErrorsList] = useState(false)

	const escFunction = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === "Escape" && !queryParams.get(modalOpenQueryKey)) {
				if (basket.values.mobileBasketShown) {
					basket.functions.setMobileBasketShown(false)
				}
			}
		},
		[basket.functions, queryParams],
	)

	useEffect(() => {
		if (!isEqual(basket.orderItems, orderItems)) {
			setOrderItems(basket.orderItems)

			if (basket.orderItems.length > orderItems.length) {
				setTimeout(() => {
					const ref = regularBasketContentWrapper.current

					if (ref) {
						ref.scrollTo({ behavior: "smooth", top: ref.scrollHeight || 0 })
					}
				}, 150)
			}
		}
	}, [basket.orderItems, orderItems])

	useEffect(() => {
		document.addEventListener("keydown", escFunction, false)

		return () => {
			document.removeEventListener("keydown", escFunction, false)
		}
	}, [escFunction])

	useEffect(() => {
		if (!orderItemSelectionListElement) {
			return
		}

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.intersectionRatio !== 1) {
						entry.target.classList.add("orderItemSelectionListSticky")
					}
				})
			},
			{ threshold: [0.99, 1] },
		)
		observer.observe(orderItemSelectionListElement)

		return () => {
			observer.disconnect()
		}
	}, [orderItemSelectionListElement])

	useEffect(() => {
		if (!mobileBasketHeaderRef || !orderItemSelectionListElement) {
			return
		}

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (orderItemSelectionListElement && entry.intersectionRatio > 0.4) {
						orderItemSelectionListElement.classList.remove("orderItemSelectionListSticky")
					}
				})
			},
			{ threshold: [0.4] },
		)
		observer.observe(mobileBasketHeaderRef)

		return () => {
			observer.disconnect()
		}
	}, [mobileBasketHeaderRef, orderItemSelectionListElement])

	useEffect(() => {
		if (basket.values.mobileBasketShown) {
			document.body.classList.add("mobile-basket-open")
			addModalOpenClass()
		} else {
			if (isModalOpen()) {
				if (document.body.classList.contains("mobile-basket-open")) {
					document.body.classList.remove("mobile-basket-open")
				}

				removeModalOpen(queryParams, setQueryParams)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [basket.values.mobileBasketShown])

	useEffect(() => {
		const index = basket.values.selectedOrderItemIndex
		if (index === null || basket.values.mobileBasketShown) {
			return
		}

		setTimeout(() => {
			const element = document.getElementsByClassName("orderItemSelectionListItem")[index]

			if (element) {
				element.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
			}
		}, 600)
	}, [basket.values.selectedOrderItemIndex, basket.values.mobileBasketShown])

	const allServices: { [serviceId: string]: ProductService } = {}

	forEach(client.categories, (x) => {
		if (x.type === "WasteCategory") {
			forEach(x.services, (y, key) => {
				allServices[key] = y
			})
		}
	})

	function orderItemProductPriceElement(orderItemProduct: OrderItemProduct, orderItem: OrderItem): JSX.Element | null {
		if (!consumerCatalog.pricesEnabled) {
			return null
		}

		const article = orderItemProduct?.articles?.getProductArticle()

		if (article == null) {
			if (auth.Me?.type === MeResponseAccountType.Client) {
				/**
				 * FIXME This is a hack, it should be integrated with {@Link ProductDefinitionWithPrice}
				 */
				return <div>Ej prissatt</div>
			}

			return <div>Inget pris satt, beställning ej möjlig, ta bort produkten från ordern för att fortsätta</div>
		}

		let discountDescription = lets(orderItemProduct.discountDescriptionRef, (it) => {
			return findDiscountDescription(it, orderItem.articles?.allArticles || [])
		})

		let category = client.categories[orderItemProduct.category]
		if (category == null) {
			throwIllegalState("Category missing! name: " + orderItemProduct.category)
		}

		const unitElement = exhaustive(category, "type", {
			WasteCategory: (category: WasteProductCategoryInstance) => {
				if (orderItemProduct.serviceId) {
					return productServiceUnitToHumanText(category.services[orderItemProduct.serviceId]?.unit)
				}
				return null
			},
			GoodsCategory: () => {
				if (orderItemProduct.packagingMethod) {
					return productServiceUnitToHumanText(
						client.possiblePackagingMethods[orderItemProduct.packagingMethod.id]?.productUnit,
					)
				}
				return null
			},
		})

		return (
			<div className={orderContainerStyle.orderItemPriceCell}>
				<Price priced={article} discountDescription={discountDescription} /> / {unitElement}
			</div>
		)
	}

	function orderItemPriceSummaryTable(orderItem: OrderItem): JSX.Element | null {
		const orderItemTotalPrice = basket.values.orderItemTotalPrices[orderItem.id]
		if (!consumerCatalog.pricesEnabled || !orderItemTotalPrice) {
			return null
		}

		const transportPriceArticle = orderItem?.articles?.getTransportationArticle()

		const dateSlotPriceArticle = orderItem?.articles?.getDateSlotArticle()

		// Optionally remove transport fee from shown price
		const articlesPrice = currencyFormatter(
			orderItemTotalPrice.price +
				(consumerCatalog.renderVAT ? orderItemTotalPrice.tax : 0) -
				(lets(transportPriceArticle, (article) => {
					if (consumerCatalog.renderVAT) {
						return article.price * (1 + article.taxPercentage)
					} else {
						return article.price
					}
				}) || 0) -
				(lets(dateSlotPriceArticle, (article) => {
					if (consumerCatalog.renderVAT) {
						return article.price * (1 + article.taxPercentage)
					} else {
						return article.price
					}
				}) || 0),
		)

		const productPriceElement: JSX.Element = (
			<div key={v4()} className={style.orderItemPriceSummaryRow}>
				<span>Varor ({orderItemTotalPrice.amountOfArticles.toString()} st)</span>
				<span>{articlesPrice}</span>
			</div>
		)

		const transportPriceElement: JSX.Element | null = transportPriceArticle ? (
			<div key={v4()} className={orderContainerStyle.orderItemPriceSummaryZoneRow} style={{ marginTop: "5px" }}>
				<div className={style.sectionTextColor}>Transportavgift</div>
				<div className={style.sectionTextColor}>
					{consumerCatalog.renderVAT
						? currencyFormatter(transportPriceArticle.price * (1 + transportPriceArticle.taxPercentage))
						: currencyFormatter(transportPriceArticle.price)}
				</div>
			</div>
		) : null

		const dateSlotPriceElement: JSX.Element | null = dateSlotPriceArticle ? (
			<div key={v4()} className={orderContainerStyle.orderItemPriceSummaryZoneRow} style={{ marginTop: "5px" }}>
				<div className={style.sectionTextColor}>Datumavgift</div>
				<div className={style.sectionTextColor}>
					{consumerCatalog.renderVAT
						? currencyFormatter(dateSlotPriceArticle.price * (1 + dateSlotPriceArticle.taxPercentage))
						: currencyFormatter(dateSlotPriceArticle.price)}
				</div>
			</div>
		) : null

		const discountElements = lets(orderItem.articles?.getDiscountArticles() || [], (discountArticles) => {
			if (isEmpty(discountArticles)) return null
			return (
				<>
					<hr className={style.hr} />
					<div className={style.orderItemPriceSummaryHeader}>Rabatt</div>
					<div>
						{discountArticles.map((discountArticle) => {
							return (
								<DiscountEntry
									discountArticle={discountArticle.article}
									className={style.orderItemPriceSummaryRow}
									key={getKey(discountArticle.article)}
								/>
							)
						})}
					</div>
				</>
			)
		})

		return (
			<span>
				{discountElements}
				<hr className={style.hr} />
				<div className={style.orderItemPriceSummaryHeader}>Summa</div>
				{productPriceElement}
				{transportPriceElement}
				{dateSlotPriceElement}
				{orderItems.length === 1 ? (
					<>
						<hr className={style.hr} />
						<div className={style.orderItemPriceSummaryTotal}>
							<span>Totalt</span>
							<span>
								{consumerCatalog.renderVAT
									? currencyFormatter(orderItemTotalPrice.price + orderItemTotalPrice.tax)
									: currencyFormatter(orderItemTotalPrice.price)}
							</span>
						</div>
						<div className={style.orderItemPriceSummaryTotalSubRow} style={{ marginTop: "5px" }}>
							{consumerCatalog.renderVAT ? (
								<>
									<span>Varav moms</span>
									<span>{currencyFormatter(orderItemTotalPrice.tax)}</span>
								</>
							) : (
								<>
									<span>Priser är exklusive moms</span>
									<span></span>
								</>
							)}
						</div>
					</>
				) : null}
			</span>
		)
	}

	function goToCheckout() {
		if (!permissions.isAllowed(PermissionAreaLocation.Order_Process_Start)) {
			return
		}

		if (submitDisabled) {
			setShowBasketErrorsList(true)
			return
		}

		if (!client.features.orderUiAllowAnonymousOrders && !auth.IsLoggedIn) {
			if (client.features.orderUiAllowLoggingIn) {
				EventQueue.dispatchEvent("openLoginModal", { redirectTo: "order/checkout" })
			}
		} else {
			if (basket.values.mobileBasketShown) {
				basket.functions.setMobileBasketShown(false)
				removeModalOpen(queryParams, setQueryParams)
			}
			navigator.open("order/checkout")
		}
	}

	function basketErrorsList() {
		if (!showBasketErrorsList || orderItems.length === 0 || !submitDisabled) {
			return
		}

		const consumerUserNeeded = auth.IsLoggedIn && clientSelectedConsumerId && !selectedUserLocalstorage
		return (
			<div
				className={cls(
					style.errorsListWrapper,
					{ [style.multipleOrders]: orderItems.length > 1 },
					{ [style.pricesEnabled]: consumerCatalog.pricesEnabled },
				)}>
				<div className={style.errorsListHeader}>
					<div>
						<div style={{ fontWeight: 600 }}>Komplettering behövs</div>
						<div style={{ fontSize: "14px" }}>Korrigera följande fel för att kunna lägga en beställning</div>
					</div>
					<span
						onClick={() => {
							setShowBasketErrorsList(false)
						}}>
						<CrossIcon size={26} className={style.closeIcon} />
					</span>
				</div>
				<div className={style.errorRows}>
					{orderItems.map((orderItem, index) => {
						let project = `${orderItem.project.marking || orderItem.project.street}${
							orderItem.project.zipcode ? ", " + orderItem.project.zipcode : ""
						}${orderItem.project.city ? ", " + orderItem.project.city : ""}`
						let date: JSX.Element | null = null
						let time: JSX.Element | null = null
						if (!orderItem.date) {
							date = (
								<div
									className={style.errorRow}
									onClick={(e) => {
										e.preventDefault()
										e.stopPropagation()
										onOrderItemEditDate(index)
									}}>
									<div className={style.errorHeader}>Välj datum</div>
									<div className={style.errorDescription}>
										#{index + 1} {project}
									</div>
								</div>
							)
						}

						if (!orderItem.time) {
							time = (
								<div
									className={style.errorRow}
									onClick={(e) => {
										e.preventDefault()
										e.stopPropagation()
										onOrderItemEditTime(index)
									}}>
									<div className={style.errorHeader}>Välj tid</div>
									<div className={style.errorDescription}>
										#{index + 1} {project}
									</div>
								</div>
							)
						}

						return (
							<React.Fragment key={orderItem.id + "_errorsList"}>
								{date}
								{time}
							</React.Fragment>
						)
					})}
					{consumerUserNeeded ? (
						<div
							className={style.errorRow}
							onClick={() => {
								EventQueue.dispatchEvent("show-client-consumer-user-selection", {})
							}}>
							<div className={style.errorHeader}>Välj beställare</div>
							<div className={style.errorDescription}>
								Eftersom du har en kund vald måste du också ha en vald beställare
							</div>
						</div>
					) : null}
				</div>
			</div>
		)
	}

	function mobileBasketElement(): JSX.Element | null {
		if (!basket.values.mobileBasketShown) {
			return null
		}

		let discountSum = consumerCatalog.renderVAT
			? sum(Object.values(basket.values.orderItemTotalPrices).map((x) => x.discount + x.taxDiscount))
			: sum(Object.values(basket.values.orderItemTotalPrices).map((x) => x.discount))
		return (
			<div className={orderContainerStyle.mobileBasketWrapper}>
				<div
					className={orderContainerStyle.mobileBasketHeader}
					ref={(ref) => {
						setMobileBasketHeaderRef(ref)
					}}>
					<div className={orderContainerStyle.mobileBasketBackArrowAndStatusWrapper}>
						<span
							style={{ width: "28px", alignSelf: "center", cursor: "pointer" }}
							onClick={() => basket.functions.setMobileBasketShown(false)}>
							<ArrowLeftIcon size={28} className={style.arrowLeftIcon} />
						</span>
						<ul className={orderContainerStyle.timeline}>
							<li className={orderContainerStyle.complete}>
								<div />
							</li>
							<div className={orderContainerStyle.timelineLineComplete} />
							<li className={orderContainerStyle.current}>
								<div />
							</li>
							<div className={orderContainerStyle.timelineLine} />
							<li>
								<div />
							</li>
						</ul>
						<div />
					</div>
					<div style={{ display: "flex" }}>
						<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
							<VarukorgIcon size={34} iconClassName={style.basketIcon} />
							<h1 className={style.mobileBasketMainHeaderText}>Varukorg</h1>
						</div>
						<StdTooltip id="mobileBasketTopInfoText" openOnClick={true} />
						<div
							data-tooltip-id="mobileBasketTopInfoText"
							data-tooltip-content={basketTooltipHelperText}
							data-tooltip-place="bottom">
							<InformationIcon size={24} iconClassName={style.tooltipIcon} />
						</div>
					</div>
					<hr className={style.hr} />
					{basketHeaderListMobile()}
				</div>
				{basketContentElement()}
				<div className={style.mobileBasketFinalizeButton}>
					{orderItems.length > 1 ? (
						<div
							style={{ display: "flex", flexDirection: "column", width: "100%", margin: "15px 20px 0 20px" }}>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									fontSize: "20px",
									fontWeight: 600,
								}}>
								<span className={style.basketMainTextColor}>
									{consumerCatalog.pricesEnabled ? (
										<>
											Totalt <span style={{ fontSize: "14px" }}>({orderItems.length} st ordrar)</span>
										</>
									) : (
										<>Antal ordrar</>
									)}
								</span>
								<span className={style.basketMainTextColor}>
									{consumerCatalog.pricesEnabled ? (
										consumerCatalog.renderVAT ? (
											currencyFormatter(
												sum(
													Object.values(basket.values.orderItemTotalPrices).map(
														(x) => x.price + x.tax,
													),
												),
											)
										) : (
											currencyFormatter(
												sum(Object.values(basket.values.orderItemTotalPrices).map((x) => x.price)),
											)
										)
									) : (
										<>{orderItems.length} st</>
									)}
								</span>
							</div>
							{discountSum < 0 ? (
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										marginTop: "5px",
										fontSize: "14px",
									}}
									className={style.basketMainTextColor}>
									<span>Rabatt</span>
									<span>{currencyFormatter(discountSum)}</span>
								</div>
							) : null}
							{consumerCatalog.pricesEnabled ? (
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										marginTop: "5px",
										fontSize: "14px",
									}}
									className={style.basketMainTextColor}>
									{consumerCatalog.renderVAT ? (
										<>
											<span>Varav moms</span>
											<span>
												{currencyFormatter(
													sum(
														Object.values(basket.values.orderItemTotalPrices).map((x) => x.tax),
													),
												)}
											</span>
										</>
									) : (
										<>
											<span>Priser är exklusive moms</span>
											<span></span>
										</>
									)}
								</div>
							) : (
								<div></div>
							)}
						</div>
					) : null}
					{basketErrorsList()}
					<FinalizeButton
						disabled={
							orderItems.length === 0 || !permissions.isAllowed(PermissionAreaLocation.Order_Process_Start)
						}
						className={orderContainerStyle.basketFinalizeButton}
						style={{
							opacity: !permissions.isAllowed(PermissionAreaLocation.Order_Process_Start) ? 0 : undefined,
						}}
						onClick={() => {
							goToCheckout()
						}}>
						Lägg din beställning
					</FinalizeButton>
					<MobileBasketFooter />
				</div>
			</div>
		)
	}

	function basketHeaderListRegular(): JSX.Element | null {
		return (
			<HorizontalScrollBox cellClassName={style.orderItemSelectionList} key={`basketSelectionListRegular`}>
				{basketHeaderListSelectionContent()}
			</HorizontalScrollBox>
		)
	}

	function basketHeaderListMobile(): JSX.Element | null {
		return (
			<HorizontalScrollBox
				wrapperElementGet={(wrapperElement: HTMLDivElement) => {
					if (basket.values.mobileBasketShown) {
						setOrderItemSelectionListElement(wrapperElement)
					}
				}}
				cellClassName={style.orderItemSelectionList}
				key={`basketSelectionListMobile`}>
				{basketHeaderListSelectionContent()}
			</HorizontalScrollBox>
		)
	}

	function basketHeaderListSelectionContent(): JSX.Element[] {
		return orderItems.map((orderItem, index) => {
			return (
				<div
					key={orderItem.id + "_selectionItem"}
					className={cls(style.orderItemSelectionListItem, "orderItemSelectionListItem")}
					onClick={() => {
						const element = document.getElementsByClassName("basketItemSection")[index]
						if (element) {
							element.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
						}
						onOrderItemSelected(index)
					}}>
					<div
						className={style.orderItemSelectionListItemContent}
						style={{ fontWeight: basket.values.selectedOrderItemIndex === index ? 800 : undefined }}>
						<FontAwesomeIcon icon={faFile} />
						<span>#{index + 1}</span>
					</div>
					<div
						className={cls(
							style.orderItemSelectionListItemIndicatorRow,
							"orderItemSelectionListItemIndicatorRow",
						)}
						style={{ visibility: basket.values.selectedOrderItemIndex === index ? "visible" : "hidden" }}></div>
				</div>
			)
		})
	}

	function regularBasketElement(): JSX.Element | null {
		if (!showRegularBasket) {
			return null
		}

		let discountSum = consumerCatalog.renderVAT
			? sum(Object.values(basket.values.orderItemTotalPrices).map((x) => x.discount + x.taxDiscount))
			: sum(Object.values(basket.values.orderItemTotalPrices).map((x) => x.discount))
		return (
			<div className={orderContainerStyle.regularBasketWrapper}>
				<div ref={regularBasketWrapper} className={orderContainerStyle.basketWrapper}>
					<div className={style.regularBasketHeaderSection}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<span style={{ display: "flex" }}>
								<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
									<VarukorgIcon size={28} iconClassName={style.basketIcon} />
									<h1
										style={{
											fontSize: "28px",
											margin: 0,
											color: "var(--basket-main-text-color)",
										}}>
										Varukorg
									</h1>
								</div>
								<div>
									<StdTooltip id="regularBasketTopInfoText" openOnClick={true} />
									<div
										data-tooltip-id="regularBasketTopInfoText"
										data-tooltip-content={basketTooltipHelperText}
										data-tooltip-place="bottom">
										<InformationIcon size={24} iconClassName={style.tooltipIcon} />
									</div>
								</div>
							</span>
							<button
								type="button"
								className={orderContainerStyle.basketCollapseButton}
								onClick={() => {
									setShowRegularBasket(false)
								}}>
								<FontAwesomeIcon
									className={orderContainerStyle.basketCollapseButtonIcon}
									style={{ color: "var(--basket-main-icon-color)" }}
									icon={faAngleDown}
								/>
							</button>
						</div>
						<hr className={style.hr} />
						{basketHeaderListRegular()}
					</div>
					<section
						className={cls(
							orderContainerStyle.regularBasketContentWrapper,
							{
								[orderContainerStyle.noPrices]: !consumerCatalog.pricesEnabled,
							},
							{
								[orderContainerStyle.oneOrderItemOnly]: orderItems.length === 1,
							},
							{
								[orderContainerStyle.noOrderItems]: orderItems.length === 0,
							},
						)}
						ref={regularBasketContentWrapper}
						style={{ display: "block" }}>
						{basketContentElement()}
					</section>
					<div className={style.regularBasketFinalize} style={{ display: "block" }}>
						{orderItems.length > 1 ? (
							<div style={{ marginTop: "15px", display: "flex", flexDirection: "column" }}>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										fontSize: "20px",
										fontWeight: 600,
									}}>
									{consumerCatalog.pricesEnabled ? (
										<>
											<span>
												Totalt{" "}
												<span style={{ fontSize: "14px" }}>({orderItems.length} st ordrar)</span>
											</span>
											<span>
												{consumerCatalog.renderVAT
													? currencyFormatter(
															sum(
																Object.values(basket.values.orderItemTotalPrices).map(
																	(x) => x.price + x.tax,
																),
															),
													  )
													: currencyFormatter(
															sum(
																Object.values(basket.values.orderItemTotalPrices).map(
																	(x) => x.price,
																),
															),
													  )}
											</span>
										</>
									) : (
										<>
											<span>Antal ordrar</span>
											<span>{orderItems.length} st</span>
										</>
									)}
								</div>
								{discountSum < 0 ? (
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											marginTop: "5px",
											fontSize: "14px",
										}}
										className={style.basketMainTextColor}>
										<span>Rabatt</span>
										<span>{currencyFormatter(discountSum)}</span>
									</div>
								) : null}
								{consumerCatalog.pricesEnabled ? (
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											marginTop: "5px",
											fontSize: "14px",
										}}>
										{consumerCatalog.renderVAT ? (
											<>
												<span>Varav moms</span>
												<span>
													{currencyFormatter(
														sum(
															Object.values(basket.values.orderItemTotalPrices).map(
																(x) => x.tax,
															),
														),
													)}
												</span>
											</>
										) : (
											<>
												<span>Priser är exklusive moms</span>
												<span></span>
											</>
										)}
									</div>
								) : (
									<div></div>
								)}
							</div>
						) : null}
						{basketErrorsList()}
						<FinalizeButton
							disabled={
								orderItems.length === 0 ||
								!permissions.isAllowed(PermissionAreaLocation.Order_Process_Start)
							}
							className={orderContainerStyle.basketFinalizeButton}
							style={{
								opacity: !permissions.isAllowed(PermissionAreaLocation.Order_Process_Start) ? 0 : undefined,
							}}
							onClick={() => {
								goToCheckout()
							}}>
							Lägg din beställning
						</FinalizeButton>
					</div>
				</div>
			</div>
		)
	}

	function toggleOrderItemVisibility(orderItemId: string) {
		setHiddenOrderItems((val) => {
			return { ...val, [orderItemId]: !val[orderItemId] }
		})
	}

	function basketContentElement(): JSX.Element {
		if (orderItems.length <= 0) {
			return (
				<div className={orderContainerStyle.basketItemsWrapper} ref={basketItemsWrapper}>
					<span
						className={orderContainerStyle.basketTopInfoText}
						style={{ display: "flex", justifyContent: "center" }}>
						Din varukorg är tom. Lägg till en produkt!
					</span>
				</div>
			)
		}

		return (
			<div className={orderContainerStyle.basketItemsWrapper} ref={basketItemsWrapper}>
				{orderItems.map((orderItem, index) => {
					const contactInformation = contactInformationOneLineText(orderItem.project)

					return (
						<section
							key={orderItem.id + "_basketItemSection"}
							className={cls(
								style.basketItemSection,
								{
									[style.selectedBasketItem]: index === basket.values.selectedOrderItemIndex,
								},
								{ [orderContainerStyle.orderItemSelectionListItemMobile]: basket.values.mobileBasketShown },
								"basketItemSection",
							)}
							onClick={() => {
								onOrderItemSelected(index)
							}}>
							<div className={orderContainerStyle.basketItemHeaderSection}>
								<div className={style.orderNumberAndButtonsWrapper}>
									<span className={cls(style.sectionTextColor, style.orderNumberText)}>
										Order #{index + 1}
									</span>
									<span className={style.orderItemRemoveAndToggleButtonsWrapper}>
										<button
											className={orderContainerStyle.removeBasketItemButton}
											onClick={(e) => {
												onRemoveOrderItem(index)
												setShowBasketErrorsList(false)
												e.preventDefault()
												e.stopPropagation()
											}}>
											<TrashIcon
												size={22}
												className={style.orderItemRemoveIconWrapper}
												iconClassName={style.orderItemRemoveIcon}
											/>
										</button>
										<button
											className={style.toggleOrderItemButton}
											onClick={() => {
												toggleOrderItemVisibility(orderItem.id)
											}}>
											{hiddenOrderItems[orderItem.id] ? (
												<FontAwesomeIcon
													className={orderContainerStyle.basketCollapseButtonIcon}
													icon={faAngleRight}
												/>
											) : (
												<FontAwesomeIcon
													className={orderContainerStyle.basketCollapseButtonIcon}
													icon={faAngleDown}
												/>
											)}
										</button>
									</span>
								</div>
								{!hiddenOrderItems[orderItem.id] ? (
									<>
										<div className={style.orderItemCityAndStreetWrapper}>
											<SbtH2 className={cls(style.sectionTextColor, style.streetAndCity)}>
												<strong>{orderItem.project.street},</strong>{" "}
												{orderItem.project.zipcode ? orderItem.project.zipcode + " " : ""}
												{orderItem.project.city}
											</SbtH2>
											{permissions.isAllowed(PermissionAreaLocation.Consumer_Addresses_Read) ? (
												<button
													className={cls(style.toggleOrderItemButton, style.editOrderItemButton)}
													onClick={(e) => {
														e.preventDefault()
														e.stopPropagation()
														onOrderItemEditProject(index)
													}}>
													<PenIcon size={24} />
												</button>
											) : null}
										</div>
										<div>
											<SbtH6 className={cls(style.sectionTextColor, style.projectSubTextWithIcon)}>
												<MarkingIcon size={22} />
												<span>{orderItem.project.marking || orderItem.project.street}</span>
											</SbtH6>
											{contactInformation ? (
												<SbtH6
													className={cls(style.sectionTextColor, style.projectSubTextWithIcon)}>
													<KontaktIcon size={22} />
													<span>{contactInformation}</span>
												</SbtH6>
											) : null}
										</div>
									</>
								) : null}
							</div>
							{!hiddenOrderItems[orderItem.id] ? (
								<>
									{basketContentElementDateAndTimeSection(orderItem, index)}
									{(orderItem.products as OrderItemProduct[]).map((orderItemProduct) =>
										orderItemProductElement(orderItem, orderItemProduct),
									)}
									{orderItemZoneElement(
										orderItem,
										client,
										consumerCatalog,
										false,
										style.orderItemTransportZone,
									)}
									{orderItemDateSlotPriceElement(
										orderItem,
										client,
										consumerCatalog,
										style.orderItemTransportZone,
									)}
									{orderItemPriceSummaryTable(orderItem)}
									<hr className={style.hr} />
								</>
							) : null}
						</section>
					)
				})}
			</div>
		)
	}

	function basketContentTimeElement(orderItem: OrderItem, index: number) {
		if (orderItem.products.length === 0) {
			return (
				<div className={cls(orderContainerStyle.timeSelector, orderContainerStyle.timeSelectorDisabled)}>
					<span>Välj tid</span>
					<FontAwesomeIcon className={orderContainerStyle.timeSelectorIcon} icon={faClock} />
				</div>
			)
		} else if (!orderItem.time) {
			return (
				<div
					className={orderContainerStyle.timeSelector}
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						onOrderItemEditTime(index)
					}}>
					<span>Välj tid</span>
					<FontAwesomeIcon className={orderContainerStyle.timeSelectorIcon} icon={faClock} />
				</div>
			)
		} else if (orderItem.time) {
			let category = client.categories[orderItem.category]
			let timeslots =
				category == null
					? []
					: exhaustive.tag(category, "type", {
							WasteCategory: (category) => {
								let serviceId = orderItem.serviceId
								if (!serviceId) {
									logger.warn(
										`Service Id not set when rendering timeSlots for Waste, orderItem: ${anyToLoggableString(
											orderItem,
										)} `,
									)
									return []
								}
								return category?.services[serviceId]?.timeSlots || []
							},
							GoodsCategory: () => {
								let id = orderItem.products.find((x) => x.packagingMethod)?.packagingMethod?.id
								if (!id) {
									logger.warn(
										`Could not resolve packagingMethod for orderItem when rendering timeSlots for Goods, orderItem: ${anyToLoggableString(
											orderItem,
										)}`,
									)
									return []
								}
								return client.possiblePackagingMethods[id]?.timeSlotIds ?? []
							},
							_: () => [], //Just ignore unknown types
					  })

			const timeName = (orderDateItem: OrderItemTime) =>
				orderDateItem ? (orderDateItem.specificTime ? orderDateItem.timeValue : orderDateItem.timeName) : ""

			if (timeslots.length === 1) {
				const timeslot = _.first(timeslots)
				if (timeslot != null && !client.possibleTimeSlots[timeslot]?.specificTime) {
					return (
						<div className={orderContainerStyle.timeSelectorSelected} style={{ cursor: "default" }}>
							<span style={{ cursor: "default" }}>Tid: {timeName(orderItem.time)}</span>
						</div>
					)
				}
			}

			return (
				<div
					className={orderContainerStyle.timeSelectorSelected}
					onClick={(e) => {
						e.preventDefault()
						e.stopPropagation()
						onOrderItemEditTime(index)
					}}>
					<span>Tid: {timeName(orderItem.time)}</span>
					<PenIcon size={22} iconClassName={orderContainerStyle.dateAndTimeSelectorsSelectedIcon} />
				</div>
			)
		}

		return null
	}

	function basketContentDateElement(orderItem: OrderItem, index: number) {
		let content: JSX.Element
		let allowEditDate = true

		if (orderItem.date) {
			let elem
			if (isString(orderItem.date)) {
				elem = (
					<div>
						<span>Datum: {orderItem.date.substring(2)}</span>
					</div>
				)
			} else {
				let category = client.categories[orderItem.category]

				if (!category) {
					throwIllegalState(
						`Tried to render basket content date element for category ${orderItem.category} and orderItemId: ${orderItem.id} but no category could be resolved`,
					)
				}

				let possibleDateSlots: string[] = exhaustive.tag(category, "type", {
					WasteCategory: (category) => {
						const serviceId = orderItem.serviceId

						if (serviceId && category?.services[serviceId]) {
							return category.services[serviceId]?.dateSlots || []
						}
						return []
					},
					GoodsCategory: () => {
						const product = orderItem.products.find((x) => x.packagingMethod)

						if (product && product.packagingMethod) {
							return client.possiblePackagingMethods[product.packagingMethod.id]?.dateSlotIds || []
						}

						return []
					},
				})
				const firstPossibleDateSlotId = possibleDateSlots[0]

				allowEditDate =
					possibleDateSlots.length > 1 ||
					(possibleDateSlots.length === 1 &&
						firstPossibleDateSlotId !== undefined &&
						client.possibleDateSlots[firstPossibleDateSlotId]?.settings?.exactDate === true)
				const dateSlot = client.possibleDateSlots[orderItem.date.dateSlotId]
				if (dateSlot == null) {
					throwIllegalState(`DateSlot missing!, id: ${orderItem.date.dateSlotId}`)
				}
				const dates = getDatesFromDateSlotAsString(dateSlot)
				elem = (
					<div style={{ paddingTop: "5px", paddingBottom: "5px" }}>
						<span>
							Datum: <span style={{ whiteSpace: "nowrap" }}>{dateSlot?.name || ""}</span>
						</span>
						<br />
						<div style={{ fontSize: "12px", display: "flex", gap: "2px" }}>
							{orderItem.date.specificDate ? (
								orderItem.date.specificDate
							) : (
								<>
									<span>{dates.from}</span>
									{dates.to > dates.from ? (
										<>
											<FontAwesomeIcon icon={faArrowsLeftRight} />
											<span>{dates.to}</span>
										</>
									) : null}
								</>
							)}
						</div>
					</div>
				)
			}

			content = (
				<>
					{elem}
					{allowEditDate ? (
						<PenIcon size={22} iconClassName={orderContainerStyle.dateAndTimeSelectorsSelectedIcon} />
					) : null}
				</>
			)
		} else {
			content = (
				<>
					<span>Välj datum</span>
					<DatumIcon className={orderContainerStyle.dateSelectorIcon} size={18} />
				</>
			)
		}

		return (
			<div
				className={orderItem.date ? orderContainerStyle.dateSelectorSelected : orderContainerStyle.dateSelector}
				style={{ cursor: allowEditDate ? "pointer" : "default" }}
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
					if (allowEditDate) {
						onOrderItemEditDate(index)
					}
				}}>
				{content}
			</div>
		)
	}

	function basketContentElementDateAndTimeSection(orderItem: OrderItem, index: number): JSX.Element {
		let timeElement: JSX.Element | null = basketContentTimeElement(orderItem, index)

		let dateElement: JSX.Element = basketContentDateElement(orderItem, index)

		return (
			<div className={orderContainerStyle.dateAndTimeSelectorsWrapper}>
				{dateElement}
				{timeElement}
			</div>
		)
	}

	function orderItemProductElement(orderItem: OrderItem, orderItemProduct: OrderItemProduct): JSX.Element {
		return (
			<div
				key={orderItem.id + orderItemProduct.productId + orderItemProduct.uniqueId + "_orderItemProductWrapper"}
				className={orderContainerStyle.basketItemProduct}>
				<div style={{ marginTop: "12px" }}>
					<MbtH5>
						<span style={{ fontWeight: 600, color: "var(--basket-module-text-color)" }}>
							{unitFormatter(orderItemProduct.name)}
						</span>
					</MbtH5>
					<MbtH5>
						<span
							className={orderContainerStyle.orderItemProductServiceText}
							style={{ color: "var(--basket-module-text-color)" }}>
							{orderItemProduct.serviceId
								? allServices[orderItemProduct.serviceId]?.name ?? ""
								: orderItemProduct.packagingMethod
								? client.possiblePackagingMethods[orderItemProduct.packagingMethod.id]?.name ?? ""
								: ""}
						</span>
					</MbtH5>
					{orderItemProduct.wasteType ? (
						<MbtH5 key={orderItem.id + orderItemProduct.productId + orderItemProduct.wasteType.wasteTypeId}>
							<span
								style={{ color: "var(--basket-module-text-color)" }}
								className={orderContainerStyle.orderItemWasteTypeText}
								title={client.possibleWasteTypes[orderItemProduct.wasteType.wasteTypeId]?.name}>
								{orderItemProduct.wasteType.amount + "x"}
								&nbsp;
								{client.possibleWasteTypes[orderItemProduct.wasteType.wasteTypeId]?.name}
							</span>
						</MbtH5>
					) : null}
					{orderItemProductPriceElement(orderItemProduct, orderItem)}
				</div>
				<div style={{ marginTop: "18px" }}>{orderItemProductImageElement(orderItemProduct)}</div>
				<div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
					<div className={orderContainerStyle.basketItemProductRemove}>
						<span
							className={orderContainerStyle.basketItemProductRemoveButton}
							onClick={(e) => {
								onRemoveProductFromOrderItem(orderItem.id, orderItemProduct.uniqueId)
								e.preventDefault()
								e.stopPropagation()
							}}>
							<TrashIcon
								size={14}
								iconClassName={cls(
									orderContainerStyle.basketModuleIconColorStroke,
									orderContainerStyle.basketModuleIconColorFill,
								)}
							/>
							<u style={{ color: "var(--basket-module-text-color)" }}>Ta bort</u>
						</span>
					</div>
				</div>
				<div style={{ marginBottom: "10px" }}>
					{orderItemProductAmountSelection(orderItem.id, orderItemProduct)}
				</div>
			</div>
		)
	}

	function orderItemProductImageElement(orderItemProduct: OrderItemProduct): JSX.Element {
		let category = client.categories[orderItemProduct.category]
		const mainImage = category?.products[orderItemProduct.productId]?.images?.main

		if (mainImage && "url" in mainImage) {
			return (
				<div style={{ display: "flex" }}>
					<img
						className={orderContainerStyle.basketItemProductImage}
						style={{ margin: "0 auto" }}
						src={mainImage.url}
						alt="Produktbild"
					/>
				</div>
			)
		} else {
			return (
				<ProductImage
					client={client}
					categoryName={orderItemProduct.category}
					image={mainImage ? ProductImageType[mainImage.typeImage] : undefined}
					wrapperClassName={orderContainerStyle.basketItemProductImage}
				/>
			)
		}
	}

	function orderItemProductAmountSelection(orderItemId: string, orderItemProduct: OrderItemProduct) {
		let category = client.categories[orderItemProduct.category]
		const orderItem = orderItems.find((x) => x.id === orderItemId)
		if (!category || !orderItem || !client.possibleTransportations[orderItem.transportId]) {
			return null
		}

		return exhaustive(category, "type", {
			WasteCategory: (category: WasteProductCategoryInstance) => {
				if (
					!orderItemProduct.serviceId ||
					(orderItemProduct.amount === undefined && orderItemProduct.wasteType === undefined)
				) {
					return null
				}
				const service = category.services[orderItemProduct.serviceId]
				if (service == null) {
					throwIllegalState(`Service missing! id: ${orderItemProduct.serviceId}, category: ${category.id}`)
				} else {
					let unit = service.unit

					if (unit === ProductServiceUnit.Piece) {
						return orderItemProductIncrementorElement(orderItem, orderItemProduct)
					} else {
						return orderItemProductTextPillElement(orderItem, orderItemProduct, unit)
					}
				}
			},
			GoodsCategory: () => {
				if (!orderItemProduct.packagingMethod) {
					return null
				}

				const packagingMethod = client.possiblePackagingMethods[orderItemProduct.packagingMethod.id]
				if (packagingMethod == null) {
					throwIllegalState(
						`PackagingMethod is missing! id: ${orderItemProduct.packagingMethod.id}, category: $${orderItemProduct.category}`,
					)
				} else {
					if (packagingMethod.visualization.type === "Incrementor") {
						return orderItemProductIncrementorElement(orderItem, orderItemProduct)
					} else if (packagingMethod.visualization.type === "Dropdown") {
						return orderItemProductDropdownElement(orderItem, orderItemProduct)
					} else {
						return orderItemProductTextPillElement(orderItem, orderItemProduct, packagingMethod.productUnit)
					}
				}
			},
		})
	}

	function orderItemProductIncrementorElement(
		orderItem: OrderItem,
		orderItemProduct: OrderItemProduct,
	): JSX.Element | null {
		const transport = client.possibleTransportations[orderItem.transportId]
		if (!transport) {
			throwIllegalState(`Transport missing! id: ${orderItem.transportId}`)
		}
		const orderItemProductSum = getAmountOfProductsInOrderItem(orderItem, client)
		if (orderItemProduct.amount !== undefined) {
			return (
				<Incrementor
					key={orderItem.id + orderItemProduct.productId + "-basket-incrementor-1"}
					name={orderItem.id + orderItemProduct.productId + orderItemProduct.uniqueId}
					disableIncrementTooltip="Max antal produkter för transporten uppnådd"
					value={orderItemProduct.amount}
					disableIncrement={transport.constraints.max - orderItemProductSum <= 0}
					max={orderItemProduct.amount + (transport.constraints.max - orderItemProductSum)}
					min={1}
					onChange={(name, value) => {
						onOrderItemProductIncrementorChange(orderItem.id, orderItemProduct.uniqueId, value, "amount")
					}}
				/>
			)
		} else if (orderItemProduct.packagingMethod) {
			return (
				<Incrementor
					key={orderItem.id + orderItemProduct.productId + "-basket-incrementor-2"}
					name={orderItem.id + orderItemProduct.productId + orderItemProduct.uniqueId}
					disableIncrementTooltip="Max antal produkter för transporten uppnådd"
					value={orderItemProduct.packagingMethod.amount}
					disableIncrement={transport.constraints.max - orderItemProductSum <= 0}
					max={orderItemProduct.packagingMethod.amount + (transport.constraints.max - orderItemProductSum)}
					min={1}
					onChange={(name, value) => {
						onOrderItemProductIncrementorChange(orderItem.id, orderItemProduct.uniqueId, value, "goods")
					}}
				/>
			)
		} else {
			return (
				<Incrementor
					key={orderItem.id + orderItemProduct.productId + "-basket-incrementor-3"}
					name={orderItem.id + orderItemProduct.productId}
					disableIncrementTooltip="Max antal produkter för transporten uppnådd"
					value={orderItemProduct.wasteType!.amount}
					disableIncrement={transport.constraints.max - orderItemProductSum <= 0}
					max={orderItemProduct.wasteType!.amount + (transport.constraints.max - orderItemProductSum)}
					min={1}
					onChange={(name, value) => {
						onOrderItemProductIncrementorChange(orderItem.id, orderItemProduct.uniqueId, value, "waste")
					}}
				/>
			)
		}
	}

	function orderItemProductDropdownElement(orderItem: OrderItem, orderItemProduct: OrderItemProduct): JSX.Element | null {
		if (orderItemProduct.packagingMethod) {
			const packagingMethod = client.possiblePackagingMethods[orderItemProduct.packagingMethod.id]
			if (!packagingMethod) {
				throwIllegalState(
					`PackagingMethod missing! id: ${orderItemProduct.packagingMethod.id}, category: ${orderItemProduct.category}`,
				)
			}
			const transport = client.possibleTransportations[orderItem.transportId]
			if (!transport) {
				throwIllegalState(`Transportation missing! id: ${orderItem.transportId}, orderItemId: ${orderItem.id}`)
			}
			const orderItemProductSum = getAmountOfProductsInOrderItem(orderItem, client)
			const max =
				getAmountOfProductsInOrderItemProduct(orderItemProduct, client) +
				(transport.constraints.max - orderItemProductSum)
			return (
				<ExpandableDropdown
					key={v4()}
					getItems={(indexes, max): DropdownItem[] => {
						return indexes
							.map((idx) => {
								const value = idx + 1

								return {
									key: `${(
										packagingMethod.multiplier *
										(idx + 1)
									).toString()} ${productServiceUnitToShortHumanText(packagingMethod.productUnit)}`,
									value: idx + 1,
									disabled: max !== undefined && value > max,
								}
							})
							.filter((x) => x !== null) as DropdownItem[]
					}}
					unSelectionItemText={`0 ${productServiceUnitToShortHumanText(packagingMethod.productUnit)}`}
					defaultValue={orderItemProduct.packagingMethod.amount}
					onChange={(item) => {
						if (!item || item.value === 0) {
							onOrderItemProductIncrementorChange(orderItem.id, orderItemProduct.uniqueId, 0, "goods")
						} else {
							onOrderItemProductIncrementorChange(
								orderItem.id,
								orderItemProduct.uniqueId,
								isNumber(item.value) ? item.value : parseInt(item.value) || 0,
								"goods",
							)
						}
					}}
					max={max}
				/>
			)
		}

		return null
	}

	function orderItemProductTextPillElement(
		orderItem: OrderItem,
		orderItemProduct: OrderItemProduct,
		unit: ProductServiceUnit,
	): JSX.Element | null {
		const transport = client.possibleTransportations[orderItem.transportId]
		if (!transport) {
			throwIllegalState(`Transport missing! id: ${orderItem.transportId}, orderItemId: ${orderItem.id}`)
		}
		const orderItemProductSum = getAmountOfProductsInOrderItem(orderItem, client)
		let defaultValue: number

		if (orderItemProduct.wasteType) {
			defaultValue = orderItemProduct.wasteType.amount
		} else if (orderItemProduct.packagingMethod) {
			defaultValue = orderItemProduct.packagingMethod.amount
		} else {
			defaultValue = orderItemProduct.amount || 0
		}
		const multiplier =
			lets(orderItemProduct?.packagingMethod?.id, (id) => client.possiblePackagingMethods[id]?.multiplier) ?? 1
		const max =
			getAmountOfProductsInOrderItemProduct(orderItemProduct, client) +
			(transport.constraints.max - orderItemProductSum)
		return (
			<div style={{ display: "flex", justifyContent: "end" }}>
				<IncrementorPill
					defaultValue={defaultValue}
					max={max}
					onChange={(newValue) => {
						if (newValue === null) {
							return
						}

						let type: "amount" | "waste" | "goods"

						type = orderItemProduct.wasteType ? "waste" : orderItemProduct.packagingMethod ? "goods" : "amount"
						onOrderItemProductIncrementorChange(
							orderItem.id,
							orderItemProduct.uniqueId,
							toNumber(newValue),
							type,
						)
					}}
					text={productServiceUnitToHumanText(unit)}
					multiplier={multiplier}
				/>
			</div>
		)
	}

	return (
		<>
			{mobileBasketElement()}
			{regularBasketElement()}

			{orderItems.length > 0 && !hideShowBasketButton ? (
				<div className={orderContainerStyle.mobileSeeCart}>
					<div className={orderContainerStyle.mobileSeeCartButtonWrapper}>
						<FinalizeButton
							disabled={orderItems.length < 1}
							className={orderContainerStyle.showMobileBasketFinalizeButton}
							onClick={() => {
								basket.functions.setMobileBasketShown(true)
							}}>
							{orderItems.length < 1 ? "Din varukorg är tom" : "Se din varukorg"}
						</FinalizeButton>
					</div>
				</div>
			) : null}
		</>
	)
}
