import ObjectID from "bson-objectid"
import { exhaustive } from "exhaustive"
import { cloneDeep, concat, findIndex, forEach, isEqual, isNumber, isString, sum, sumBy, uniq } from "lodash"
import log from "loglevel"
import { DateTime } from "luxon"
import { Dispatch, SetStateAction } from "react"
import { when } from "Shared/when"
import { v4 } from "uuid"
import { ArticleAndTree, Articles } from "../../Client/articleTrees/Articles"
import {
	ArticleTree,
	BranchTargetKeyEnum,
	DiscountDefinition,
	DiscountDefinitionId,
	DiscountRate,
	DiscountRateOnEach,
	DiscountRateOnTotal,
	DiscountRateSubTypeMapping,
	DiscountTarget,
	TreeSearchObject,
} from "../../Client/articleTrees/ArticleTreeDataModel"
import {
	ArticleResolverService,
	LeafOrigin,
	ResolvedArticle,
	ResolvedArticlePath,
	ResolvedArticlePathStep,
	ResolvedLeafWithTree,
	ResolvedTypedLeaf,
} from "../../Client/articleTrees/ArticleTreeResolver"
import { ClientInstance, ProductCategoryInstance } from "../../Client/ClientInstance"
import { ConsumerCatalogInstance } from "../../Client/ConsumerCatalogContext"
import { PackagingMethod, ProductService, ProductServiceUnit } from "../../Client/ProductDefinitionsByCategories"
import {
	Article,
	ArticlePath,
	ArticleType,
	DiscountDescription,
	DiscountDescriptionId,
	DiscountOrigin,
} from "../../CustomerPortal/CustomerPortalOrders/CustomerPortalOrders"
import { GetProject } from "../../CustomerPortal/CustomerPortalProjectsManager/CustomerPortalProjectsManager"
import { getLogger } from "../../Logging/getLogger"
import { lets } from "../../Shared/lets"
import { notNull } from "../../Shared/notNull"
import { IllegalState, throwIllegalState } from "../../Shared/throwIllegalState"
import { nextWorkday } from "../../Shared/tomorrow"
import { OrderItem, OrderItemProduct, OrderItemsSchema, Project } from "../order-data-model"
import { ProductSelectionReturnValue } from "../ProductInformationAndSelectionModule/ProductInformationAndSelectionModule"
import { unitFormatter } from "../unit-formatter"
import { OrderItemTotalPrices } from "./OrderContainer"

const logger = getLogger("Logic")

export function getOrderItemIndex(
	client: ClientInstance,
	orderItems: OrderItem[],
	project: Project,
	transportId: string,
	productId: string,
	packagingMethod?: PackagingMethod,
): number {
	// Get order item with matching project and transport, and date and/or time if they are set
	return findIndex(orderItems, (item) => {
		const amountOk =
			getAmountOfProductsInOrderItem(item, client) <
			(client.possibleTransportations[item.transportId]?.constraints?.max || 1)

		let packagingTimeslotsOk

		if (packagingMethod) {
			let timeslots
			let firstItemId = item.products.find((x) => x.packagingMethod)?.packagingMethod?.id
			if (firstItemId) {
				timeslots = client.possiblePackagingMethods[firstItemId].timeSlotIds
			} else {
				timeslots = packagingMethod.timeSlotIds
			}
			packagingTimeslotsOk = isEqual(timeslots, packagingMethod.timeSlotIds)
		} else {
			packagingTimeslotsOk = true
		}

		const projectOk = isEqual(item.project, project)
		const transportOk = item.transportId === transportId

		const transport = client.possibleTransportations[transportId]
		const orderItemProductIds = uniq(item.products.map((x) => x.productId))
		const newProductExistsInOrderItem = orderItemProductIds.includes(productId)
		let discreteAmountOk = true
		if (transport) {
			const max = transport.constraints.maxDiscreteProducts

			if (isNumber(max) && max > 0) {
				if (orderItemProductIds.length >= max && !newProductExistsInOrderItem) {
					discreteAmountOk = false
				}
			}
		}

		return projectOk && transportOk && amountOk && packagingTimeslotsOk && discreteAmountOk
	})
}

export function addNewOrderItemForProduct(
	client: ClientInstance,
	consumerCatalog: ConsumerCatalogInstance,
	orderItems: OrderItem[],
	selectedOrderItem: number | null,
	projectToUse: Project | GetProject,
	productSelectionReturnValue: ProductSelectionReturnValue,
	product: OrderItemProduct,
	discountTemplateId: string | null,
): OrderItem[] {
	const project = selectedOrderItem !== null ? orderItems[selectedOrderItem].project : projectToUse
	let zoneId: string | undefined
	const coordinates = "id" in project ? project.address.coordinates : project.coordinates
	if (coordinates) {
		zoneId = getZoneIdFromPoint(client, coordinates)
	}
	let category = client.categories[productSelectionReturnValue.category]

	let transportId: string = ""
	let service: ProductService | null = null
	let timeslotIds: string[] = []
	let dateSlotIds: string[] = []
	if (category.type === "WasteCategory" && productSelectionReturnValue.serviceId) {
		service = category?.services[productSelectionReturnValue.serviceId]
		transportId = category?.products[product.productId]?.transportation[productSelectionReturnValue.serviceId]
		timeslotIds = service?.timeSlots || []
		dateSlotIds = service?.dateSlots || []
	} else if (category.type === "GoodsCategory" && product.packagingMethod) {
		transportId = client.possiblePackagingMethods[product.packagingMethod.id].transportationId
		timeslotIds = client.possiblePackagingMethods[product.packagingMethod.id].timeSlotIds
		dateSlotIds = client.possiblePackagingMethods[product.packagingMethod.id].dateSlotIds ?? []
	}

	let orderItemProject: Project

	if ("id" in project) {
		orderItemProject = {
			street: project.address.street,
			city: project.address.city,
			zipcode: project.address.zipCode,
			coordinates: project.address.coordinates,
			...project,
		}
	} else {
		orderItemProject = project
	}

	let time: OrderItem["time"]
	// check if there is only one timeslot, and if so, set it by default
	if (timeslotIds.length === 1) {
		const timeSlot = client.possibleTimeSlots[timeslotIds[0]]

		if (timeSlot.specificTime) {
			time = {
				timeslotId: timeslotIds[0],
				timeValue: "12:00",
				specificTime: true,
			}
		} else {
			time = {
				timeslotId: timeslotIds[0],
				timeName: timeSlot.name,
				specificTime: false,
			}
		}
	}

	let date: OrderItem["date"]
	// check if there is only one timeslot, and if so, set it by default
	if (dateSlotIds.length === 1) {
		const dateSlot = client.possibleDateSlots[dateSlotIds[0]]

		// Only set default dateslot value when it's not exact date
		if (!dateSlot.settings.exactDate) {
			date = {
				dateSlotId: dateSlotIds[0],
				specificDate: dateSlot.settings.exactDate ? nextWorkday() : undefined,
			}
		}
	}

	let orderItem: OrderItem = {
		id: v4(),
		project: orderItemProject,
		date: date,
		time: time,
		products: [product],
		category: productSelectionReturnValue.category,
		serviceId: productSelectionReturnValue.serviceId,
		transportId: transportId,
		zoneId: zoneId,
		articles: Articles.empty(),
	}

	//FIXME, Damn, this caused a catch 22 :facepalm:
	orderItem.articles = getOrderItemPriceArticles(client, consumerCatalog, orderItem, discountTemplateId)

	orderItems.push(orderItem)

	return orderItems
}

// As the name states, adds a product to an existing order item
// Since we add the products one at a time, and this is called only when an item index is found
// And an index is only returned if there is >= 1 slots, there is no need to check if the order item has room, since that's done beforehand
export function addProductToExistingOrderItem(
	orderItems: OrderItem[],
	orderItemIndex: number,
	productToAdd: OrderItemProduct,
): OrderItem[] {
	// Check if product already exists in the order item
	const indexOfProduct: number = orderItems[orderItemIndex].products.findIndex(
		(x) =>
			x.productId === productToAdd.productId &&
			(productToAdd.packagingMethod ? productToAdd.packagingMethod.id === x.packagingMethod?.id : true) &&
			(productToAdd.serviceId ? productToAdd.serviceId === x.serviceId : true) &&
			(productToAdd.wasteType ? x.wasteType?.wasteTypeId === productToAdd.wasteType.wasteTypeId : true),
	)
	if (indexOfProduct > -1) {
		const product = cloneDeep(orderItems[orderItemIndex].products[indexOfProduct])
		if (product.wasteType) {
			product.wasteType.amount += 1
		} else if (productToAdd.packagingMethod && product.packagingMethod) {
			product.packagingMethod.amount += productToAdd.packagingMethod.amount
		} else {
			product.amount! += 1
		}
		orderItems[orderItemIndex].products[indexOfProduct] = product
	} else {
		orderItems[orderItemIndex].products.push(productToAdd)
	}
	orderItems[orderItemIndex].id = v4()
	return orderItems
}

export function addProductToOrderItem(
	client: ClientInstance,
	consumerCatalog: ConsumerCatalogInstance,
	orderItems: OrderItem[],
	productSelectionReturnValue: ProductSelectionReturnValue,
	repeat: number = 1,
	projectToUse: Project,
	selectedOrderItem: number | null,
	selectedServiceId: string | null,
	setSelectedOrderItem: Dispatch<SetStateAction<number | null>>,
	discountTemplateId: string | null,
): OrderItem[] {
	// Check if there are no products, if there are none we set the category, service, and transport
	if (selectedOrderItem !== null && orderItems[selectedOrderItem].products.length === 0) {
		orderItems[selectedOrderItem].id = v4()
		orderItems[selectedOrderItem].serviceId = productSelectionReturnValue.serviceId
		orderItems[selectedOrderItem].category = productSelectionReturnValue.category
		let selectedOrderItemCategory = client.categories[orderItems[selectedOrderItem].category]

		if (selectedOrderItemCategory?.type === "WasteCategory" && orderItems[selectedOrderItem].serviceId) {
			orderItems[selectedOrderItem].transportId =
				selectedOrderItemCategory!.products[productSelectionReturnValue.productId]!.transportation[
					orderItems[selectedOrderItem].serviceId!
				]
		} else if (selectedOrderItemCategory?.type === "GoodsCategory" && productSelectionReturnValue.packagingMethods) {
			const firstKey = Object.keys(productSelectionReturnValue.packagingMethods)[0]
			orderItems[selectedOrderItem].transportId = client.possiblePackagingMethods[firstKey].transportationId
		}

		// If the service only has one timeslot we set it by default
		if (
			selectedServiceId !== null &&
			selectedOrderItemCategory.type === "WasteCategory" &&
			selectedOrderItemCategory?.services[selectedServiceId]?.timeSlots.length === 1
		) {
			const timeslotId: string = selectedOrderItemCategory.services[selectedServiceId].timeSlots[0]
			const timeSlot = client.possibleTimeSlots[timeslotId]

			if (timeSlot.specificTime) {
				orderItems[selectedOrderItem].time = {
					timeslotId: timeslotId,
					timeValue: "12:00",
					specificTime: true,
				}
			} else {
				orderItems[selectedOrderItem].time = {
					timeslotId: timeslotId,
					timeName: timeSlot.name,
					specificTime: false,
				}
			}
		}

		// If the service only has one dateSlot we set it by default
		if (
			selectedServiceId !== null &&
			selectedOrderItemCategory.type === "WasteCategory" &&
			selectedOrderItemCategory?.services[selectedServiceId]?.dateSlots.length === 1
		) {
			const dateSlotId: string = selectedOrderItemCategory.services[selectedServiceId].dateSlots[0]
			orderItems[selectedOrderItem].date = {
				dateSlotId: dateSlotId,
			}
		}
	}

	let category = client.categories[productSelectionReturnValue.category]
	let productsToAdd: OrderItemProduct[] = []

	exhaustive(category, "type", {
		WasteCategory: () => {
			// Split the products and add them one by one to productsToAdd array
			// This enables us to make the logic a bit simpler since we add them one by one instead of in chunks of > 1
			if (productSelectionReturnValue.selectedWasteTypeAmounts) {
				for (let i = 0; i < repeat; i++) {
					Object.entries(productSelectionReturnValue.selectedWasteTypeAmounts).forEach((wasteIdAndAmount) => {
						for (let i = 0; i < wasteIdAndAmount[1]; i++) {
							productsToAdd.push({
								name: productSelectionReturnValue.name,
								productId: productSelectionReturnValue.productId,
								amount: undefined,
								wasteType: {
									wasteTypeId: wasteIdAndAmount[0],
									amount: 1,
								},
								uniqueId: v4(),
								serviceId: productSelectionReturnValue.serviceId,
								category: productSelectionReturnValue.category,
								articles: Articles.empty(),
								packagingMethod: undefined,
							})
						}
					})
				}
			} else {
				for (let i = 0; i < repeat; i++) {
					productsToAdd.push({
						name: productSelectionReturnValue.name,
						productId: productSelectionReturnValue.productId,
						amount: 1,
						wasteType: undefined,
						uniqueId: v4(),
						serviceId: productSelectionReturnValue.serviceId,
						category: productSelectionReturnValue.category,
						articles: Articles.empty(),
						packagingMethod: undefined,
					})
				}
			}
		},
		GoodsCategory: () => {
			if (
				productSelectionReturnValue.packagingMethods &&
				Object.keys(productSelectionReturnValue.packagingMethods).length > 0
			) {
				for (let i = 0; i < repeat; i++) {
					forEach(productSelectionReturnValue.packagingMethods, (amount, packagingMethodId) => {
						for (let i = 0; i < amount; i++) {
							productsToAdd.push({
								name: productSelectionReturnValue.name,
								productId: productSelectionReturnValue.productId,
								amount: undefined,
								wasteType: undefined,
								uniqueId: v4(),
								serviceId: productSelectionReturnValue.serviceId,
								category: productSelectionReturnValue.category,
								articles: Articles.empty(),
								packagingMethod: { id: packagingMethodId, amount: 1 },
							})
						}
					})
				}
			}
		},
	})

	forEach(productsToAdd, (newProductToAdd) => {
		let transportId: string = ""

		exhaustive(category, "type", {
			WasteCategory: (category) => {
				if (newProductToAdd.serviceId) {
					transportId = category.products[newProductToAdd.productId]?.transportation[newProductToAdd.serviceId]
				}
			},
			GoodsCategory: () => {
				const method = newProductToAdd.packagingMethod
					? client.possiblePackagingMethods[newProductToAdd.packagingMethod.id]
					: null
				if (method) {
					transportId = method.transportationId
				}
			},
		})

		if (!transportId) {
			throw new Error(`Transport id not found for product ${newProductToAdd.productId}`)
		}

		// try to get index of an item to add to, if not, add to new order item
		const orderItemIndex: number =
			selectedOrderItem === null
				? getOrderItemIndex(
						client,
						orderItems,
						projectToUse!,
						transportId,
						newProductToAdd.productId,
						newProductToAdd.packagingMethod
							? client.possiblePackagingMethods[newProductToAdd.packagingMethod.id]
							: undefined,
				  )
				: getOrderItemIndex(
						client,
						orderItems,
						orderItems[selectedOrderItem].project,
						transportId,
						newProductToAdd.productId,
						newProductToAdd.packagingMethod
							? client.possiblePackagingMethods[newProductToAdd.packagingMethod.id]
							: undefined,
				  )
		if (orderItemIndex > -1) {
			orderItems = addProductToExistingOrderItem(orderItems, orderItemIndex, newProductToAdd)
			setSelectedOrderItem((selectedOrderItem) => {
				if (selectedOrderItem === null) {
					return orderItemIndex
				}

				return selectedOrderItem
			})
		} else {
			orderItems = addNewOrderItemForProduct(
				client,
				consumerCatalog,
				orderItems,
				selectedOrderItem,
				projectToUse,
				productSelectionReturnValue,
				newProductToAdd,
				discountTemplateId,
			)
		}
	})

	return orderItems
}

export function calculateTotalPrices(orderItems: readonly OrderItem[], client: ClientInstance): OrderItemTotalPrices {
	const newOrderItemTotalPrices: OrderItemTotalPrices = {}

	orderItems.forEach((orderItem) => {
		;(orderItem.products as OrderItemProduct[]).forEach((orderItemProduct) => {
			let article = orderItemProduct?.articles?.getProductArticle()

			if (!article) {
				return
			}

			let amount

			if (orderItemProduct.packagingMethod) {
				amount =
					orderItemProduct.packagingMethod.amount *
					client.possiblePackagingMethods[orderItemProduct.packagingMethod.id].multiplier
			} else {
				amount = orderItemProduct?.wasteType?.amount || orderItemProduct.amount || 1
			}
			const tax = article.price * article.taxPercentage
			if (!newOrderItemTotalPrices[orderItem.id]) {
				newOrderItemTotalPrices[orderItem.id] = {
					price: article.price * amount,
					tax: tax * amount,
					amountOfArticles: amount,
					discount: 0,
					taxDiscount: 0,
				}
			} else {
				newOrderItemTotalPrices[orderItem.id].price += article.price * amount
				newOrderItemTotalPrices[orderItem.id].tax += tax * amount
				newOrderItemTotalPrices[orderItem.id].amountOfArticles += amount
			}
		})

		const transportPriceArticle = orderItem?.articles?.getTransportationArticle()

		if (transportPriceArticle && newOrderItemTotalPrices[orderItem.id]) {
			newOrderItemTotalPrices[orderItem.id].price += transportPriceArticle.price
			newOrderItemTotalPrices[orderItem.id].tax += transportPriceArticle.price * transportPriceArticle.taxPercentage
		}

		const dateSlotPriceArticle = orderItem?.articles?.getDateSlotArticle()

		if (dateSlotPriceArticle && newOrderItemTotalPrices[orderItem.id]) {
			newOrderItemTotalPrices[orderItem.id].price += dateSlotPriceArticle.price
			newOrderItemTotalPrices[orderItem.id].tax += dateSlotPriceArticle.price * dateSlotPriceArticle.taxPercentage
		}

		orderItem.articles?.getDiscountArticles().forEach((discountArticleAndTree) => {
			if (newOrderItemTotalPrices[orderItem.id] == null) {
				return
			}

			let discount = discountArticleAndTree.article.price
			let taxDiscount = discount * discountArticleAndTree.article.taxPercentage

			newOrderItemTotalPrices[orderItem.id].price += discount
			newOrderItemTotalPrices[orderItem.id].tax += taxDiscount
			newOrderItemTotalPrices[orderItem.id].discount += discount
			newOrderItemTotalPrices[orderItem.id].taxDiscount += taxDiscount
		})
	})

	return newOrderItemTotalPrices
}

export function getZoneIdFromPoint(client: ClientInstance, point: google.maps.LatLngLiteral): string | undefined {
	let ret: string | undefined = undefined
	for (const zone of client.transportZonesSorted) {
		const contains = google.maps.geometry.poly.containsLocation(
			point,
			new google.maps.Polygon({
				paths: zone.points,
			}),
		)
		if (contains) {
			ret = zone.id
			break
		}
	}

	return ret
}

export function readOrderItemsFromLocalStorage(
	client: ClientInstance,
	consumerCatalog: ConsumerCatalogInstance,
	discountTemplateId: string | null,
): OrderItem[] {
	let parsed

	try {
		parsed = OrderItemsSchema.safeParse(JSON.parse(localStorage.getItem(`${client.identifier}.order-items`) ?? "[]"))
	} catch (e) {
		parsed = OrderItemsSchema.safeParse([])
	}
	if (parsed.success) {
		const data: OrderItem[] = []
		forEach(cloneDeep(parsed.data) as OrderItem[], (x) => {
			if (x.date) {
				let date

				if (typeof x.date === "string") {
					date = DateTime.fromFormat(x.date, "yyyy-MM-dd", {
						zone: "Europe/Stockholm",
					})

					const now = DateTime.now().setZone("Europe/Stockholm")

					// remove date if it has passed
					if (!date.isValid || date.startOf("second") < now.startOf("second")) {
						x.date = undefined
					}
				} else {
					const dateSlot = client.possibleDateSlots[x.date.dateSlotId]
					if (!dateSlot) {
						// unset date if the dateSlotId doesn't exist
						x.date = undefined
					}
					if (dateSlot.settings.exactDate) {
						// verify that the given date is a date, and valid for the relevant settings of the dateslot
						const specificDateInvalid = lets(x.date?.specificDate, (specificDate) => {
							const parsed = DateTime.fromFormat(specificDate, "yyyy-MM-dd", {
								zone: "Europe/Stockholm",
							})

							if (!parsed.isValid || (dateSlot.settings.skipSaturdayAndSunday && parsed.weekday > 5)) {
								return true
							}
							return false
						})

						if (specificDateInvalid) {
							x.date = undefined
						}
					}
				}
			}

			// unset time if the timeslotId doesn't exist
			if (x.time && !client.possibleTimeSlots[x.time.timeslotId]) {
				x.time = undefined
			}

			if (!client.categories[x.category]) {
				return
			}

			// go through and remove products with invalid category, serviceId or wasteTypeId
			// also remove order item products that refer to a product that doesn't exist and products with amount zero or less
			;(x.products as OrderItemProduct[]).forEach((product, index, items) => {
				let category: ProductCategoryInstance | null = client.categories[product.category] || null

				if (!category || product.category === "" || !category?.products[product.productId]) {
					items.splice(index, 1)
					return
				}

				let removed = false
				exhaustive(category, "type", {
					WasteCategory: (category) => {
						if (
							!product?.serviceId ||
							!category?.services[product.serviceId] ||
							(product?.wasteType?.wasteTypeId &&
								!client.possibleWasteTypes[product.wasteType.wasteTypeId]) ||
							(product.amount !== undefined && product.amount === 0) ||
							(product.wasteType && product.wasteType.amount === 0)
						) {
							items.splice(index, 1)
							removed = true
						}
					},
					GoodsCategory: () => {
						if (!product.packagingMethod || !client.possiblePackagingMethods[product.packagingMethod.id]) {
							items.splice(index, 1)
							removed = true
						}
					},
				})

				if (!removed) {
					product.articles = getOrderItemProductArticles(
						client,
						consumerCatalog,
						category.id,
						product,
						product.serviceId,
						product.productId,
						product?.wasteType?.wasteTypeId,
						product?.packagingMethod?.id,
					)
				}
			})

			if (x.transportId !== "" && !client.possibleTransportations[x.transportId]) {
				x.transportId = ""
				x.serviceId = ""
				// transport id is set when first product is added to order item, if the transport is invalid then all products are invalid as well
				x.products = []
			}

			if (x.products.length !== 0) {
				x.articles = getOrderItemPriceArticles(client, consumerCatalog, x, discountTemplateId)
				data.push(x)
			}
		})
		return data
	} else {
		log.warn(`Order items saved in local storage did not match defined type interface. Defaulting to []`)
		return []
	}
}

export function getOrderItemPriceArticles(
	client: ClientInstance,
	consumerCatalog: ConsumerCatalogInstance,
	orderItem: OrderItem,
	discountTemplateId: string | null,
): Articles {
	if (!consumerCatalog.pricesEnabled) {
		return Articles.empty()
	}

	const dateSlotId = !isString(orderItem.date) ? orderItem.date?.dateSlotId : undefined

	const ret: ArticleAndTree[] = []

	if (consumerCatalog.transportPricing) {
		let treeSearch: TreeSearchObject = new Map([])

		if (orderItem.transportId) {
			treeSearch.set(BranchTargetKeyEnum.Transportation, orderItem.transportId)
		}
		if (orderItem.zoneId) {
			treeSearch.set(BranchTargetKeyEnum.Zone, orderItem.zoneId)
		}
		if (discountTemplateId) {
			treeSearch.set(BranchTargetKeyEnum.DiscountTemplate, discountTemplateId)
		}

		const leaf = ArticleResolverService.resolveArticleFromTree(treeSearch, consumerCatalog.transportPricing)

		if (leaf) {
			let article = createArticleFromLeaf(
				{ resolvedLeaf: leaf, tree: consumerCatalog.transportPricing, originReason: LeafOrigin.Regular },
				null,
				client,
			)
			ret.push({ article, tree: consumerCatalog.transportPricing })
		}
	}

	if (consumerCatalog.datePricing && dateSlotId) {
		const leaf = ArticleResolverService.resolveArticleFromTree(
			new Map([[BranchTargetKeyEnum.DateSlot, dateSlotId]]),
			consumerCatalog.datePricing,
		)

		if (leaf) {
			let article = createArticleFromLeaf(
				{ resolvedLeaf: leaf, tree: consumerCatalog.datePricing, originReason: LeafOrigin.Regular },
				null,
				client,
			)
			ret.push({ article, tree: consumerCatalog.datePricing })
		}
	}

	const discountFromTrees = findDiscountsFromTrees(client, consumerCatalog, orderItem, discountTemplateId)

	let productDiscounts = resolveConsolidatedProductDiscounts(client, discountFromTrees)
	let orderDiscounts = resolveConsolidatedOrderDiscounts(client, ret, productDiscounts, discountFromTrees)

	const discounts = concat(productDiscounts, orderDiscounts)

	return new Articles([...ret, ...discounts])
}

function calculateDiscountForEachOfProducts(
	products: OrderItemProduct[],
	discountRate: DiscountRateOnEach,
	client: ClientInstance,
) {
	let discountCalcs = products.flatMap((orderItemProduct) => {
		return (orderItemProduct.articles?.listAll || [])
			.map((orderProductArticleLeaf) => {
				return calculateDiscountOnEach(discountRate, orderProductArticleLeaf.article, orderItemProduct, client)
			})
			.filter(notNull)
	})

	return discountCalcs.reduce((acc, discountCalc) => {
		return acc.plus(discountCalc)
	}, DiscountCalculation.ZERO)
}

function calculateDiscountOnEach(
	discountRate: DiscountRateOnEach,
	article: Article,
	orderItemProduct: OrderItemProduct,
	client: ClientInstance,
) {
	const amount = getAmountOfProductsInOrderItemProduct(orderItemProduct, client)

	const discountAmount = exhaustive(discountRate, "type", {
		Percentage: (discount) => {
			return -article.price * amount * discount.percent
		},
		PerItemAmount: (discount) => {
			return discount.price * amount
		},
	})

	return new DiscountCalculation(discountAmount, discountAmount * article.taxPercentage)
}

function calculateDiscountForTotalOfProducts(
	products: OrderItemProduct[],
	discountRate: DiscountRateOnTotal,
	client: ClientInstance,
) {
	const discountAmount = exhaustive(discountRate, "type", {
		FixedAmount: (discountRate) => discountRate.price,
	})

	const averageTaxPercentage = getAverageTaxPercentage(products, client)

	return new DiscountCalculation(discountAmount, discountAmount * averageTaxPercentage)
}

function calculateOrderDiscount(
	products: OrderItemProduct[],
	discountRate: DiscountRate,
	currentPrice: number,
	count: number,
	client: ClientInstance,
): DiscountCalculation {
	const averageTaxPercentage = getAverageTaxPercentage(products, client)

	return exhaustive(discountRate, "subType", {
		OnTotal: (it) => calculateDiscountForTotalOfProducts(products, it, client),
		OnEach: (onEachDiscount) => {
			return exhaustive(onEachDiscount, "type", {
				Percentage: (it) => {
					const discountAmount = currentPrice * -it.percent // Percent should be a positive number. To behave as a discount it is flipped to be negative for calculations.
					return consolidateDiscountCalculationFromDiscountAmount(discountAmount, averageTaxPercentage)
				},
				PerItemAmount: (it) => {
					const discountAmount = count * it.price // Price should be a negative number
					return consolidateDiscountCalculationFromDiscountAmount(discountAmount, averageTaxPercentage)
				},
			})
		},
	})
}

function consolidateDiscountCalculationFromDiscountAmount(discountAmount: number, averageTaxPercentage: number) {
	const discountNoTax = discountAmount / (1 + averageTaxPercentage)
	const discountTaxAmount = discountNoTax * averageTaxPercentage
	return new DiscountCalculation(discountNoTax, discountTaxAmount)
}

/**
 * Calculate an Averaged Tax Percent that can be used to proportionally apply discount with regard to individual tax percentages.
 */
function getAverageTaxPercentage(products: OrderItemProduct[], client: ClientInstance): number {
	let totalPrice = 0
	let totalTaxPrice = 0

	products.forEach((orderProduct) => {
		const it = orderProduct.articles?.getProductArticle()
		if (it == null) {
			logger.error("OrderItemProduct has no ProductArticle! item: ", cloneDeep(orderProduct))
			return
		}
		const amount = getAmountOfProductsInOrderItemProduct(orderProduct, client)

		const priceForAllAmount = it.price * amount
		totalPrice += priceForAllAmount
		totalTaxPrice += priceForAllAmount * it.taxPercentage
	})

	const averageTaxPercentage = totalTaxPrice / totalPrice
	return averageTaxPercentage
}

class DiscountCalculation {
	constructor(
		/**
		 * Amount/sum of money that is discounted.
		 */
		readonly discountAmount: number,
		/**
		 * Amount/sum of tax, in money, that is discounted.
		 * Not the percent since that my vary between articles.
		 */
		readonly discountTaxAmount: number,
	) {}

	plus(other: DiscountCalculation): DiscountCalculation {
		return new DiscountCalculation(
			this.discountAmount + other.discountAmount,
			this.discountTaxAmount + other.discountTaxAmount,
		)
	}

	static readonly ZERO = new DiscountCalculation(0, 0)
}

export function createArticleFromLeaf(
	resolvedLeafWithTree: ResolvedLeafWithTree,
	orderProduct: OrderItemProduct | null,
	client: ClientInstance,
) {
	const { resolvedLeaf, tree } = resolvedLeafWithTree

	const type = exhaustive(tree, "treeType", {
		StaticProductPricing: () => ArticleType.Product,
		StaticTransportationPricing: () => ArticleType.Transport,
		StaticDateSlotPricing: () => ArticleType.DateSlot,
		Discounts: () => ArticleType.Discount,
	})

	const amount = exhaustive(type, {
		Transport: () => 1,
		Product: () => {
			if (orderProduct == null) {
				/**
				 * This should not happen, this case should be refactored away, it only happens because
				 * FE resolved some articles per Order not OrderProduct.
				 */
				throw IllegalState(`Product missing for typ: ${type}!`)
			}
			return getAmountOfProductsInOrderItemProduct(orderProduct, client)
		},
		DateSlot: () => 1,
		Discount: () => 1,
	})

	if (!(resolvedLeaf instanceof ResolvedArticle)) {
		throwIllegalState(typeof resolvedLeaf + " dosen't support price yet, so this should not happen")
	}

	const price = resolvedLeaf.price
	let taxPercentage = resolvedLeaf.taxPercentage

	let articlePath = resolvedLeaf.articlePath.toArticlePaths()
	return createArticle(type, articlePath, amount, price, taxPercentage, null)
}

export function createArticle(
	type: ArticleType,
	articlePath: ArticlePath,
	amount: number = 1,
	price: number,
	taxPercentage: number,
	discountDescription: DiscountDescription | null,
) {
	let article: Article = {
		type: type,
		articlePath: articlePath,
		amount: amount,
		price: price,
		taxPercentage: taxPercentage,
		discountDescription: discountDescription,
	}
	return article
}

type OrderProductAndTreeResult = {
	product: OrderItemProduct
	treeResults: ResolvedLeafWithTree[]
}

type DiscountDefAndOrderItemProducts = {
	discountDefinition: DiscountDefinition
	tree: ArticleTree
	products: OrderItemProduct[]
}

function findDiscountOrigin(
	discountDefinition: DiscountDefinition,
	leafsToConsolidateAndOrderProducts: OrderProductAndTreeResult[],
): DiscountOrigin {
	const leafOrigins = leafsToConsolidateAndOrderProducts
		.map((toConsolidate) => {
			const resLeaves = toConsolidate.treeResults.find((resLeaf) => {
				return (
					resLeaf.resolvedLeaf instanceof ResolvedTypedLeaf &&
					resLeaf.resolvedLeaf.leaf.ref === discountDefinition.id
				)
			})

			if (resLeaves != null) {
				return resLeaves.originReason
			}
			return null
		})
		.filter((it) => it != null)

	const leafOrigin = uniq(leafOrigins)[0]
	return leafOrigin != null
		? when(leafOrigin, {
				[LeafOrigin.DiscountCode]: () => DiscountOrigin.Code,
				[LeafOrigin.Regular]: () => DiscountOrigin.General,
		  })
		: DiscountOrigin.General
}

function findDiscountsFromTrees(
	client: ClientInstance,
	consumerCatalog: ConsumerCatalogInstance,
	orderItem: OrderItem,
	discountTemplateId: string | null,
): OrderProductAndTreeResult[] {
	return orderItem.products.flatMap((product: OrderItemProduct): OrderProductAndTreeResult => {
		let dateSlotId = !isString(orderItem.date) ? orderItem.date?.dateSlotId : null

		const category = client.findCategoryByName(product.category)
		if (category == null) {
			throw IllegalState(`Category missing! ${product.category}`)
		}

		let searchObject: TreeSearchObject = new Map([
			[BranchTargetKeyEnum.Category, category.id],
			[BranchTargetKeyEnum.ProductDefinition, product.productId],
			[BranchTargetKeyEnum.Service, orderItem.serviceId],
			[BranchTargetKeyEnum.WasteType, product.wasteType?.wasteTypeId],
			[BranchTargetKeyEnum.PackagingMethod, product.packagingMethod?.id],
			[BranchTargetKeyEnum.DateSlot, dateSlotId],
			[BranchTargetKeyEnum.Timeslot, orderItem.time?.timeslotId],
			[BranchTargetKeyEnum.Transportation, orderItem.transportId],
			[BranchTargetKeyEnum.Zone, orderItem.zoneId],
		])

		if (discountTemplateId) {
			searchObject.set(BranchTargetKeyEnum.DiscountTemplate, discountTemplateId)
		}

		return {
			product: product,
			treeResults: ArticleResolverService.resolveAllLeafs(
				searchObject,
				consumerCatalog.discountTrees,
				discountTemplateId,
			),
		}
	})
}

function groupProductsByDiscountRef(orderProductsAndTreeResults: OrderProductAndTreeResult[]) {
	const groupProductsByDiscountDef = new Map<DiscountDefinitionId, DiscountDefAndOrderItemProducts>()
	orderProductsAndTreeResults.forEach(({ product, treeResults }) => {
		treeResults.forEach((treeResult) => {
			if (treeResult.resolvedLeaf instanceof ResolvedTypedLeaf) {
				exhaustive(treeResult.resolvedLeaf.leaf, "type", {
					DiscountRef: (leaf) => {
						let ref = leaf.ref

						let group = groupProductsByDiscountDef.get(ref)
						if (group == null) {
							const discountDefinition = treeResult.tree.appendix?.discounts?.find((it) => it.id === ref)
							if (discountDefinition == null) {
								throwIllegalState(`DiscountDefinition missing! ${ref}`)
							}
							group = {
								discountDefinition,
								tree: treeResult.tree,
								products: [],
							}
							groupProductsByDiscountDef.set(ref, group)
						}

						group.products.push(product)
					},
				})
			} else {
				logger.error("Weird tree result found during discount consolidation! ", cloneDeep(treeResult))
			}
		})
	})
	return groupProductsByDiscountDef
}

function createArticlePath(tree: ArticleTree, discountDefinition: DiscountDefinition, sumQuantity: number) {
	return ResolvedArticlePath.ofTree(tree)
		.plus(new ResolvedArticlePathStep(discountDefinition.pathKey, `${discountDefinition.name}`, null))
		.plus(new ResolvedArticlePathStep(`${sumQuantity}`, `${sumQuantity}st`, null))
}

function countProducts(products: OrderItemProduct[], client: ClientInstance) {
	return products.reduce((acc, current) => {
		const amount = getAmountOfProductsInOrderItemProduct(current, client)
		if (amount <= 0) {
			throw IllegalState("Amount should be larger than 0")
		}

		return acc + amount
	}, 0)
}

function calculateProductDiscount(discountRate: DiscountRate, products: OrderItemProduct[], client: ClientInstance) {
	return exhaustive(discountRate, "subType", {
		OnEach: (discountRate) => {
			return calculateDiscountForEachOfProducts(products, discountRate, client)
		},
		OnTotal: (discountRate) => {
			return calculateDiscountForTotalOfProducts(products, discountRate, client)
		},
	})
}

function resolveConsolidatedProductDiscounts(
	client: ClientInstance,
	orderProductsAndTreeResults: OrderProductAndTreeResult[],
) {
	const groupProductsByDiscountDef = groupProductsByDiscountRef(orderProductsAndTreeResults)

	const productDiscountArticles: ArticleAndTree[] = []
	for (const { discountDefinition, tree, products } of groupProductsByDiscountDef.values()) {
		const discountOrigin = findDiscountOrigin(discountDefinition, orderProductsAndTreeResults)
		if (discountDefinition.target === DiscountTarget.Product) {
			exhaustive(discountDefinition, "type", {
				ThresholdQuantityDiscounts: (discounts) => {
					const sumQuantity = countProducts(products, client)

					let threshold = discounts.thresholds.find((threshold) => {
						const afterStart = sumQuantity >= threshold.start
						const beforeEnd = sumQuantity < (threshold.stop ?? Number.MAX_SAFE_INTEGER)
						return afterStart && beforeEnd
					})

					if (threshold == null) {
						logger.log(
							`No threshold hit with sumQuantity: ${sumQuantity} for DiscountDefinition: `,
							cloneDeep(discountDefinition),
						)
						return
					}

					const discountRate = threshold.discount
					discountRate.subType = DiscountRateSubTypeMapping[discountRate.type]
					let discountCalc = calculateProductDiscount(discountRate, products, client)
					let resolvedArticlePath = createArticlePath(tree, discountDefinition, sumQuantity)
					let discountAmount = Number(discountCalc.discountAmount.toFixed(2))
					let discountTaxReversCalc = Number((discountCalc.discountTaxAmount / discountAmount).toFixed(6))

					if (discountAmount > 0) {
						throw IllegalState(
							`DiscountAmount CANNOT be larger than 0! discountDefinition: ${JSON.stringify(
								discountDefinition,
							)}, products: ${JSON.stringify(products)}, tree: ${JSON.stringify(tree)}`,
						)
					}

					let discountDescription: DiscountDescription = {
						type: "ThresholdQuantityDiscount",
						id: ObjectID().toHexString() as DiscountDescriptionId,
						name: discounts.name,
						start: threshold.start,
						stop: threshold.stop,
						discountRate: discountRate,
						discountTarget: DiscountTarget.Product,
						discountOrigin: discountOrigin,
					}
					let article = createArticle(
						ArticleType.Discount,
						resolvedArticlePath.toArticlePaths(),
						1,
						discountAmount,
						discountTaxReversCalc,
						discountDescription,
					)

					productDiscountArticles.push({ article, tree })

					products.forEach((product) => {
						product.discountDescriptionRef = discountDescription.id
					})
				},
				SingleValueDiscount: (discount) => {
					const sumQuantity = countProducts(products, client)

					const discountRate = discount.discount
					discountRate.subType = DiscountRateSubTypeMapping[discountRate.type]
					let discountCalc = calculateProductDiscount(discountRate, products, client)
					let resolvedArticlePath = createArticlePath(tree, discountDefinition, sumQuantity)
					let discountAmount = Number(discountCalc.discountAmount.toFixed(2))
					let discountTaxReversCalc = Number((discountCalc.discountTaxAmount / discountAmount).toFixed(6))

					if (discountAmount > 0) {
						throw IllegalState(
							`DiscountAmount CANNOT be larger than 0! discountDefinition: ${JSON.stringify(
								discountDefinition,
							)}, products: ${JSON.stringify(products)}, tree: ${JSON.stringify(tree)}`,
						)
					}

					let discountDescription: DiscountDescription = {
						type: "SingleValueDiscount",
						id: ObjectID().toHexString() as DiscountDescriptionId,
						name: discount.name,
						discountRate: discountRate,
						discountTarget: DiscountTarget.Product,
						discountOrigin: discountOrigin,
					}
					let article = createArticle(
						ArticleType.Discount,
						resolvedArticlePath.toArticlePaths(),
						1,
						discountAmount,
						discountTaxReversCalc,
						discountDescription,
					)

					productDiscountArticles.push({ article, tree })

					products.forEach((product) => {
						product.discountDescriptionRef = discountDescription.id
					})
				},
			})
		}
	}

	return productDiscountArticles
}

function sumArticlesPrices(articles: Article[]): number {
	return sum(articles.map((it) => it.price * (it.taxPercentage + 1) * it.amount))
}

function resolveConsolidatedOrderDiscounts(
	client: ClientInstance,
	promotedArticles: ArticleAndTree[],
	productDiscounts: ArticleAndTree[],
	orderProductsAndTreeResults: OrderProductAndTreeResult[],
) {
	const groupProductsByDiscountDef = groupProductsByDiscountRef(orderProductsAndTreeResults)

	const maybeProductArticles = orderProductsAndTreeResults.flatMap((it) => it.product.articles?.allArticles ?? [])
	const startingPrice =
		sumArticlesPrices(maybeProductArticles) +
		sumArticlesPrices(promotedArticles.map((it) => it.article)) +
		sumArticlesPrices(productDiscounts.map((it) => it.article))
	const orderDiscountArticles: ArticleAndTree[] = []
	for (const { discountDefinition, tree, products } of groupProductsByDiscountDef.values()) {
		if (discountDefinition.target === DiscountTarget.Order) {
			/*
				Calculate current price, include orderDiscountArticles
			*/
			const currentPrice = startingPrice + sumArticlesPrices(orderDiscountArticles.map((it) => it.article))
			const discountOrigin = findDiscountOrigin(discountDefinition, orderProductsAndTreeResults)
			exhaustive(discountDefinition, "type", {
				ThresholdQuantityDiscounts: (discounts) => {
					const sumQuantity = countProducts(products, client)

					let threshold = discounts.thresholds.find((threshold) => {
						const afterStart = sumQuantity >= threshold.start
						const beforeEnd = sumQuantity < (threshold.stop ?? Number.MAX_SAFE_INTEGER)
						return afterStart && beforeEnd
					})

					if (threshold == null) {
						logger.log(
							`No threshold hit with sumQuantity: ${sumQuantity} for DiscountDefinition: `,
							cloneDeep(discountDefinition),
						)
						return
					}
					const discountRate = threshold.discount
					discountRate.subType = DiscountRateSubTypeMapping[discountRate.type]

					let discountCalc = calculateOrderDiscount(products, discountRate, currentPrice, sumQuantity, client)
					if (discountCalc == null) {
						return null
					}

					let resolvedArticlePath = createArticlePath(tree, discountDefinition, sumQuantity)

					let discountAmount = Number(discountCalc.discountAmount.toFixed(2))
					let discountTaxReversCalc = Number((discountCalc.discountTaxAmount / discountAmount).toFixed(6))

					if (discountAmount > 0) {
						throw IllegalState(
							`DiscountAmount CANNOT be larger than 0! discountDefinition: ${JSON.stringify(
								discountDefinition,
							)}, products: ${JSON.stringify(products)}, tree: ${JSON.stringify(tree)}`,
						)
					}

					let discountDescription: DiscountDescription = {
						type: "ThresholdQuantityDiscount",
						id: ObjectID().toHexString() as DiscountDescriptionId,
						name: discounts.name,
						start: threshold.start,
						stop: threshold.stop,
						discountRate: discountRate,
						discountTarget: DiscountTarget.Order,
						discountOrigin: discountOrigin,
					}
					let article = createArticle(
						ArticleType.Discount,
						resolvedArticlePath.toArticlePaths(),
						1,
						discountAmount,
						discountTaxReversCalc,
						discountDescription,
					)

					orderDiscountArticles.push({ article, tree })
					return null
				},
				SingleValueDiscount: (discount) => {
					const sumQuantity = countProducts(products, client)

					const discountRate = discount.discount
					discountRate.subType = DiscountRateSubTypeMapping[discountRate.type]

					let discountCalc = calculateOrderDiscount(products, discountRate, currentPrice, sumQuantity, client)
					if (discountCalc == null) {
						return null
					}

					let resolvedArticlePath = createArticlePath(tree, discountDefinition, sumQuantity)

					let discountAmount = Number(discountCalc.discountAmount.toFixed(2))
					let discountTaxReversCalc = Number((discountCalc.discountTaxAmount / discountAmount).toFixed(6))

					if (discountAmount > 0) {
						throw IllegalState(
							`DiscountAmount CANNOT be larger than 0! discountDefinition: ${JSON.stringify(
								discountDefinition,
							)}, products: ${JSON.stringify(products)}, tree: ${JSON.stringify(tree)}`,
						)
					}

					let discountDescription: DiscountDescription = {
						type: "SingleValueDiscount",
						id: ObjectID().toHexString() as DiscountDescriptionId,
						name: discount.name,
						discountRate: discountRate,
						discountTarget: DiscountTarget.Order,
						discountOrigin: discountOrigin,
					}
					let article = createArticle(
						ArticleType.Discount,
						resolvedArticlePath.toArticlePaths(),
						1,
						discountAmount,
						discountTaxReversCalc,
						discountDescription,
					)

					orderDiscountArticles.push({ article, tree })
					return null
				},
			})
		}
	}

	return orderDiscountArticles
}

export function getOrderItemProductArticles(
	client: ClientInstance,
	consumerCatalog: ConsumerCatalogInstance,
	categoryId: string,
	orderProduct: OrderItemProduct,
	serviceId?: string | undefined,
	productId?: string,
	wasteTypeId?: string | undefined,
	packagingMethodId?: string | undefined,
): Articles {
	if (!consumerCatalog.pricesEnabled || !consumerCatalog.staticProductPricing) {
		return Articles.empty()
	}

	let searchObject: TreeSearchObject = new Map([[BranchTargetKeyEnum.Category, categoryId]])

	if (serviceId) {
		searchObject.set(BranchTargetKeyEnum.Service, serviceId)
	}

	if (productId) {
		searchObject.set(BranchTargetKeyEnum.ProductDefinition, productId)
	}

	if (wasteTypeId) {
		searchObject.set(BranchTargetKeyEnum.WasteType, wasteTypeId)
	}

	if (packagingMethodId) {
		searchObject.set(BranchTargetKeyEnum.PackagingMethod, packagingMethodId)
	}

	const resolveArticleFromTree = ArticleResolverService.resolveArticleFromTree(
		searchObject,
		consumerCatalog.staticProductPricing,
	)
	if (resolveArticleFromTree == null) {
		return Articles.empty()
	}

	let resolvedLeafWithTree = new ResolvedLeafWithTree(
		resolveArticleFromTree,
		consumerCatalog.staticProductPricing,
		LeafOrigin.Regular,
	)

	let article = createArticleFromLeaf(resolvedLeafWithTree, orderProduct, client)

	return new Articles([{ article, tree: resolvedLeafWithTree.tree }])
}

export function setArticlesOnOrderItem(
	client: ClientInstance,
	consumerCatalog: ConsumerCatalogInstance,
	orderItem: OrderItem,
	discountTemplateId: string | null,
): OrderItem {
	if (consumerCatalog.configurationType === "Contract") {
		orderItem.products = (orderItem.products as OrderItemProduct[]).map((product) => {
			product.articles = Articles.empty()
			return product
		})

		orderItem.articles = Articles.empty()
	} else {
		/**
		 * First, resolve Product Articles
		 */
		orderItem.products = (orderItem.products as OrderItemProduct[]).map((product) => {
			product.articles = getOrderItemProductArticles(
				client,
				consumerCatalog,
				client.categories[product.category].id,
				product,
				product.serviceId,
				product.productId,
				product?.wasteType?.wasteTypeId,
				product?.packagingMethod?.id,
			)
			return product
		})

		/**
		 * Second, resolve Order Articles, this requires up-to-date Product Articles since it's used for discount calculations.
		 */
		orderItem.articles = getOrderItemPriceArticles(client, consumerCatalog, orderItem, discountTemplateId)
	}
	return orderItem
}

export function productServiceUnitToHumanText(unit?: ProductServiceUnit | null): string {
	if (!unit) {
		return ""
	}

	switch (unit) {
		case ProductServiceUnit.Litre:
			return "liter"
		case ProductServiceUnit.CubicMeter:
			return unitFormatter("m3")
		case ProductServiceUnit.Piece:
			return "st"
		case ProductServiceUnit.MetricTon:
			return "ton"
		default:
			return ""
	}
}

export function productServiceUnitToShortHumanText(unit?: ProductServiceUnit): string {
	if (!unit) {
		return ""
	}

	switch (unit) {
		case ProductServiceUnit.Litre:
			return "L"
		case ProductServiceUnit.CubicMeter:
			return unitFormatter("m3")
		case ProductServiceUnit.Piece:
			return "St"
		case ProductServiceUnit.MetricTon:
			return "Ton"
		default:
			return ""
	}
}

export function getAmountOfProductsInOrderItems(orderItems: OrderItem[], client: ClientInstance): number {
	return sumBy(orderItems, (oi) => getAmountOfProductsInOrderItem(oi, client))
}

export function getAmountOfProductsInOrderItem(orderItem: OrderItem, client: ClientInstance): number {
	return sumBy(orderItem.products as OrderItemProduct[], (oip) => getAmountOfProductsInOrderItemProduct(oip, client))
}

export function getAmountOfProductsInOrderItemProduct(orderItemProduct: OrderItemProduct, client: ClientInstance): number {
	let category = client.categories[orderItemProduct.category]

	return exhaustive(category, "type", {
		WasteCategory: (category) => {
			if (!orderItemProduct.serviceId || !category?.services[orderItemProduct.serviceId]) {
				return 0
			}

			return orderItemProduct?.wasteType?.amount || orderItemProduct.amount || 0
		},
		GoodsCategory: () => {
			if (orderItemProduct.packagingMethod) {
				return orderItemProduct.packagingMethod.amount
			} else {
				return 1
			}
		},
	})
}

export function getAmountOfArticlesInOrderItemProduct(orderItemProduct: OrderItemProduct, client: ClientInstance): number {
	let category = client.categories[orderItemProduct.category]

	return exhaustive(category, "type", {
		WasteCategory: (category) => {
			if (!orderItemProduct.serviceId || !category?.services[orderItemProduct.serviceId]) {
				return 0
			}

			return orderItemProduct?.wasteType?.amount || orderItemProduct.amount || 0
		},
		GoodsCategory: () => {
			if (orderItemProduct.packagingMethod && client.possiblePackagingMethods[orderItemProduct.packagingMethod.id]) {
				return (
					orderItemProduct.packagingMethod.amount *
					client.possiblePackagingMethods[orderItemProduct.packagingMethod.id].multiplier
				)
			} else {
				return 1
			}
		},
	})
}
