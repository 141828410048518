import { faClock } from "@fortawesome/free-regular-svg-icons"
import { faAngleDown, faAngleUp, faArrowsLeftRight, faMagnifyingGlass, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { BranchTargetKeyEnum, DiscountRate, DiscountTarget } from "Client/articleTrees/ArticleTreeDataModel"
import { cloneDeep, debounce, first, isArray, isEqual, isNaN, merge, some, toNumber, uniq } from "lodash"
import { DateTime } from "luxon"
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { NavigateOptions, URLSearchParamsInit, useSearchParams } from "react-router-dom"
import { MeResponseAccountType } from "../../Auth/Auth.types"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import { PreferredVATRenderPolicy } from "../../Client/consumerCatalogConfigurationTypes"
import { useConsumer } from "../../Client/ConsumerContext"
import { OrderActivityRenderMode, OrderReferenceMode } from "../../Client/FeatureTypes"
import { ProductImages, ProductServiceUnit } from "../../Client/ProductDefinitionsByCategories"
import {
	AccepteradOrderStatusIcon,
	AvslutadOrderStatusIcon,
	BilagaIcon,
	DatumIcon,
	EjHanteradOrderStatusIcon,
	FiltreraIcon,
	NekadOrderStatusIcon,
	NotifikationIcon,
	PlatsIcon,
	SökOrderIcon,
} from "../../Icons/Icon"
import { ReactComponent as MaterialBagCategory } from "../../Icons/Images/Ikon_Material_Säck.svg"
import { ReactComponent as MaterialCategory } from "../../Icons/Images/Ikon_Material_TippTruck.svg"
import { ReactComponent as BagCategory } from "../../Icons/Images/Thin_Skrappy_Bag.svg"
import { ReactComponent as ContainerCategory } from "../../Icons/Images/Thin_Skrappy_Container.svg"
import { ReactComponent as VesselCategory } from "../../Icons/Images/Thin_Skrappy_Vessel.svg"
import { Loader } from "../../Loader/Loader"
import { useNavigator } from "../../Navigator/useNavigator"
import { API, ServerError } from "../../network/API"
import { Address, OrderState } from "../../OrderAcceptance/OrderCollection"
import { addModalOpenClass, removeModalOpenClass } from "../../Orders/Components/ModulePopup/ModulePopup"
import { parseNumberSafe } from "../../Orders/Helpers"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import {
	ProductDimensions,
	ProductImageType,
	ProductType,
	ServiceType,
	Transportation,
	WasteTypeImages,
} from "../../ProductDefinitions"
import { cls } from "../../Shared/cls"
import {
	rowsPerPageSection,
	selectedFilters,
	SkrappyTable,
	TableColumn,
	tablePagingSection,
} from "../../Shared/SkrappyTable/SkrappyTable"
import { CustomOrderReference, SelectedTab } from "../OrderDetails/OrderDetails"
import { OrderInformationModalHandler } from "../OrderInformationModalHandler/OrderInformationModalHandler"
import {
	OrderTableFilterData,
	OrderTableFilterDataCustomerType,
	OrderTableFilterDataSchema,
	OrderTableFilterDataSchemaType,
	OrderTableFilterSidebar,
} from "../OrderTableFilterSidebar/OrderTableFilterSidebar"
import style from "./CustomerPortalOrders.module.css"

type Props = {
	consumerId?: string
	orders?: GetOrder[]
}

export interface Consumer {
	name: string
	email: string
	phone: string
	ordererName?: string
}

export interface CompanyConsumer extends Consumer {
	orgNumber: string
}

export interface PrivateConsumer extends Consumer {
	personNumber: string
}

interface Contact {
	name: string
	phone: string
}

export interface OrderDateTime {
	date?: string
	dateSlot?: OrderDateSlot
	timeDescription: string
	timeExact?: string
}

export interface OrderDateSlot {
	name: string
	fromDate: string
	toDate: string
}

export interface OrderCategory {
	name: string
}

export interface OrderProductService {
	name: string
	// @Deprecated("Type shouldn't be needed any more")
	type: ServiceType
}

export type GetOrderWasteType = {
	name: string
	typeImage: WasteTypeImages
}

export interface OrderProduct {
	name: string
	category?: OrderCategory
	/**
	 * @Deprecated("Replaced by category")
	 */
	type: string
	productImages?: ProductImages
	/**
	 * @Deprecated("Replaced by productImages")
	 */
	typeImage: ProductImageType
	service?: OrderProductService
	wasteType?: GetOrderWasteType
	amount: number
	dimensions: ProductDimensions
	transportation: Transportation
	productArticles: Article[]
	unit: ProductServiceUnit
	packagingMethod?: OrderProductPackagingMethod
	discountDescriptionRef?: DiscountDescriptionId
}

export type Article = {
	type: ArticleType
	articlePath: ArticlePath
	amount: number
	price: number
	taxPercentage: number
	discountDescription: DiscountDescription | null
}

export enum ArticleType {
	Transport = "Transport",
	Product = "Product",
	DateSlot = "DateSlot",
	Discount = "Discount",
}

export enum DiscountOrigin {
	Code = "Code",
	General = "General",
}

export type DiscountDescriptionId = string & { isDiscountDescriptionId: true }

export type DiscountDescription = ThresholdQuantityDiscountDescription | SingleValueDiscountDescription

export type ThresholdQuantityDiscountDescription = {
	type: "ThresholdQuantityDiscount"
	id: DiscountDescriptionId
	name: string
	start: number
	stop?: number
	discountRate: DiscountRate
	discountTarget: DiscountTarget
	discountOrigin: DiscountOrigin
}
export type SingleValueDiscountDescription = {
	type: "SingleValueDiscount"
	id: DiscountDescriptionId
	name: string
	discountRate: DiscountRate
	discountTarget: DiscountTarget
	discountOrigin: DiscountOrigin
}

export type ArticlePath = ArticlePathStep[]

export type ArticlePathStep = {
	pathKey: string
	name: string
	targetKey: BranchTargetKeyEnum | null
	targetValue: string | null
}

export type ConsumerGroupContractSettings = {
	type: "Contract"
}

export type ConsumerGroupPrepaySettings = {
	type: "Prepay"
	preferredVATRenderPolicy: PreferredVATRenderPolicy
}

export type ConsumerGroupSettings = ConsumerGroupContractSettings | ConsumerGroupPrepaySettings

export type GetOrder = {
	id: string
	orderCollectionRef: string
	clientRef: string
	state: OrderState
	customer: CompanyConsumer | PrivateConsumer
	address: Address
	marking: string
	contact?: Contact
	contactPersons: Contact[]
	datetime: OrderDateTime
	products: OrderProduct[]
	orderArticles: Article[]
	comment: string
	clientComment: string
	orderNumber: number
	payment?: Other | KlarnaPaymentOrder
	transportZoneName?: string
	execution?: GetOrderExecution
	files?: GetOrderFile[]
	createdAt: number
	loggedInUser?: GetOrderLoggedInUser
	activityAmount: number
	references: CustomOrderReference[]
	discountCode?: OrderDiscountCode
	consumerGroupSettings?: ConsumerGroupSettings
}

export type OrderDiscountCode = {
	identifier?: string
	label?: string
}

export type GetOrderLoggedInUser = {
	profilePictureUrl?: string
	firstName?: string
	lastName?: string
	email?: string
	userType: OrderLoggedInUserType
	id: string
}

export enum OrderLoggedInUserType {
	Client = "Client",
	Consumer = "Consumer",
}

export type OrderProductPackagingMethod = {
	name: string
	multiplier: number
	quantity: number
}

export type UploadedByConsumer = {
	type: "Consumer"
	userRef: string
}

export type UploadedByClient = {
	type: "Client"
	userRef: string
}

export type UploadedBySuperAdmin = {
	type: "Superadmin"
	email: string
}

export type OrderFileUploadedBy = UploadedByConsumer | UploadedByClient | UploadedBySuperAdmin

type GetOrderFileBase = {
	uploadedBy?: OrderFileUploadedBy
	fileId: string
	attachmentId: string
	originalFileName?: string
}

export type GetOrderFile = GetOrderFileImage | GetOrderFileGeneric

export type GetOrderFileImage = GetOrderFileBase & {
	url: string
	type: "Image"
}

export type GetOrderFileGeneric = GetOrderFileBase & {
	url: string
	type: "Generic"
}

export type GetOrderExecution = {
	deliveryCoordinates?: google.maps.LatLngLiteral | null
}

type OrderCollectionPayment = {}

type Other = OrderCollectionPayment

type KlarnaPaymentOrder = OrderCollectionPayment & {
	orderId: string
	orderReference?: string
}

interface OrderLike {
	property: string
	direction: "ASC" | "DESC"
}

interface PageableLike {
	page: number
	size: number
	sort?: OrderLike[]
}

export type Paging = {
	pageNumber: number
	pageSize: number
	totalPages: number
	elementsInPage: number
	totalElements: number
	isFirst: boolean
	isLast: boolean
	isEmpty: boolean
	current: PageableLike
	next?: PageableLike
	prev?: PageableLike
}

export interface GetOrdersResponse {
	orders: GetOrder[]
	paging: Paging
}

export function getSpecificOrderForClient(clientIdentifier: string, orderId: string) {
	return API.getWithRetries<GetOrder>(`/customer-portal/orders/client-v1/${clientIdentifier}/${orderId}`, true)
}

function getSpecificOrderByOrderNumberForClient(clientIdentifier: string, orderNumber: string) {
	return API.getWithRetries<GetOrder>(
		`/customer-portal/orders/client-v1/${clientIdentifier}/order-number/${orderNumber}`,
		true,
	)
}

function getFilteredOrdersForClient(
	clientIdentifier: string,
	filterData: OrderTableFilterData,
	pageNumber: number,
	pageSize: number,
) {
	return API.postWithRetries<GetOrdersResponse>(
		`/customer-portal/orders/client-v1/${clientIdentifier}/filter?page=${pageNumber}&size=${pageSize}`,
		filterData,
		{},
		30,
	)
}

export function getSpecificOrderForConsumer(clientIdentifier: string, consumerId: string, orderId: string) {
	return API.getWithRetries<GetOrder>(
		`/customer-portal/orders/consumer-v1/${clientIdentifier}/${consumerId}/${orderId}`,
		true,
	)
}

function getSpecificOrderByOrderNumberForConsumer(clientIdentifier: string, consumerId: string, orderNumber: string) {
	return API.getWithRetries<GetOrder>(
		`/customer-portal/orders/consumer-v1/${clientIdentifier}/${consumerId}/order-number/${orderNumber}`,
		true,
	)
}

function getFilteredOrdersForConsumer(
	clientIdentifier: string,
	consumerId: string,
	filterData: OrderTableFilterData,
	pageNumber: number,
	pageSize: number,
) {
	return API.postWithRetries<GetOrdersResponse>(
		`/customer-portal/orders/consumer-v1/${clientIdentifier}/${consumerId}/filter?page=${pageNumber}&size=${pageSize}`,
		filterData,
		{},
		30,
	)
}

export const StateToHumanText: Record<OrderState, string> = {
	[OrderState.Accepted]: "Accepterad",
	[OrderState.Created]: "Ej hanterad",
	[OrderState.Declined]: "Nekad",
	[OrderState.Done]: "Avslutad",
}

export function getStatusElement(state: OrderState, className?: string): JSX.Element {
	let ret: JSX.Element

	switch (state) {
		case OrderState.Accepted:
			ret = (
				<div className={cls(style.orderState, style.orderStateAccepted, className)}>{StateToHumanText[state]}</div>
			)
			break
		case OrderState.Created:
			ret = <div className={cls(style.orderState, style.orderStateCreated, className)}>{StateToHumanText[state]}</div>
			break
		case OrderState.Declined:
			ret = (
				<div className={cls(style.orderState, style.orderStateDeclined, className)}>{StateToHumanText[state]}</div>
			)
			break
		case OrderState.Done:
			ret = <div className={cls(style.orderState, style.orderStateDone, className)}>{StateToHumanText[state]}</div>
			break
	}

	return ret
}

function getStatusIcon(state: OrderState): JSX.Element {
	let ret: JSX.Element

	switch (state) {
		case OrderState.Accepted:
			ret = (
				<div className={cls(style.orderState, style.orderStateIcon, style.orderStateAccepted)}>
					<AccepteradOrderStatusIcon />
				</div>
			)
			break
		case OrderState.Created:
			ret = (
				<div className={cls(style.orderState, style.orderStateIcon, style.orderStateCreated)}>
					<EjHanteradOrderStatusIcon />
				</div>
			)
			break
		case OrderState.Declined:
			ret = (
				<div className={cls(style.orderState, style.orderStateIcon, style.orderStateDeclined)}>
					<NekadOrderStatusIcon />
				</div>
			)
			break
		case OrderState.Done:
			ret = (
				<div className={cls(style.orderState, style.orderStateIcon, style.orderStateDone)}>
					<AvslutadOrderStatusIcon />
				</div>
			)
			break
	}

	return ret
}

export declare type SetURLSearchParams = (
	nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
	navigateOpts?: NavigateOptions,
) => void

export function setPageAndFilterInUrl(
	page: number | null = null,
	pageSize: number | null = null,
	filterData: { [key: string]: string | string[] | number | boolean | object } | null,
	setQueryParams: SetURLSearchParams,
) {
	setQueryParams((x) => {
		if (page !== null) {
			x.set("page", page.toString())
		}

		if (pageSize !== null) {
			x.set("pageSize", pageSize.toString())
		}

		if (filterData !== null && Object.keys(filterData).length > 0) {
			x.set("filters", JSON.stringify(filterData))
		} else {
			x.delete("filters")
		}

		return x
	})
}

type ApplyOrdersOptions = {
	orders: GetOrder[]
	paging?: Paging
	dateSort?: "ASC" | "DESC"
}

export const CustomerPortalOrders: FC<Props> = (props) => {
	const auth = useAuth()
	const client = useClient()
	const navigator = useNavigator()
	const consumer = useConsumer()
	const permissions = usePermissions()

	const propsOrders = useRef(cloneDeep(props.orders))

	const selectedConsumerId = useRef<string>(
		(auth.Me?.type === MeResponseAccountType.Client ? props.consumerId : props.consumerId || consumer?.consumerId) ||
			"",
	)

	const [showFilterSidebar, setShowFilterSidebar] = useState(false)
	const [acceptanceOrder, setAcceptanceOrder] = useState<GetOrder | null>(null)
	const [informationOrder, setInformationOrder] = useState<GetOrder | null>(null)
	const [completionOrder, setCompletionOrder] = useState<GetOrder | null>(null)

	const [originalCurrentlyShownOrders, setOriginalCurrentlyShownOrders] = useState<GetOrder[] | null>(null)
	const [currentlyShownOrders, setCurrentlyShownOrders] = useState<GetOrder[] | null>(props.orders || null)
	const [allShownOrders, setAllShownOrders] = useState<GetOrder[] | null>(null)
	const [dataLoading, setDataLoading] = useState<boolean>(true)

	const [queryParams, setQueryParams] = useSearchParams({ page: "1", pageSize: "20", filters: JSON.stringify("{}") })

	const [textSearchValue, setTextSearchValue] = useState<string>("")

	const [paging, setPaging] = useState<Paging | null>(null)

	const [sortDates, setSortDates] = useState<"ASC" | "DESC" | null>(null)

	const [orderNumberSearch, setOrderNumberSearch] = useState<string | null>(null)
	const [orderNumberSearching, setOrderNumberSearching] = useState<boolean>(false)
	const [orderNumberSearchError, setOrderNumberSearchError] = useState<boolean>(false)
	const [showOrderNumberSearchMobile, setShowOrderNumberSearchMobile] = useState<boolean>(false)

	const orderNumberSearchRef = useRef<HTMLInputElement>(null)
	const searchInputRef = useRef<HTMLInputElement | null>(null)

	const previousQueryParams = useRef<URLSearchParams | null>(null)

	const isAllowedToView = useMemo(() => {
		return permissions.isAllowed(PermissionAreaLocation.Orders_Read)
	}, [permissions])

	useEffect(() => {
		if (!isAllowedToView) {
			navigator.open("order")
		}
	}, [isAllowedToView, navigator])

	useEffect(() => {
		const ref = orderNumberSearchRef.current
		if (showOrderNumberSearchMobile && ref) {
			ref.focus()
		}
	}, [showOrderNumberSearchMobile])

	useEffect(() => {
		if (!auth.Me || !isAllowedToView) {
			return
		}

		let pgNumber: number = getPageNumber()
		let pgSize: number = getPageSize()
		let filters = getActiveFilters()

		if (filters.searchText) {
			setTextSearchValue(filters.searchText)
		} else if (textSearchValue) {
			setTextSearchValue("")
		}

		if (
			((!previousQueryParams.current ||
				(previousQueryParams.current && !previousQueryParams.current?.has("order"))) &&
				!queryParams.has("order")) ||
			!originalCurrentlyShownOrders
		) {
			filterData(filters, pgSize, pgNumber)
		} else {
		}

		const orderId = queryParams.get("order")
		if (orderId) {
			const order = allShownOrders?.find((x) => x.id === orderId)
			if (order) {
				openOrderModal(order, false)
			} else {
				const func = shouldGetOrdersForConsumer()
					? getSpecificOrderForConsumer(client.identifier, selectedConsumerId.current, orderId)
					: getSpecificOrderForClient(client.identifier, orderId)
				func.then((order) => {
					openOrderModal(order, false)
				}).catch((e: ServerError<GetOrder>) => {
					if (e.status === 401 || e.status === 403) {
						navigator.open("order")
					} else {
						queryParams.delete("order")
						setQueryParams(queryParams)
					}
				})
			}
		} else {
			setAcceptanceOrder(null)
			setCompletionOrder(null)
			setInformationOrder(null)
		}

		previousQueryParams.current = new URLSearchParams(queryParams)
	}, [queryParams, auth, isAllowedToView])

	function getPageNumber() {
		return parseNumberSafe(queryParams.get("page"), 1, 1)
	}

	function getPageSize() {
		return parseNumberSafe(queryParams.get("pageSize"), 20, 1)
	}

	function getActiveFilters(): OrderTableFilterDataSchemaType {
		try {
			const ret = OrderTableFilterDataSchema.safeParse(JSON.parse(queryParams.get("filters") ?? "{}"))
			if (ret.success) {
				return ret.data
			} else {
				return {}
			}
		} catch (e) {
			return {}
		}
	}

	function shouldGetOrdersForConsumer(): boolean {
		return !!props.consumerId || (auth.Me?.type === "Consumer" && !!selectedConsumerId.current)
	}

	const filterData = async (filterData: OrderTableFilterData, amountPerPage: number, pageNumber: number) => {
		if (!auth.Me || !isAllowedToView) {
			return
		}
		setDataLoading(true)

		if (propsOrders.current) {
			setOriginalCurrentlyShownOrders(propsOrders.current)
			applyOrders({
				orders: propsOrders.current,
				paging: getClientsidePaging(amountPerPage, pageNumber === 0 ? 0 : pageNumber - 1),
				dateSort: sortDates || undefined,
			})
			setDataLoading(false)
			return
		}

		const func = shouldGetOrdersForConsumer()
			? getFilteredOrdersForConsumer(
					client.identifier,
					selectedConsumerId.current,
					filterData,
					pageNumber === 0 ? 0 : pageNumber - 1,
					amountPerPage,
			  )
			: getFilteredOrdersForClient(
					client.identifier,
					filterData,
					pageNumber === 0 ? 0 : pageNumber - 1,
					amountPerPage,
			  )
		await func
			.then((resp) => {
				setOriginalCurrentlyShownOrders(resp.orders)
				applyOrders({
					orders: resp.orders,
					paging: resp.paging,
					dateSort: sortDates || undefined,
				})
				setDataLoading(false)
				setTimeout(() => {
					const ref = searchInputRef.current
					if (ref) {
						ref.focus()
					}
				}, 80)
			})
			.catch((e: ServerError<GetOrdersResponse>) => {
				if (e.status === 401 || e.status === 403) {
					navigator.open("order")
				} else {
					throw e
				}
			})
	}

	function getClientsidePaging(pageSize: number, pageNumber: number): Paging {
		return {
			pageNumber: pageNumber,
			pageSize: pageSize,
			totalPages: propsOrders.current ? Math.ceil(propsOrders.current.length / pageSize) : 1,
			elementsInPage: pageSize,
			totalElements: propsOrders.current?.length || 0,
			isFirst: pageNumber === 0,
			isLast: false,
			isEmpty: false,
			current: {
				page: 1,
				size: pageSize,
			},
		}
	}

	function sortOrdersByDate(orders: GetOrder[], sortOrder: "ASC" | "DESC") {
		orders = orders.sort((a, b) => {
			let aMs: number = 0
			let bMs: number = 0
			if (a.datetime.timeExact) {
				aMs = DateTime.fromFormat(`${a.datetime.date} ${a.datetime.timeExact}`, "yyyy-MM-dd HH:mm:ss", {
					zone: "Europe/Stockholm",
				}).valueOf()
			} else {
				if (a.datetime.date) {
					aMs = new Date(a.datetime.date).valueOf()
				} else if (a.datetime.dateSlot) {
					aMs = new Date(a.datetime.dateSlot.fromDate).valueOf()
				}
			}

			if (b.datetime.timeExact) {
				bMs = DateTime.fromFormat(`${b.datetime.date} ${b.datetime.timeExact}`, "yyyy-MM-dd HH:mm:ss", {
					zone: "Europe/Stockholm",
				}).valueOf()
			} else {
				if (b.datetime.date) {
					bMs = new Date(b.datetime.date).valueOf()
				} else if (b.datetime.dateSlot) {
					bMs = new Date(b.datetime.dateSlot.fromDate).valueOf()
				}
			}
			return bMs - aMs
		})

		if (sortOrder === "ASC") {
			orders = orders.reverse()
		}

		return orders
	}

	function applyOrders(data: ApplyOrdersOptions) {
		if (data.paging) {
			setPaging(data.paging)
		}

		if (data.dateSort) {
			data.orders = sortOrdersByDate(data.orders, data.dateSort)
		}

		setCurrentlyShownOrders(data.orders)
		setAllShownOrders((allShownOrders) => {
			if (allShownOrders) {
				const allIds: string[] = allShownOrders.map((x) => x.id)

				data.orders.forEach((order) => {
					if (allIds.includes(order.id)) {
						const index = allShownOrders.findIndex((x) => x.id === order.id)
						if (!isEqual(allShownOrders[index], order)) {
							allShownOrders[index] = merge(allShownOrders[index], order)
						}
					} else {
						allShownOrders.push(order)
					}
				})

				return allShownOrders
			} else {
				return data.orders
			}
		})
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const filterRowsByTextDebounced = useCallback(
		debounce((text: string | null, data: OrderTableFilterData) => {
			if (propsOrders.current) {
				filterOrdersByTextClientSide(text || "")
			} else {
				if (text !== null) {
					data.searchText = text
				} else {
					delete data.searchText
				}
				setPageAndFilterInUrl(null, null, data, setQueryParams)
			}
		}, 1000),
		[auth, queryParams],
	)

	function filterOrdersByTextClientSide(text: string) {
		let searchText = text.normalize("NFC").toLocaleLowerCase()
		let orders: GetOrder[] = cloneDeep(propsOrders.current) || []

		if (text) {
			orders = orders
				.map((order) => {
					let match =
						(order.datetime.dateSlot?.name
							? order.datetime.dateSlot.name.normalize("NFC").toLocaleLowerCase().indexOf(searchText) > -1
							: false) ||
						(order.datetime.dateSlot?.fromDate
							? order.datetime.dateSlot.fromDate.toLocaleLowerCase().indexOf(searchText) > -1
							: false) ||
						(order.datetime.dateSlot?.toDate
							? order.datetime.dateSlot.toDate.toLocaleLowerCase().indexOf(searchText) > -1
							: false) ||
						(order.datetime.date ? order.datetime.date.toLocaleLowerCase().indexOf(searchText) > -1 : false) ||
						order.datetime.timeDescription.normalize("NFC").toLocaleLowerCase().indexOf(searchText) > -1 ||
						order.marking.normalize("NFC").toLocaleLowerCase().indexOf(searchText) > -1 ||
						order.customer.name.normalize("NFC").toLocaleLowerCase().indexOf(searchText) > -1 ||
						(order.customer.ordererName
							? order.customer.ordererName.normalize("NFC").toLocaleLowerCase().indexOf(searchText) > -1
							: false) ||
						order.address.street.normalize("NFC").toLocaleLowerCase().indexOf(searchText) > -1 ||
						order.address.city.normalize("NFC").toLocaleLowerCase().indexOf(searchText) > -1 ||
						StateToHumanText[order.state].toLocaleLowerCase().indexOf(searchText) > -1 ||
						some(order.products, (product) => {
							return (
								product.name.normalize("NFC").toLocaleLowerCase().indexOf(searchText) > -1 ||
								(product.service
									? product.service.name.normalize("NFC").toLocaleLowerCase().indexOf(searchText) > -1
									: false) ||
								(product.category
									? product.category.name.normalize("NFC").toLocaleLowerCase().indexOf(searchText) > -1
									: false)
							)
						})

					if (match) {
						return order
					}

					return null
				})
				.filter((x) => x) as GetOrder[]
		}

		setOriginalCurrentlyShownOrders(orders)
		applyOrders({
			orders: orders,
			paging: getClientsidePaging(getPageSize(), getPageNumber()),
			dateSort: sortDates || undefined,
		})
		setDataLoading(false)
	}

	function filterRowsByText(text: string, selectedFilterData: OrderTableFilterData) {
		if (!text) {
			setTextSearchValue("")
		} else {
			setTextSearchValue(text)
		}
		filterRowsByTextDebounced(text || null, selectedFilterData)
	}

	function clearFilters() {
		setTextSearchValue("")
		setQueryParams((queryParams) => {
			queryParams.delete("filters")
			queryParams.set("page", "1")

			return queryParams
		})
	}

	function sortRowsByDate() {
		if (!currentlyShownOrders) {
			return
		}

		let valueToSet: "ASC" | "DESC" | null
		if (sortDates === "ASC") {
			valueToSet = "DESC"
		} else if (sortDates === "DESC") {
			valueToSet = null
		} else {
			valueToSet = "ASC"
		}
		setSortDates(valueToSet)
		if (valueToSet !== null) {
			setCurrentlyShownOrders((orders) => (orders !== null ? sortOrdersByDate(cloneDeep(orders), valueToSet!) : null))
		} else {
			setCurrentlyShownOrders(originalCurrentlyShownOrders)
		}
	}

	function getFilterDataValue(
		key: keyof OrderTableFilterData,
		value: string | string[] | number | boolean | object,
	): string | number {
		switch (key) {
			case "state":
				return (value as string[]).map((x) => StateToHumanText[x as OrderState]).join(", ")
			case "street":
			case "city":
			case "contactName":
			case "marking":
			case "orderer":
			case "service":
			case "time":
			case "wasteType":
			case "category":
			case "customer":
				return (value as string[]).map((x) => x.substring(0, 1).toLocaleUpperCase() + x.substring(1)).join(", ")
			case "dateFrom":
				return "Från: " + value
			case "dateTo":
				return "Till: " + value
			case "dateSlotName":
				return value.toString()
			case "customerType":
				return value === OrderTableFilterDataCustomerType.Individual ? "Privatpersoner" : "Företag"
			case "loggedInUser":
				if (isArray(value)) {
					return value
						.map((userId) => {
							const user = allShownOrders?.find((x) => x.loggedInUser?.id === userId)?.loggedInUser
							return `${user?.firstName || ""} ${user?.lastName || ""}`
						})
						.join(", ")
				}
				const user = allShownOrders?.find((x) => x.loggedInUser?.id === value)?.loggedInUser
				return `${user?.firstName || ""} ${user?.lastName || ""}`
			case "zipCode":
				return value.toString()
			case "collectionNumber":
				return `Ordersamling: ${value}`
			case "hasPaymentReference":
				return `Betalad med klarna?: ${value === true ? "Ja" : "Nej"}`
			default:
				return ""
		}
	}

	function setOrderInUrl(id: string, tab: SelectedTab) {
		setTimeout(() => {
			setQueryParams((queryParams) => {
				queryParams.set("order", id)
				queryParams.set("tabId", tab)
				return queryParams
			})
		}, 100)
	}

	function removeOrderInUrl() {
		setTimeout(() => {
			setQueryParams((queryParams) => {
				queryParams.delete("order")
				queryParams.delete("tabId")
				return queryParams
			})
		}, 100)
	}

	function openOrderByOrderNumber(orderNumber: string): void {
		if (!isAllowedToView) {
			return
		}

		setTimeout(() => {
			const number = toNumber(orderNumber)
			if (isNaN(number) || number < 0) {
				return
			}

			const loadedOrder = allShownOrders?.find((x) => x.orderNumber === number)

			if (loadedOrder) {
				setOrderNumberSearching(false)
				openOrderModal(loadedOrder)
				const ref = orderNumberSearchRef.current
				if (ref) {
					ref.value = ""
				}
			} else {
				if (propsOrders.current) {
					setOrderNumberSearchError(true)
					setOrderNumberSearching(false)
					return
				}

				const func = shouldGetOrdersForConsumer()
					? getSpecificOrderByOrderNumberForConsumer(client.identifier, selectedConsumerId.current, orderNumber)
					: getSpecificOrderByOrderNumberForClient(client.identifier, orderNumber)
				func.then((order) => {
					setOrderNumberSearching(false)

					setAllShownOrders((shown) => {
						if (shown) {
							shown?.push(order)
						}
						return shown
					})
					openOrderModal(order)
					const ref = orderNumberSearchRef.current
					if (ref) {
						ref.value = ""
					}
				}).catch((e: ServerError<GetOrder>) => {
					if (e.status === 401 || e.status === 403) {
						navigator.open("order")
					} else {
						// treat any non 401 or 403 response failure as the order not existing
						setOrderNumberSearchError(true)
						setOrderNumberSearching(false)
					}
				})
			}
		}, 1)
	}

	function openOrderModal(order: GetOrder, setOrderIdInUrl: boolean = true, tab: SelectedTab = SelectedTab.Booking) {
		if (!auth.Me) {
			return
		}

		if (setOrderIdInUrl) {
			setOrderInUrl(order.id, tab)
		}

		if (order.state === OrderState.Created && auth.Me.type === MeResponseAccountType.Client) {
			setAcceptanceOrder(order)
		}

		if (order.state === OrderState.Accepted && auth.Me.type === MeResponseAccountType.Client) {
			setCompletionOrder(order)
		}

		if (
			order.state === OrderState.Declined ||
			order.state === OrderState.Done ||
			auth.Me.type === MeResponseAccountType.Consumer
		) {
			setInformationOrder(order)
		}
	}

	function modalElements(): JSX.Element {
		return (
			<>
				{showFilterSidebar && allShownOrders ? (
					<OrderTableFilterSidebar
						orders={allShownOrders || []}
						onClose={() => {
							setShowFilterSidebar(false)
							removeModalOpenClass()
						}}
						onDone={(data) => {
							setPageAndFilterInUrl(1, null, data, setQueryParams)
						}}
						filterData={getActiveFilters()}
					/>
				) : null}
				<OrderInformationModalHandler
					acceptanceOrder={acceptanceOrder}
					completionOrder={completionOrder}
					informationOrder={informationOrder}
					setAcceptanceOrder={setAcceptanceOrder}
					setCompletionOrder={setCompletionOrder}
					setInformationOrder={setInformationOrder}
					onClose={() => {
						setInformationOrder(null)
						setAcceptanceOrder(null)
						setCompletionOrder(null)
						removeModalOpenClass()
						removeOrderInUrl()
					}}
					onDone={(order) => {
						if (currentlyShownOrders) {
							const newOrders = cloneDeep(currentlyShownOrders)
							const index = currentlyShownOrders.findIndex((x) => x.id === order.id)
							newOrders[index] = order
							applyOrders({
								orders: newOrders,
							})
						}
					}}
					onUpdate={(order) => {
						if (currentlyShownOrders) {
							const newOrders = cloneDeep(currentlyShownOrders)
							const index = currentlyShownOrders.findIndex((x) => x.id === order.id)
							newOrders[index] = order
							applyOrders({
								orders: newOrders,
							})
						}
					}}
				/>
			</>
		)
	}

	function getCurrentShownOrders() {
		const orders = currentlyShownOrders || []
		if (!props.orders) {
			return orders
		}

		let ret: GetOrder[] = []
		const pageSize = getPageSize()
		const pageNumber = getPageNumber() - 1
		for (let i = pageSize * pageNumber; i < pageSize * (pageNumber + 1); i++) {
			const order = orders[i]
			if (order) {
				ret.push(order)
			}
		}
		return ret
	}

	function getStatusRow(state: OrderState): JSX.Element {
		let ret: JSX.Element

		switch (state) {
			case OrderState.Accepted:
				ret = (
					<div className={cls(style.orderState, style.orderStateRow, style.orderStateAccepted)}>
						<AccepteradOrderStatusIcon /> <span>{StateToHumanText[state]}</span>
					</div>
				)
				break
			case OrderState.Created:
				ret = (
					<div className={cls(style.orderState, style.orderStateRow, style.orderStateCreated)}>
						<EjHanteradOrderStatusIcon /> <span>{StateToHumanText[state]}</span>
					</div>
				)
				break
			case OrderState.Declined:
				ret = (
					<div className={cls(style.orderState, style.orderStateRow, style.orderStateDeclined)}>
						<NekadOrderStatusIcon /> <span>{StateToHumanText[state]}</span>
					</div>
				)
				break
			case OrderState.Done:
				ret = (
					<div className={cls(style.orderState, style.orderStateRow, style.orderStateDone)}>
						<AvslutadOrderStatusIcon /> <span>{StateToHumanText[state]}</span>
					</div>
				)
				break
		}

		return ret
	}

	function getStatusColorClass(state: OrderState) {
		let ret

		switch (state) {
			case OrderState.Accepted:
				ret = style.orderStateAccepted
				break
			case OrderState.Created:
				ret = style.orderStateCreated
				break
			case OrderState.Declined:
				ret = style.orderStateDeclined
				break
			case OrderState.Done:
				ret = style.orderStateDone
				break
		}

		return ret
	}

	function openOrderModalWithTab(order: GetOrder, tab: SelectedTab) {
		openOrderModal(order, undefined, tab)
	}

	function consumerCell(order: GetOrder) {
		const ordererName = order.customer.ordererName || ""

		if (selectedConsumerId.current) {
			return (
				<>
					<div className={style.onlyDesktop}>
						<strong>{ordererName}</strong>
						<br />
						<div className="twoLineClamp" style={{ wordBreak: "break-all" }}>
							{order.customer.email}
						</div>
					</div>
					<div className={style.onlyMobile}>
						<strong>{ordererName}</strong>
						<div className="oneLineClamp" style={{ wordBreak: "break-all" }}>
							{order.customer.email}
						</div>
					</div>
				</>
			)
		} else {
			return (
				<>
					<div className="oneLineClamp">
						<strong>{order.customer.name}</strong>
					</div>
					<div className="oneLineClamp">{ordererName}</div>
				</>
			)
		}
	}

	function dateTimeCell(order: GetOrder) {
		return (
			<>
				<span>
					<span className={style.date}>
						<DatumIcon className={style.cellIcon} size={18} />
						<div style={{ display: "flex" }}>
							{order.datetime.date ? <strong>{order.datetime.date}</strong> : null}
							{order.datetime.dateSlot ? (
								<span className={style.dateSlotTableContent}>
									<strong>{order.datetime.dateSlot.fromDate}</strong>
									{order.datetime.dateSlot.fromDate !== order.datetime.dateSlot.toDate ? (
										<>
											<FontAwesomeIcon icon={faArrowsLeftRight} />
											<strong>{order.datetime.dateSlot.toDate}</strong>
										</>
									) : null}
								</span>
							) : null}
						</div>
					</span>
					<span className={style.time}>
						<FontAwesomeIcon icon={faClock} />
						<div>{order.datetime.timeDescription}</div>
					</span>
				</span>
				{order.datetime.timeExact ? <div className={style.exactTime}>{order.datetime.timeExact}</div> : null}
			</>
		)
	}

	function categoryAndServiceCell(order: GetOrder) {
		const productCategoryNames = uniq(order.products.map((x) => x.category?.name).filter((x) => x)) as string[]
		const productServiceNames = uniq(
			order.products.map((x) => x?.service?.name || x?.packagingMethod?.name).filter((x) => x),
		) as string[]
		const orderProduct = first(order.products)
		return (
			<div style={{ display: "flex", gap: "15px", wordBreak: "break-all" }}>
				<div className={style.categoryIconBgWrapper}>
					<div style={{ position: "relative" }}>
						{orderProduct?.type === ProductType.Container ? (
							<ContainerCategory className={style.productCategoryImage} />
						) : orderProduct?.type === ProductType.Vessel ? (
							<VesselCategory className={style.productCategoryImage} />
						) : orderProduct?.type === ProductType.Bag ? (
							<BagCategory className={style.productCategoryImage} />
						) : orderProduct?.type === ProductType.Material ? (
							<MaterialCategory className={style.productCategoryImage} />
						) : (
							<MaterialBagCategory className={style.productCategoryImage} />
						)}
						<span className={style.amountOfProductsCircle}>{order.products.length}</span>
					</div>
				</div>
				<div>
					<strong>{orderProduct?.category?.name || ""}</strong>
					{productCategoryNames.length > 1 ? (
						<>
							&nbsp;+
							{(productCategoryNames.length - 1).toString()}
						</>
					) : null}
					<br />
					{orderProduct?.service?.name || orderProduct?.packagingMethod?.name || ""}
					{productServiceNames.length > 1 ? (
						<>
							&nbsp;+
							{(productServiceNames.length - 1).toString()}
						</>
					) : null}
				</div>
			</div>
		)
	}

	function skrappyTableColumns(): TableColumn<GetOrder>[] {
		return [
			{
				headerClass: style.orderNumberColumn,
				headerContent: "Order",
				cellContent: {
					content: (order) => {
						let externalReference

						if (client.features.orderUiOrderReferencesMode === OrderReferenceMode.ShowExternalReference) {
							externalReference = order.references.find((x) => x.type === "ExternalReference")?.value || ""
						}
						return (
							<span>
								{order.orderNumber}
								{externalReference ? (
									<span style={{ fontSize: "14px", fontWeight: 600 }}>
										<br />
										{externalReference}
									</span>
								) : null}
							</span>
						)
					},
					className: (order) => cls(style.orderNumberCell, getStatusColorClass(order.state)),
				},
			},
			{
				headerClass: style.paymentReferenceColumn,
				headerContent: "Klarna",
				cellContent: {
					content: (order) => {
						if (order.payment && "orderReference" in order.payment && order.payment.orderReference) {
							return (
								<span className={style.content}>
									<span className={style.paymentReferenceText}>{order.payment.orderReference}</span>
									<img
										className={style.paymentReferenceLogo}
										src="https://x.klarnacdn.net/payment-method/assets/badges/generic/klarna.svg"
										alt="Klarna logga"
									/>
								</span>
							)
						}
						return ""
					},
					className: () => style.paymentReferenceCell,
				},
				condition: () => client.features.allowKlarnaIntegration,
			},
			{
				headerClass: style.ordererColumn,
				headerContent: selectedConsumerId.current ? (
					<>Beställare</>
				) : (
					<>
						Kund
						<br />
						<span className={style.tableHeaderSubtext}>Beställare</span>
					</>
				),
				cellContent: {
					content: (order) => consumerCell(order),
					className: () => style.consumerCell,
				},
			},
			{
				headerContent: "",
				cellContent: {
					content: () => " ",
					className: () => style.tableSpacing,
				},
			},
			{
				headerClass: style.markingColumn,
				headerContent: "Märkning",
				cellContent: {
					content: (order) => <strong>{order.marking}</strong>,
					className: () => style.onlyDesktop,
				},
			},
			{
				headerContent: "",
				cellContent: {
					content: () => " ",
					className: () => style.tableSpacing,
				},
			},
			{
				headerClass: style.addressColumn,
				headerContent: "Adress",
				cellContent: {
					content: (order) => (
						<>
							<PlatsIcon className={style.cellIcon} size={18} />
							<div>
								<strong>{order.address.city}</strong>
							</div>
							<div>{order.address.street}</div>
						</>
					),
					className: () => style.addressCell,
				},
			},
			{
				headerClass: style.dateColumn,
				headerOnClick: () => sortRowsByDate(),
				headerContent: (
					<span className={style.dateColumnHeader}>
						<span>Datum</span>
						<span className={style.dateColumnHeaderArrows}>
							<FontAwesomeIcon
								icon={faAngleUp}
								style={{
									color: sortDates === "ASC" || !sortDates ? undefined : "transparent",
								}}
							/>
							<FontAwesomeIcon
								icon={faAngleDown}
								style={{
									color: sortDates === "DESC" || !sortDates ? undefined : "transparent",
								}}
							/>
						</span>
					</span>
				),
				cellContent: {
					content: (order) => dateTimeCell(order),
					className: () => style.dateTimeCell,
				},
			},
			{
				headerClass: style.categoryColumn,
				headerContent: "Utförande",
				cellContent: {
					content: (order) => categoryAndServiceCell(order),
					className: () => style.categoryAndServiceCell,
				},
			},
			{
				headerClass: style.statusColumn,
				headerContent: "Status",
				cellContent: {
					content: (order) => (
						<>
							<div className={style.statusRow}>{getStatusRow(order.state)}</div>
							<div className={style.statusIcon}>{getStatusIcon(order.state)}</div>
							<div className={style.statusText}>{getStatusElement(order.state)}</div>
						</>
					),
					className: () => style.statusCell,
				},
			},
			{
				headerClass: style.activityColumn,
				headerContent: "Aktivitet",
				cellContent: {
					content: (order) => (
						<span
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()
								openOrderModalWithTab(order, SelectedTab.Activity)
							}}>
							<NotifikationIcon size={26}></NotifikationIcon>
							<span
								className={cls(style.amountCircle, style.circleNotificationColor, {
									[style.zeroAmountCircle]: order.activityAmount === 0,
								})}>
								{order.activityAmount > 99 ? "99+" : order.activityAmount}
							</span>
						</span>
					),
					className: () => style.activityCell,
				},
				condition: () =>
					client.features.orderActivityRenderMode !== OrderActivityRenderMode.NoRender &&
					permissions.isAllowed(PermissionAreaLocation.Order_Activities_Read),
			},
			{
				headerClass: style.attachmentsColumn,
				headerContent: "Bilagor",
				cellContent: {
					content: (order) => {
						const fileAmount = order.files?.length || 0
						return (
							<span
								onClick={(e) => {
									e.preventDefault()
									e.stopPropagation()
									openOrderModalWithTab(order, SelectedTab.Attachments)
								}}>
								<BilagaIcon size={26}></BilagaIcon>
								<span
									className={cls(style.amountCircle, style.circleAccentColor, {
										[style.zeroAmountCircle]: (fileAmount || 0) === 0,
									})}>
									{fileAmount > 99 ? "99+" : fileAmount}
								</span>
							</span>
						)
					},
					className: () =>
						cls(style.attachmentsCell, {
							[style.noActivities]:
								client.features.orderActivityRenderMode === OrderActivityRenderMode.NoRender,
						}),
				},
				condition: () => permissions.isAllowed(PermissionAreaLocation.Order_Attachments_Read),
			},
		]
	}

	return (
		<>
			{modalElements()}
			<div className={style.wrapper}>
				<div className={style.listOrdersText}>
					<div>Dina ordrar</div>
				</div>
				<div className={style.topFilterRow}>
					{!propsOrders.current ? (
						<button
							disabled={orderNumberSearching || dataLoading}
							className={cls(style.filterButton, style.onlyDesktop, {
								[style.disabled]: orderNumberSearching || dataLoading,
							})}
							onClick={() => {
								setShowFilterSidebar(true)
								addModalOpenClass()
							}}>
							<span>Filtrera</span>
							<FiltreraIcon size={22} />
						</button>
					) : null}
					<span className={style.searchInputAndMobileFilterWrapper}>
						<span className={cls(style.searchInputWrapper, { [style.disabled]: dataLoading })}>
							<span>
								<FontAwesomeIcon icon={faMagnifyingGlass} />
							</span>
							<input
								ref={searchInputRef}
								type="search"
								disabled={dataLoading}
								placeholder={"Sök"}
								value={textSearchValue}
								onChange={(e) => {
									filterRowsByText(e.target.value, getActiveFilters())
								}}
							/>
						</span>
						<button
							disabled={orderNumberSearching || dataLoading}
							className={cls(style.filterButton, style.onlyMobile, {
								[style.disabled]: orderNumberSearching || dataLoading,
							})}
							onClick={() => {
								setShowFilterSidebar(true)
								addModalOpenClass()
							}}>
							<FiltreraIcon size={22} />
						</button>
					</span>
					<span>
						<span
							className={cls(style.searchInputWrapper, {
								[style.disabled]: orderNumberSearching || dataLoading,
							})}>
							<button
								className={cls(style.searchByOrderNumberButton, style.onlyMobileFlex)}
								style={{ display: showOrderNumberSearchMobile ? "none" : undefined }}
								disabled={orderNumberSearching || dataLoading}
								onClick={() => {
									setShowOrderNumberSearchMobile(true)
								}}>
								<SökOrderIcon className={style.searchByOrderNumberButtonIcon} size={null} />
								<span>Sök på ordernummer</span>
							</button>
							<span
								className={cls(style.onlyDesktop)}
								style={{ display: showOrderNumberSearchMobile ? "flex" : undefined, width: "40px" }}
								onClick={() => {
									if (orderNumberSearching || dataLoading) {
										return
									}

									if (orderNumberSearch) {
										setOrderNumberSearching(true)
										openOrderByOrderNumber(orderNumberSearch)
									}
								}}>
								{orderNumberSearching ? (
									<FontAwesomeIcon style={{ color: "gray" }} spin={true} icon={faSpinner} />
								) : (
									<SökOrderIcon className={style.searchByOrderNumberButtonIcon} size={null} />
								)}
							</span>
							<input
								ref={orderNumberSearchRef}
								className={style.onlyDesktop}
								style={{
									display: showOrderNumberSearchMobile ? "block" : undefined,
									maxWidth: showOrderNumberSearchMobile ? "185px" : undefined,
								}}
								type="number"
								onChange={(e) => {
									setOrderNumberSearchError(false)
									setOrderNumberSearch(e.target.value)
								}}
								disabled={orderNumberSearching || dataLoading}
								placeholder={"Sök på ordernummer"}
								onKeyDown={(e) => {
									if (orderNumberSearching || dataLoading) {
										return
									}

									if (e.key === "Enter" && orderNumberSearch) {
										setOrderNumberSearching(true)
										openOrderByOrderNumber(orderNumberSearch)
									}
									if (e.key === "Escape") {
										setShowOrderNumberSearchMobile(false)
									}
								}}
								onBlur={() => {
									if (orderNumberSearching || dataLoading) {
										return
									}

									if (!orderNumberSearch) {
										setShowOrderNumberSearchMobile(false)
									}
								}}
							/>
						</span>
						{orderNumberSearchError ? (
							<div className={style.orderNotFoundError}>Ordern kunde inte hittas</div>
						) : null}
					</span>
				</div>
				{selectedFilters(
					getActiveFilters(),
					(key, value) => getFilterDataValue(key, value),
					(key) => {
						const activeFilters: { [key: string]: any } = cloneDeep(getActiveFilters())
						delete activeFilters[key]
						setPageAndFilterInUrl(1, null, activeFilters, setQueryParams)
					},
					() => {
						clearFilters()
					},
				)}
				{currentlyShownOrders === null || dataLoading ? (
					<div>
						<div style={{ margin: "25px auto 0 auto", width: "100px" }}>
							<strong>Laddar data</strong>
							<Loader />
						</div>
					</div>
				) : !dataLoading && currentlyShownOrders ? (
					<>
						{rowsPerPageSection(getActiveFilters(), getPageSize(), (page) => {
							setPageAndFilterInUrl(1, page, getActiveFilters(), setQueryParams)
						})}
						<div>
							<SkrappyTable
								data={getCurrentShownOrders()}
								columns={skrappyTableColumns()}
								tableClass={style.ordersTable}
								tableRowClass={style.clickableRow}
								onRowClick={(order) => {
									openOrderModal(order)
								}}
							/>
							{currentlyShownOrders && currentlyShownOrders.length === 0 ? (
								<div
									style={{
										width: "100%",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										marginTop: "10px",
									}}>
									Inga resultat
								</div>
							) : null}
							<div></div>
						</div>
						{currentlyShownOrders
							? tablePagingSection(
									paging,
									{ pageNumber: getPageNumber(), pageSize: getPageSize() },
									() => {
										setPageAndFilterInUrl(getPageNumber() - 1, null, getActiveFilters(), setQueryParams)
									},
									() => {
										setPageAndFilterInUrl(getPageNumber() + 1, null, getActiveFilters(), setQueryParams)
									},
									(number) => {
										setPageAndFilterInUrl(number, null, getActiveFilters(), setQueryParams)
									},
							  )
							: null}
					</>
				) : !dataLoading && !currentlyShownOrders ? (
					"Något gick fel"
				) : null}
			</div>
		</>
	)
}
