import { DateTime } from "luxon"
import React, { ReactNode, useContext, useRef, useState } from "react"
import { getLogger } from "../Logging/getLogger"
import { API, ServerError } from "../network/API"
import { LoginRequest, LoginResponse, MeResponse, MeResponseAccountType, RefreshResponse } from "./Auth.types"

const logger = getLogger("AuthContext")

// stored on this level for easy access in both the auth instance class and the context
let clientIdentifier: string | null = null

export function GetAuthToken(): string | null {
	if (!clientIdentifier) {
		return null
	}
	return localStorage.getItem(`${clientIdentifier}.auth-token`) || null
}

export function GetResolvedSelectedConsumerId(): string {
	if (!clientIdentifier) {
		return ""
	}

	return localStorage.getItem(`${clientIdentifier}.resolved-selected-consumer-id`) || ""
}

export class AuthInstance {
	get IsLoggedIn(): boolean {
		return this.authData.isLoggedIn
	}

	get IsLoggedInClient(): boolean {
		return this.IsLoggedIn && this.Me?.type === MeResponseAccountType.Client
	}

	get IsLoggedInConsumer(): boolean {
		return this.IsLoggedIn && this.Me?.type === MeResponseAccountType.Consumer
	}

	get IsLoggedInAndHasConsumers(): boolean {
		return this.IsLoggedIn && this.Me !== null && this.Me.consumers.length > 0
	}

	get Me(): MeResponse | null {
		return this.authData.me
	}

	constructor(
		private readonly authData: AuthData,
		readonly init: (identifier: string) => Promise<void>,
		readonly refreshMeData: () => Promise<void>,
		readonly login: (obj: LoginRequest) => Promise<LoginResponse>,
		readonly setLoggedInFromResponse: (data: LoginResponse, clientIdentifier: string) => Promise<void>,
		readonly logout: (clientIdentifier: string) => void,
		readonly resetPasswordAndLogin: (
			clientIdentifier: string,
			resetKey: string,
			newPassword: string,
		) => Promise<LoginResponse>,
	) {}
}

const AuthContext = React.createContext<AuthInstance | null>(null)

type AuthData = {
	me: MeResponse | null
	clientIdentifier: string | null
	isLoggedIn: boolean
}

type Props = {
	children: ReactNode
}

export function clearAllServiceWorkers(clientIdentifier: string) {
	if ("serviceWorker" in navigator) {
		// Remove all service worker registrations when logging out, since they are only used for notifications,
		// which are only used when logged in
		navigator.serviceWorker.getRegistration(`/service-worker/${clientIdentifier}/`).then(
			(registration) => {
				if (registration) {
					registration
						.unregister()
						.then((res) => {
							console.log("unregistered service worker", res)
						})
						.catch((err) => {
							console.error("service worker registration unregister error", err)
						})
				}
			},
			(err) => {
				console.error("service worker getRegistration on logout error", err)
			},
		)
	}
}

export function AuthContextProvider({ children }: Props) {
	const authData = useRef<AuthData>({
		isLoggedIn: false,
		clientIdentifier: null,
		me: null,
	})

	const initPromise = useRef<Promise<void> | null>(null)

	/**
	 * Checks if auth items are stored in localstorage and inits the values of the class
	 * Calls function to refresh token as well
	 * @param identifier
	 */
	const init = (identifier: string) => {
		clientIdentifier = identifier
		if (initPromise.current != null) {
			return initPromise.current
		}

		const promise = new Promise<void>(async (resolve) => {
			const expiry = getExpirationDate(identifier)
			let loggedIn = false
			let me: MeResponse | null = null
			if (GetAuthToken() && expiry) {
				setExpirationDateToLocalStorage(expiry, identifier)

				if (tokenValid(identifier)) {
					loggedIn = true
					await fetchRefreshToken().then(
						async (x) => {
							if ("token" in x) {
								setAuthAndExpiryDatesFromAuthResponse(x, identifier)
								me = await getMeData()
							} else {
								loggedIn = false
								setLoggedOut(identifier)
							}
						},
						() => {
							loggedIn = false
							setLoggedOut(identifier)
						},
					)
				} else {
					setAuthTokenToLocalStorage(null, identifier)
					setExpirationDateToLocalStorage(null, identifier)
				}
			}
			updateAuthData(loggedIn, me)
			resolve()
		})

		initPromise.current = promise
		return promise
	}

	const getMeData = (): Promise<MeResponse | null> => {
		return API.getWithRetries<MeResponse>(`/client-portal/auth-v1/me`, true).then(
			(data) => {
				return data
			},
			(err: ServerError<MeResponse>) => {
				logger.warn(`Failed to fetch me-data with error: ${JSON.stringify(err)}`)
				return null
			},
		)
	}

	const refreshMeData = (): Promise<void> => {
		return API.getWithRetries<MeResponse>(`/client-portal/auth-v1/me`, true).then(
			(data) => {
				updateAuthData(authData.current.isLoggedIn, data)
			},
			(err: ServerError<MeResponse>) => {
				logger.error(`Failed to refresh me-data with error: ${JSON.stringify(err)}`)
			},
		)
	}

	const setLoggedInFromResponse = (data: LoginResponse, clientIdentifier: string) => {
		setAuthAndExpiryDatesFromAuthResponse(data, clientIdentifier)
		return getMeData().then((me) => {
			updateAuthData(true, me)
		})
	}

	const logout = (clientIdentifier: string) => {
		clearAllServiceWorkers(clientIdentifier)
		localStorage.removeItem(`${clientIdentifier}.resolved-selected-consumer-id`)
		localStorage.setItem(`${clientIdentifier}.selected-consumer-id`, `""`)
		setLoggedOut(clientIdentifier)
		API.postWithRetries<void>(`/client-portal/auth-v1/logout`, undefined).then(
			() => {},
			() => {},
		)
	}

	const login = (obj: LoginRequest): Promise<LoginResponse> => {
		return API.postWithRetries<LoginResponse>(`/client-portal/auth-v1/login`, obj).then(
			async (data) => {
				await setLoggedInFromResponse(data, obj.clientIdentifier)
				return data
			},
			(err: ServerError<LoginResponse>) => {
				return Promise.reject(err)
			},
		)
	}

	const resetPasswordAndLogin = (
		clientIdentifier: string,
		resetKey: string,
		newPassword: string,
	): Promise<LoginResponse> => {
		const obj = {
			newPassword,
		}
		return API.postWithRetries<LoginResponse>(`/auth/reset-password-v1/${resetKey}`, obj).then(
			async (data) => {
				await setLoggedInFromResponse(data, clientIdentifier)
				return data
			},
			(err: ServerError<LoginResponse>) => {
				return Promise.reject(err)
			},
		)
	}

	const [auth, setAuth] = useState<AuthInstance>(
		new AuthInstance(
			authData.current,
			init,
			refreshMeData,
			login,
			setLoggedInFromResponse,
			logout,
			resetPasswordAndLogin,
		),
	)

	function updateAuthData(loggedIn: boolean, meData: MeResponse | null) {
		const aData: AuthData = {
			isLoggedIn: loggedIn,
			me: meData,
			clientIdentifier: clientIdentifier,
		}
		authData.current = aData
		setAuth(new AuthInstance(aData, init, refreshMeData, login, setLoggedInFromResponse, logout, resetPasswordAndLogin))
	}

	function getExpirationDate(identifier: string): string | null {
		return localStorage.getItem(`${identifier}.auth-expiry`)
	}

	function setAuthTokenToLocalStorage(value: string | null, identifier: string | null) {
		if (!identifier) {
			return
		}

		if (!value) {
			localStorage.removeItem(`${identifier}.auth-token`)
		} else {
			localStorage.setItem(`${identifier}.auth-token`, value)
		}
	}

	function setExpirationDateToLocalStorage(value: string | null, identifier: string | null) {
		if (!identifier) {
			return
		}

		if (!value) {
			localStorage.removeItem(`${identifier}.auth-expiry`)
		} else {
			localStorage.setItem(`${identifier}.auth-expiry`, value)
		}
	}

	function setAuthAndExpiryDatesFromAuthResponse(data: LoginResponse | RefreshResponse, clientIdentifier: string) {
		setAuthTokenToLocalStorage(data.token, clientIdentifier)
		setExpirationDateToLocalStorage(data.expirationDate, clientIdentifier)
	}

	function tokenValid(identifier: string): boolean {
		const expiryDate = getExpirationDate(identifier)

		const date: DateTime | null = expiryDate ? DateTime.fromISO(expiryDate) : null

		return !!(date && date.isValid && date >= DateTime.now())
	}

	function fetchRefreshToken(): Promise<RefreshResponse> {
		return API.postWithRetries<RefreshResponse>(`/client-portal/auth-v1/refresh`, {}).then(
			(data) => {
				return data
			},
			(err: ServerError<RefreshResponse>) => {
				return Promise.reject(err)
			},
		)
	}

	function setLoggedOut(clientIdentifier: string) {
		setAuthTokenToLocalStorage(null, clientIdentifier)
		setExpirationDateToLocalStorage(null, clientIdentifier)
		updateAuthData(false, null)
	}

	return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

const useAuthContext = () => useContext(AuthContext)

export function useAuth(): AuthInstance {
	const context = useAuthContext()
	if (!context) {
		throw new Error("Auth context does not exist")
	}

	return context
}
