import { faCheck, faFileLines, faSpinner, faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { cloneDeep, debounce, map, uniqBy } from "lodash"
import { DateTime } from "luxon"
import { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from "react"
import { useAuth } from "../../../Auth/AuthContext"
import { useClient } from "../../../Client/ClientAndUserProvider"
import { useConsumer } from "../../../Client/ConsumerContext"
import { OrderActivityRenderMode } from "../../../Client/FeatureTypes"
import { CrossIcon, SendIcon } from "../../../Icons/Icon"
import { Loader } from "../../../Loader/Loader"
import { API } from "../../../network/API"
import { FinalizeButton } from "../../../Orders/Components/Form/Buttons/Buttons"
import modalStyle from "../../../Orders/Components/ModulePopup/ModulePopup.module.css"
import { SelectedFile } from "../../../Orders/Components/OrderConfirmCheckout/OrderConfirmCheckout"
import orderConfirmCheckoutStyle from "../../../Orders/Components/OrderConfirmCheckout/OrderConfirmCheckout.module.css"
import { convertTextToInitials } from "../../../Orders/Helpers"
import { PermissionAreaLocation, usePermissions } from "../../../PermissionContext"
import { RelativeCentered } from "../../../RelativeCentered/RelativeCentered"
import { cls } from "../../../Shared/cls"
import { StdTooltip } from "../../../Shared/StdTooltip/StdTooltip"
import { CompanyConsumer, GetOrderFile, PrivateConsumer } from "../../CustomerPortalOrders/CustomerPortalOrders"
import { orderDetailsGetFilesFromFileInput } from "../AttachmentsTab/OrderDetailsAttachments"
import style from "./OrderDetailsActivity.module.css"

type OrderMessageCreatedBy = {
	id: string
	name: string
	profilePictureUrl?: string
	userType: OrderMessageActivityCreatedByType
}

enum OrderMessageActivityCreatedByType {
	ClientUser = "ClientUser",
	ConsumerUser = "ConsumerUser",
}

export type OrderMessageActivity = {
	id: string
	message: string
	createdBy: OrderMessageCreatedBy
	attachmentRefs: string[]
	createdAt: number
}

type Props = {
	orderId: string
	activities: OrderMessageActivity[] | null
	onMessageSend: (message: OrderMessageActivity) => void
	files: GetOrderFile[]
	customer: CompanyConsumer | PrivateConsumer
}

type NewMessage = {
	message: string
	attachmentRefs: string[]
}

type StagedFiles = { [fileId: string]: SelectedFile }

export type UploadFileResponse = {
	fileId: string
	attachmentId: string
	url: string
}

export type GetUploadedFiles = {
	files: GetOrderFile[]
}

export const OrderDetailsActivity: FC<Props> = (props: Props) => {
	const auth = useAuth()
	const client = useClient()
	const consumer = useConsumer()
	const permissions = usePermissions()

	const [activities, setActivities] = useState<OrderMessageActivity[] | null>(props.activities)
	const [uploadError, setUploadError] = useState<string | null>(null)
	const [stagedFiles, setStagedFiles] = useState<StagedFiles>({})
	const [submitting, setSubmitting] = useState<boolean>(false)

	const [messageText, setMessageText] = useState("")

	const [failedPfpUrls, setFailedPfpUrls] = useState<string[]>([])

	const [showTooltip, setShowTooltip] = useState(false)

	const [stagedFileClicked, setStagedFileClicked] = useState<string | null>(null)
	const [shownStagedFile, setShownStagedFile] = useState<SelectedFile | null>(null)

	const fileInputRef = useRef<HTMLInputElement | null>(null)

	const textInputRef = useRef<HTMLTextAreaElement | null>(null)

	const activitiesListRef = useRef<HTMLDivElement | null>(null)

	useEffect(() => {
		if (props.activities) {
			setActivities(uniqBy(props.activities, (x) => x.id))
		} else {
			setActivities(null)
		}
	}, [props.activities])

	const hideTooltip = useCallback(
		debounce(() => {
			setShowTooltip(false)
		}, 5000),
		[],
	)

	async function sendMessage() {
		if (!permissions.isAllowed(PermissionAreaLocation.Order_Activities_Create)) {
			return
		}

		if (!messageText || !messageText.trim()) {
			setShowTooltip(true)
			hideTooltip()
			return
		}
		setSubmitting(true)
		const value = cloneDeep(messageText)
		setMessageText("")
		let attachmentRefs: string[] = []

		if (permissions.isAllowed(PermissionAreaLocation.Order_Attachments_Create)) {
			for await (const file of Object.values(stagedFiles)) {
				await uploadFile(file).then((res) => {
					if (res) {
						attachmentRefs.push(res)
					}
				})
			}
		}

		setStagedFiles({})
		const createdActivity: OrderMessageActivity | null = await API.postWithRetries<OrderMessageActivity, NewMessage>(
			`/customer-portal/order-activities-v1/${client.identifier}/${props.orderId}`,
			{
				message: value,
				attachmentRefs: attachmentRefs,
			},
		).then(
			(res) => {
				if (textInputRef.current) {
					textInputRef.current.style.height = "40px"
				}
				return res
			},
			() => {
				setMessageText(value)
				return null
			},
		)
		setSubmitting(false)

		if (createdActivity) {
			props.onMessageSend(createdActivity)
			setTimeout(() => {
				const element = activitiesListRef.current
				if (element) {
					element.scrollTop = element.scrollHeight + 100
				}
			}, 50)
		}
	}

	function formatCreatedAt(createdAt: number) {
		let date = DateTime.fromSeconds(createdAt)

		return date.setLocale("sv-SE").toLocaleString(DateTime.DATETIME_MED)
	}

	function addFiles(e: ChangeEvent<HTMLInputElement>) {
		if (!permissions.isAllowed(PermissionAreaLocation.Order_Attachments_Create)) {
			return
		}

		const maxAllowedToAdd = 5 // no more than five at a time
		setUploadError(null)
		orderDetailsGetFilesFromFileInput(e, maxAllowedToAdd).then((res) => {
			if (res.type === "ok") {
				let newStaged: StagedFiles = {}
				res.files.forEach((file) => {
					file.uploading = false
					newStaged[file.id] = file
				})
				return setStagedFiles((x) => {
					return Object.assign({}, x, newStaged)
				})
			} else {
				setUploadError(res.message)
			}
		})
	}

	function uploadFile(file: SelectedFile): Promise<string | null> {
		if (!permissions.isAllowed(PermissionAreaLocation.Order_Attachments_Create)) {
			return Promise.resolve(null)
		}

		const stagedFile = stagedFiles[file.id]
		if (!stagedFile || !stagedFile.file) {
			return Promise.resolve(null)
		}
		stagedFile.uploading = true
		stagedFile.uploadFailed = false
		stagedFiles[file.id] = stagedFile
		setStagedFiles(Object.assign({}, stagedFiles))

		const newForm = new FormData()
		newForm.append("file", stagedFile.file)
		return API.postRaw<UploadFileResponse>(
			`/order-ui/order-files-v1/${client.identifier}/${props.orderId}/upload`,
			newForm,
		)
			.then((res) => {
				const stagedFile = stagedFiles[file.id]
				if (stagedFile) {
					stagedFile.uploading = false
					stagedFile.uploaded = true
					stagedFiles[file.id] = stagedFile
					setStagedFiles(Object.assign({}, stagedFiles))
				}

				return res.attachmentId
			})
			.catch(() => {
				const stagedFile = stagedFiles[file.id]
				if (stagedFile) {
					stagedFile.uploading = false
					stagedFile.uploadFailed = true
					stagedFiles[file.id] = stagedFile
					setStagedFiles(Object.assign({}, stagedFiles))
				}

				return null
			})
	}

	function content() {
		const me = auth.Me
		if (!activities || !me) {
			return null
		}

		return (
			<div className={style.activitiesBox}>
				{shownStagedFile ? (
					<div className={style.stagedFileImagePreviewWrapper}>
						<span
							className={modalStyle.closeX}
							onClick={() => {
								setShownStagedFile(null)
							}}>
							<CrossIcon
								size={22}
								className={modalStyle.closeXIcon}
								iconClassName={modalStyle.closeXIconInside}
							/>
						</span>
						<img src={shownStagedFile.url} alt="" />
					</div>
				) : null}
				<div className={style.activitiesList} ref={activitiesListRef}>
					{activities.map((activity) => {
						const fromMe = activity.createdBy.id === me.userId
						const createdBy = activity.createdBy.name.trim() || "-"
						return (
							<div
								key={activity.id}
								className={cls(
									style.activityWrapper,
									{ [style.right]: fromMe },
									{ [style.left]: !fromMe },
								)}>
								<div className={style.userImageWrapper}>
									{activity.createdBy.profilePictureUrl &&
									!failedPfpUrls.includes(activity.createdBy.profilePictureUrl) ? (
										<img
											className={style.userImage}
											src={activity.createdBy.profilePictureUrl}
											onError={() => {
												setFailedPfpUrls((x) => [activity.createdBy.profilePictureUrl!, ...x])
											}}
											alt=""
										/>
									) : (
										convertTextToInitials(createdBy)
									)}
								</div>
								<div
									className={cls(
										style.activity,
										{
											[style.clientActivity]:
												activity.createdBy.userType ===
												OrderMessageActivityCreatedByType.ClientUser,
										},
										{
											[style.consumerActivity]:
												activity.createdBy.userType ===
												OrderMessageActivityCreatedByType.ConsumerUser,
										},
									)}>
									<div className={cls(style.clientAndConsumerName, "oneLineClamp")}>
										{activity.createdBy.userType === OrderMessageActivityCreatedByType.ClientUser
											? client.clientInfo.clientName
											: props.customer.name}
									</div>
									<div className={style.nameAndDate}>
										<div className={cls(style.name, "oneLineClamp")} title={createdBy}>
											{createdBy}
										</div>
										<div className={style.date}>{formatCreatedAt(activity.createdAt)}</div>
									</div>
									<div className={style.content}>{activity.message}</div>
									{permissions.isAllowed(PermissionAreaLocation.Order_Attachments_Read) ? (
										<div
											className={orderConfirmCheckoutStyle.orderItemSelectedImages}
											style={{
												gap: "12px",
												marginTop: activity.attachmentRefs.length > 0 ? "8px" : 0,
											}}>
											{map(activity.attachmentRefs, (attachmentId) => {
												const file = props.files.find((x) => x.attachmentId === attachmentId)
												return (
													<div
														key={attachmentId}
														className={cls(
															orderConfirmCheckoutStyle.imageWrapper,
															style.imageWrapper,
														)}>
														{!file ? (
															<div className={cls(style.genericFile, style.removedFile)}>
																<span
																	style={{
																		display: "flex",
																		marginTop: "auto",
																		marginBottom: "auto",
																	}}>
																	Borttagen bilaga
																</span>
															</div>
														) : (
															<>
																{file.type === "Image" ? (
																	<img
																		src={file.url}
																		onClick={() => {
																			window.open(file.url, "_blank")
																		}}
																	/>
																) : null}

																{file.type === "Generic" ? (
																	<div
																		className={style.genericFile}
																		onClick={() => {
																			window.open(file.url, "_blank")
																		}}>
																		<FontAwesomeIcon
																			style={{
																				color: "var(--module-box-icon-color)",
																				width: "70%",
																				height: "70%",
																			}}
																			icon={faFileLines}
																		/>
																	</div>
																) : null}
															</>
														)}
													</div>
												)
											})}
										</div>
									) : null}
								</div>
							</div>
						)
					})}
				</div>
				{Object.keys(stagedFiles).length > 0 ? (
					<div className={style.stagedFiles}>
						{map(stagedFiles, (stagedFile) => {
							return (
								<div key={stagedFile.id} className={style.stagedFile}>
									{stagedFileClicked === stagedFile.id ? (
										<div className={style.stagedFileDropUp}>
											<div
												onClick={() => {
													setShownStagedFile(stagedFile)
													setStagedFileClicked(null)
												}}>
												Visa
											</div>
											<div
												onClick={() => {
													if (stagedFile.uploading || stagedFile.uploaded) {
														return
													}

													delete stagedFiles[stagedFile.id]
													setStagedFiles(Object.assign({}, stagedFiles))
													setStagedFileClicked(null)
												}}>
												Ta bort
											</div>
										</div>
									) : null}
									{stagedFile.type === "Image" ? (
										<img className={style.image} src={stagedFile.url} alt="" />
									) : (
										<div className={style.genericFile}>
											<FontAwesomeIcon icon={faFileLines} />
										</div>
									)}
									<div
										className={style.stagedRemoveBg}
										onClick={() => {
											if (stagedFile.uploading || stagedFile.uploaded) {
												return
											}

											if (stagedFileClicked === stagedFile.id) {
												setStagedFileClicked(null)
											} else {
												setStagedFileClicked(stagedFile.id)
											}
										}}>
										{stagedFile.uploading ? (
											<FontAwesomeIcon spin={true} icon={faSpinner} className={style.crossIcon} />
										) : stagedFile.uploaded ? (
											<FontAwesomeIcon icon={faCheck} className={style.checkmark} />
										) : null}
									</div>
								</div>
							)
						})}
					</div>
				) : null}
				{uploadError ? (
					<div className={style.uploadError}>
						{uploadError}
						<CrossIcon
							onClick={() => {
								setUploadError(null)
							}}
							className={style.dismissUploadError}
							size={24}></CrossIcon>
					</div>
				) : null}
				{client.features.orderActivityRenderMode === OrderActivityRenderMode.WithChat &&
				permissions.isAllowed(PermissionAreaLocation.Order_Activities_Create) ? (
					<div className={style.activitiesFooter}>
						<StdTooltip id="regularBasketTopInfoText" open={showTooltip} clickable={true}>
							<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
								<span>Du måste skriva något för att kunna skicka ett meddelande</span>
								<FontAwesomeIcon
									icon={faXmark}
									className={style.activitiesFooterErrorDismiss}
									onClick={() => {
										setShowTooltip(false)
									}}
								/>
							</div>
						</StdTooltip>
						{client.features.orderUiAllowUploadingOrderImages &&
						permissions.isAllowed(PermissionAreaLocation.Order_Attachments_Create) ? (
							<FinalizeButton className={style.addFileButton} disabled={submitting}>
								<label aria-disabled={submitting} htmlFor={props.orderId + "attachment-input"}>
									+
								</label>
								<input
									disabled={submitting}
									ref={fileInputRef}
									style={{ display: "none" }}
									id={props.orderId + "attachment-input"}
									type="file"
									multiple={true}
									onChange={(e) => {
										addFiles(e)
									}}
								/>
							</FinalizeButton>
						) : null}
						<textarea
							ref={textInputRef}
							data-tooltip-id="regularBasketTopInfoText"
							disabled={submitting}
							value={messageText}
							className={style.textInput}
							onChange={(event) => {
								setMessageText(event.target.value)
								if (event.target.value) {
									setShowTooltip(false)
								}
							}}
							onKeyUp={(event) => {
								const target = event.target as HTMLTextAreaElement
								if (event.key === "Enter") {
									target.style.height = 16 + target.scrollHeight + "px"
								} else if (target.scrollHeight > target.clientHeight) {
									if (!target.value) {
										target.style.height = "40px"
									} else if (!["ArrowDown", "ArrowUp", "ArrowLeft", "ArrowRight"].includes(event.key)) {
										target.style.height = 16 + target.scrollHeight + "px"
									}
								} else if (target.scrollHeight === target.clientHeight && !target.value) {
									target.style.height = "40px"
								}
							}}
							placeholder="Skicka ett meddelande"
						/>
						{submitting ? (
							<FontAwesomeIcon className={style.submitBtnSpinner} icon={faSpinner} spin={true} />
						) : (
							<SendIcon
								className={style.sendIcon}
								size={36}
								onClick={() => {
									sendMessage()
								}}
							/>
						)}
					</div>
				) : null}
			</div>
		)
	}

	return (
		<div className={style.activitiesWrapper}>
			{activities ? (
				content()
			) : (
				<RelativeCentered>
					<Loader />
				</RelativeCentered>
			)}
		</div>
	)
}
