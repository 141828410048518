import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api"
import { exhaustive } from "exhaustive"
import { groupBy, isArray, minBy, some } from "lodash"
import React, { FC, useEffect, useRef, useState } from "react"
import { renderToStaticMarkup } from "react-dom/server"
import { useParams } from "react-router-dom"
import { Tooltip } from "react-tooltip"
import { useAuth } from "../Auth/AuthContext"
import { BranchTargetKeyEnum, TreeSearchObject } from "../Client/articleTrees/ArticleTreeDataModel"
import { ArticleResolverService, ResolvedArticle } from "../Client/articleTrees/ArticleTreeResolver"
import { useClient } from "../Client/ClientAndUserProvider"
import { useConsumerCatalog } from "../Client/ConsumerCatalogContext"
import {
	DensityValue,
	GoodsProductDefinition,
	ImageRenderMode,
	QuantityValue,
	WasteProductDefinition,
} from "../Client/ProductDefinitionsByCategories"
import { PenIcon, PinNoFillIcon } from "../Icons/Icon"
import { Loader } from "../Loader/Loader"
import { useNavigator } from "../Navigator/useNavigator"
import { API } from "../network/API"
import { ExpandableDropdown } from "../Orders/Components/ExpandableDropdown/ExpandableDropdown"
import { ExpandableIncrementor } from "../Orders/Components/ExpandableIncrementor/ExpandableIncrementor"
import { AccentButton } from "../Orders/Components/Form/Buttons/Buttons"
import { ExpandableIncrementorPill } from "../Orders/Components/IncrementorPill/IncrementorPill"
import { ProductImage } from "../Orders/Components/ProductImage/ProductImage"
import {
	setTransportSvgDetails,
	TransportDescription,
} from "../Orders/Components/TransportDescription/TransportDescription"
import { parseNumberSafe } from "../Orders/Helpers"
import {
	getZoneIdFromPoint,
	productServiceUnitToHumanText,
	productServiceUnitToShortHumanText,
} from "../Orders/OrderContainer/Logic"
import {
	GoodsProductDefinitionWithPrice,
	ProductPackagingWithMethodWithPriceData,
	resolveProductDefinitionsWithPriceData,
} from "../Orders/OrderContainer/resolveProductDefinitionsWithPriceData"
import { getDataForTransportSvg } from "../Orders/ProductInformationAndSelectionModule/ProductInformationAndSelectionModule"
import { libraries } from "../Orders/ProjectInputModule/ProjectInputModule"
import { ProductImageType, TransportationType } from "../ProductDefinitions"
import { RelativeCentered } from "../RelativeCentered/RelativeCentered"
import { cls, ConditionalClassName } from "../Shared/cls"
import { EventQueue } from "../Shared/eventQueue"
import { notNull } from "../Shared/notNull"
import { currencyFormatter, numberFormatter } from "../Shared/numberFormatter"
import { when } from "../Shared/when"
import { Widget, WidgetRendererV2 } from "../Shared/Widget/Widgets"
import style from "./QuantityCalculator.module.css"

type Props = {
	step: QuantityCalculatorStep
}

export enum QuantityCalculatorStep {
	List = "List",
	Selected = "Selected",
	ConfirmSummary = "ConfirmSummary",
}

export type GetQuantityCalculators = {
	calculators: GetQuantityCalculator[]
	firstStepWidget?: GetQuantityCalculatorWidget
}

type GetQuantityCalculator = {
	name: string
	description: string
	actionText: string
	image?: GetQuantityCalculatorImage
	order: number
	calculation: QuantityCalculationBySquareMeter
	widget?: GetQuantityCalculatorWidget
	widgets?: GetQuantityCalculatorWidgets
}

type GetQuantityCalculatorImage = {
	url: string
	renderMode: ImageRenderMode
}

export type GetQuantityCalculatorWidget = {
	title: string
	widget: Widget
}

type GetQuantityCalculatorWidgets = {
	secondStep?: GetQuantityCalculatorWidget
	thirdStep?: GetQuantityCalculatorWidget
}

type QuantityCalculationBySquareMeter = {
	products: QuantityCalculationProduct[]
	customProducts?: QuantityCalculationCustomProduct[]
}

type QuantityCalculationProduct = {
	name: string
	productDefinitionId: string
	millimeterThickness: number
	density: DensityValue
	compressionPercent: number
}

export type QuantityCalculationCustomProduct = {
	id: string
	name: string
	customProductName: string
	millimeterThickness: number
	density: DensityValue
	compressionPercent: number
}

type CalculationProductGoods = {
	type: "Goods"
	productName: string
	productCategoryName: string
	productId: string
	packagingMethods: ProductPackagingWithMethodWithPriceData[]
	selectedPackagingMethod: ProductPackagingWithMethodWithPriceData | null
	selectedAmount: number
}

type CalculationProductCustom = {
	type: "Custom"
	id: string
	customProductName: string
	name: string
}

export type CalculationProduct = CalculationProductGoods | CalculationProductCustom

const slugify = (str: string) =>
	str
		.toLocaleLowerCase()
		.trim()
		.replace(/[åä]/g, "a")
		.replace(/ö/g, "o")
		.replace(/[^\w\s-]/g, "")
		.replace(/[\s_-]+/g, "-")
		.replace(/^-+|-+$/g, "")

/**
 * Returns the weight of a 1 millimeter slice for a QuantityCalculationProduct
 * @param product
 */
function getOneSquareMeterOneMmWeightInKg(product: QuantityCalculationProduct | QuantityCalculationCustomProduct): number {
	return product.density.density / 1000
}

export const QuantityCalculatorWrapper: FC<Props> = (props) => {
	const client = useClient()
	const navigator = useNavigator()
	const params = useParams()

	const [quantityCalculators, setQuantityCalculators] = useState<GetQuantityCalculator[] | null>(null)
	const [firstStepWidget, setFirstStepWidget] = useState<GetQuantityCalculatorWidget | null>(null)
	const [selectedCalculator, setSelectedCalculator] = useState<GetQuantityCalculator | null>(null)

	useEffect(() => {
		API.getWithRetries<GetQuantityCalculators>(`/order-ui/quantity-calculators-v1/${client.identifier}`).then(
			(res) => {
				setQuantityCalculators(res.calculators)
				if (res.firstStepWidget) {
					setFirstStepWidget(res.firstStepWidget)
				}
			},
			() => {
				setQuantityCalculators([])
			},
		)
	}, [client.identifier])

	useEffect(() => {
		if (params.slug && quantityCalculators) {
			const calc = quantityCalculators.find((x) => slugify(x.name) === params.slug) || null

			if (!calc) {
				setTimeout(() => {
					navigator.open(`quantity-calculator`)
				}, 200)
				return
			}

			if (props.step === QuantityCalculatorStep.Selected) {
				setSelectedCalculator(calc)
			} else if (params.amount && props.step === QuantityCalculatorStep.ConfirmSummary) {
				setSelectedCalculator(calc)
			}
		}
	}, [params, props.step, quantityCalculators, navigator])

	function getAmount(): number | null {
		const amount = parseNumberSafe(params.amount, -1)
		if (amount > 0) {
			return amount
		}

		return null
	}

	if (!quantityCalculators) {
		return (
			<div className={style.wrapper}>
				<div></div>
				<div className={style.content}>
					<div className={style.contentHeader} style={{ marginBottom: "30px" }}>
						<div className={style.title}>Mängdberäknare</div>
					</div>
					<RelativeCentered>
						<Loader />
					</RelativeCentered>
				</div>
			</div>
		)
	}

	return when(props.step, {
		[QuantityCalculatorStep.List]: () => (
			<QuantityCalculatorStepOne
				calculators={quantityCalculators}
				widget={firstStepWidget}
				onSelect={(calculator) => {
					navigator.open(`quantity-calculator/${slugify(calculator.name)}`)
				}}
			/>
		),
		[QuantityCalculatorStep.Selected]: () => {
			if (selectedCalculator) {
				return <QuantityCalculatorStepTwo selectedCalculator={selectedCalculator} />
			}
			return null
		},
		[QuantityCalculatorStep.ConfirmSummary]: () => {
			const amount = getAmount()
			if (selectedCalculator && !amount) {
				navigator.open(`quantity-calculator/${slugify(selectedCalculator.name)}`)
				return null
			}
			if (!selectedCalculator && !amount) {
				navigator.open(`quantity-calculator`)
				return null
			}
			if (selectedCalculator && amount) {
				return <QuantityCalculatorStepThree amount={amount} selectedCalculator={selectedCalculator} />
			}
			return null
		},
	})
}

function calcItemImageSection(calc: GetQuantityCalculator): JSX.Element {
	const styles: (string | ConditionalClassName | undefined)[] = [
		style.calcItemImageWrapper,
		{ [style.noImage]: !calc.image?.url },
	]

	if (calc.image) {
		if (calc.image.renderMode === ImageRenderMode.Fill) {
			styles.push({
				[style.calcUrlImageWrapperFill]: calc.image.renderMode === ImageRenderMode.Fill,
			})
		}
		if (calc.image.renderMode === ImageRenderMode.Fit) {
			styles.push({
				[style.calcUrlImageWrapperFit]: calc.image.renderMode === ImageRenderMode.Fit,
			})
		}
	}

	return (
		<div className={cls(...styles)}>
			{calc.image ? (
				<img
					className={
						calc.image.renderMode === ImageRenderMode.Fill ? style.calcUrlImageFill : style.calcUrlImageFit
					}
					src={calc.image.url}
					alt="Mängdberäknarbild"
				/>
			) : null}
		</div>
	)
}

type StepOneProps = {
	calculators: GetQuantityCalculator[]
	widget: GetQuantityCalculatorWidget | null
	onSelect: (calculator: GetQuantityCalculator) => void
}
export const QuantityCalculatorStepOne: FC<StepOneProps> = (props) => {
	function noCalcs() {
		return (
			<div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center" }}>
				<div style={{ color: "var(--main-background-text-color)", fontSize: "28px", marginTop: "25px" }}>
					Det finns tyvärr inga mängdberäknare att se för tillfället. Försök igen senare.
				</div>
			</div>
		)
	}

	function selectCalc(calc: GetQuantityCalculator) {
		props.onSelect(calc)
	}

	function calcsList() {
		return (
			<div className={style.calcsWrapper}>
				{props.calculators.map((calc) => {
					return (
						<div key={calc.name + "_item"} className={style.calcItem}>
							<div className={style.calcItemContent}>
								<div className={style.name}>{calc.name}</div>
								<div className={style.description} style={{ marginBottom: "15px" }}>
									{calc.description}
								</div>
								<AccentButton
									className={style.actionButton}
									onClick={() => {
										selectCalc(calc)
									}}>
									{calc.actionText}
								</AccentButton>
							</div>
							{calcItemImageSection(calc)}
						</div>
					)
				})}
			</div>
		)
	}

	return (
		<div className={style.wrapper}>
			<div></div>
			<div className={style.content}>
				<div className={style.contentHeader}>
					<div className={style.title}>Mängdberäknare</div>
					{props.calculators.length > 0 ? (
						<div className={style.subTitle}>
							Välj kategori och fyll i arean så kommer ett förslag med varor och mängder
						</div>
					) : null}
				</div>
				{props.calculators.length > 0 ? calcsList() : noCalcs()}
			</div>
			{props.widget ? (
				<>
					<div></div>
					<div className={style.widgetSection}>
						<div className={style.widgetTitle}>{props.widget.title}</div>
						<WidgetRendererV2 apiWidgets={[props.widget.widget]} />
					</div>
				</>
			) : null}
		</div>
	)
}

const disclaimerText = (clientName: string) => {
	return (
		<div className={style.disclaimerText}>
			Detta beräkningsverktyg ger endast en uppskattning av materialbehovet och ersätter inte professionell
			rådgivning. <strong>{clientName} ansvarar inte för avvikelser</strong> i beräknade materialmängder, och vi
			rekommenderar att konsultera en fackman innan projekt påbörjas.
		</div>
	)
}

type StepTwoProps = {
	selectedCalculator: GetQuantityCalculator
}
export const QuantityCalculatorStepTwo: FC<StepTwoProps> = (props) => {
	const client = useClient()
	const navigator = useNavigator()

	const squareMeterInputRef = useRef<HTMLInputElement | null>(null)

	function setAmount(amount: number) {
		navigator.open(`quantity-calculator/${slugify(props.selectedCalculator.name)}/${amount.toString()}`)
	}

	return (
		<div className={style.wrapper}>
			<div></div>
			<div className={style.content}>
				<div className={style.contentHeader}>
					<div className={style.title}>Ange din area</div>
					{disclaimerText(client.clientInfo.clientName)}
				</div>
				<div>
					<div className={cls(style.calcItem, style.selectedCalcItem)}>
						{calcItemImageSection(props.selectedCalculator)}
						<div className={style.calcItemContent}>
							<div className={style.name}>{props.selectedCalculator.name}</div>
							<div className={style.description}>{props.selectedCalculator.description}</div>
						</div>
						<div className={style.selectAreaSection}>
							<div style={{ marginTop: "auto", marginBottom: "auto" }}>
								<div className={style.title}>Ange den totala arean</div>
								<div className={style.inputWrapper}>
									<input
										className={style.input}
										ref={squareMeterInputRef}
										type="number"
										onKeyDown={(event) => {
											if (event.key === "Enter") {
												const current = squareMeterInputRef.current
												if (current) {
													const number = parseNumberSafe(current.value, 1)
													if (number > 0) {
														setAmount(number)
													}
												}
											} else if (["e", "E", "+", "-"].includes(event.key)) {
												event.preventDefault()
											}
										}}
									/>
									<span>m²</span>
								</div>
							</div>
							<AccentButton
								className={style.actionButton}
								onClick={() => {
									const current = squareMeterInputRef.current
									if (current) {
										const number = parseNumberSafe(current.value, 1)
										if (number > 0) {
											setAmount(number)
										}
									}
								}}>
								{props.selectedCalculator.actionText}
							</AccentButton>
						</div>
					</div>
				</div>
			</div>
			{props.selectedCalculator.widgets?.secondStep ? (
				<>
					<div></div>
					<div className={style.widgetSection}>
						<div className={style.widgetTitle}>{props.selectedCalculator.widgets.secondStep.title}</div>
						<WidgetRendererV2 apiWidgets={[props.selectedCalculator.widgets.secondStep.widget]} />
					</div>
				</>
			) : null}
		</div>
	)
}

type StepThreeProps = {
	amount: number
	selectedCalculator: GetQuantityCalculator
}
export const QuantityCalculatorStepThree: FC<StepThreeProps> = (props) => {
	const auth = useAuth()
	const client = useClient()
	const consumerCatalog = useConsumerCatalog()
	const navigator = useNavigator()

	const [suggestedProducts, setSuggestedProducts] = useState<CalculationProduct[]>([])
	const [openedCalcProductDropdown, setOpenedCalcProductDropdown] = useState<string | null>(null)

	const [selectedAddress, setSelectedAddress] = useState<{ place: google.maps.places.PlaceResult; text: string } | null>(
		null,
	)

	const actualProducts = useRef<{ [productId: string]: WasteProductDefinition | GoodsProductDefinition }>({})

	const calcProductDropdownRef = useRef<HTMLDivElement | null>(null)

	const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null)
	const autoCompleteInputRef = useRef<HTMLInputElement | null>(null)

	const { isLoaded: googleMapsLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
		libraries: libraries,
		language: "sv",
	})

	useEffect(() => {
		document.addEventListener("click", handleClickOutsideDropdown, true)
		return () => {
			document.removeEventListener("click", handleClickOutsideDropdown, true)
		}
	}, [])

	useEffect(() => {
		props.selectedCalculator.calculation.products.forEach((calcProduct) => {
			if (!actualProducts.current[calcProduct.productDefinitionId]) {
				const product = client.findProductById(calcProduct.productDefinitionId)
				if (product) {
					actualProducts.current[calcProduct.productDefinitionId] = product
				}
			}
		})
	}, [client, props.selectedCalculator])

	useEffect(() => {
		const calcProducts: CalculationProduct[] = []
		props.selectedCalculator.calculation.products.forEach((quantityCalcProduct) => {
			const actualProduct = actualProducts.current[quantityCalcProduct.productDefinitionId]
			if (actualProduct) {
				return exhaustive(actualProduct, "type", {
					GoodsProductDefinition: (it) => {
						const calcProduct: CalculationProduct = {
							type: "Goods",
							productName: actualProduct.name,
							productCategoryName: actualProduct.categoryName,
							productId: it.id,
							packagingMethods: it.packagings
								.map((packaging) => {
									return getPackagingMethodWithPriceData(it, packaging.packagingMethodId)
								})
								.filter(notNull),
							selectedPackagingMethod: null,
							selectedAmount: -1,
						}

						const defaultPackaging = getDefaultSelectedPackagingMethod(quantityCalcProduct, calcProduct)
						if (defaultPackaging) {
							calcProduct.selectedAmount = defaultPackaging.amount
							calcProduct.selectedPackagingMethod = defaultPackaging.method
						}

						calcProducts.push(calcProduct)
					},
					WasteProductDefinition: () => {},
				})
			}
		})

		props.selectedCalculator.calculation.customProducts?.forEach((quantityCalcCustomProduct) => {
			const calcProduct: CalculationProduct = {
				type: "Custom",
				name: quantityCalcCustomProduct.name,
				customProductName: quantityCalcCustomProduct.customProductName,
				id: quantityCalcCustomProduct.id,
			}

			calcProducts.push(calcProduct)
		})
		setSuggestedProducts(calcProducts)
	}, [props.selectedCalculator])

	function getAmountNeeded(measurement: QuantityValue, quantityCalcProduct: QuantityCalculationProduct): number {
		let amountNeeded = -1

		const desiredMinWeightInKg = getCalculationProductWeight(quantityCalcProduct, false).compressedWeight
		const desiredMinVolumeInM3 = desiredMinWeightInKg / quantityCalcProduct.density.density

		exhaustive(measurement, "type", {
			Length: () => {
				// Not supported for now
			},
			Volume: (it) => {
				if (it.volume > 0) {
					amountNeeded = Math.ceil(desiredMinVolumeInM3 / it.volume)
				}
			},
			Mass: (it) => {
				if (it.mass > 0) {
					amountNeeded = Math.ceil(desiredMinWeightInKg / it.mass)
				}
			},
			Density: (it) => {
				if (it.density > 0) {
					amountNeeded = Math.ceil(desiredMinWeightInKg / it.density)
				}
			},
		})

		return amountNeeded
	}

	function getDefaultSelectedPackagingMethod(
		quantityCalcProduct: QuantityCalculationProduct,
		calcProduct: CalculationProductGoods,
	): {
		method: ProductPackagingWithMethodWithPriceData
		amount: number
	} | null {
		let ret: {
			method: ProductPackagingWithMethodWithPriceData
			amount: number
		}[] = []

		calcProduct.packagingMethods.forEach((x) => {
			const measurement = x.packagingMethod.measurement
			if (measurement) {
				const amountNeeded = getAmountNeeded(measurement, quantityCalcProduct)
				if (amountNeeded > -1) {
					ret.push({
						method: x,
						amount: amountNeeded,
					})
				}
			}
		})

		return minBy(ret, (x) => x.amount) || null
	}

	function getPackagingMethodWithPriceData(
		actualProduct: GoodsProductDefinition,
		packagingMethodId: string,
	): ProductPackagingWithMethodWithPriceData | null {
		const resolvedProductWithPrice = resolveProductDefinitionsWithPriceData(
			[actualProduct],
			null,
			consumerCatalog,
			client,
			auth,
		)
		let ret

		if (resolvedProductWithPrice && resolvedProductWithPrice?.length > 0) {
			const prod = resolvedProductWithPrice[0] as GoodsProductDefinitionWithPrice
			ret = prod.packagings.find(
				(x: ProductPackagingWithMethodWithPriceData) => x.packagingMethodId === packagingMethodId,
			)
		}

		return ret || null
	}

	function handleClickOutsideDropdown(event: Event) {
		const selectorWrappers = Array.from(document.getElementsByClassName("suggestedProductServiceSelectorWrapper"))
		if (!calcProductDropdownRef.current || selectorWrappers.length === 0) {
			return
		}

		if (
			!calcProductDropdownRef.current.contains(event.target as Node) &&
			!some(selectorWrappers, (selectorWrapper) => selectorWrapper.contains(event.target as Node))
		) {
			setOpenedCalcProductDropdown(null)
		}
	}

	function getApproximateTotalWeight(): number {
		if (!props.amount) {
			return 0
		}
		let approx = 0

		props.selectedCalculator.calculation.products.forEach((calcProduct) => {
			approx += getCalculationProductWeight(calcProduct, false).compressedWeight
		})

		return approx
	}

	/**
	 * Calculates the weight on a product based on the specified thickness, area and compression factor
	 *
	 * 1. Weight of one mm
	 * 	[times]
	 * 2. Specified thickness in mm
	 * 	[times]
	 * 3. Amount of square meters specified
	 * 	[plus]
	 * 4. The aforementioned weight times compression factor
	 *
	 * <strong>All returned weights are in kilograms</strong>
	 *
	 * @param calcProduct
	 * @param round If the returned value should be rounded using Math.round or not
	 */
	function getCalculationProductWeight(
		calcProduct: QuantityCalculationProduct | QuantityCalculationCustomProduct,
		round: boolean,
	): { compressedWeight: number; unCompressedWeight: number } {
		let weight = getOneSquareMeterOneMmWeightInKg(calcProduct) * calcProduct.millimeterThickness * props.amount

		if (round) {
			return {
				compressedWeight: Math.round(weight + weight * (calcProduct.compressionPercent / 100)),
				unCompressedWeight: Math.round(weight),
			}
		}
		return { compressedWeight: weight + weight * (calcProduct.compressionPercent / 100), unCompressedWeight: weight }
	}

	function calcProductSelectionElement(
		productId: string,
		methodWithPrice: ProductPackagingWithMethodWithPriceData,
		amount: number,
	): JSX.Element | null {
		const unit = productServiceUnitToHumanText(methodWithPrice.packagingMethod.productUnit)

		const selectionElement = exhaustive(methodWithPrice.packagingMethod.visualization, "type", {
			Numeric: () => {
				return goodsCategoryPackagingNumericInput(productId, methodWithPrice, unit, amount)
			},
			Dropdown: () => {
				return goodsCategoryPackagingDropdownInput(productId, methodWithPrice, amount)
			},
			Incrementor: () => {
				return goodsCategoryPackagingIncrementorInput(productId, methodWithPrice, amount)
			},
		})

		return (
			<div
				key={methodWithPrice.packagingMethod.id + "_selectionElement"}
				className={style.suggestedProductSelector}
				style={{ width: methodWithPrice.packagingMethod.visualization.type === "Dropdown" ? "130px" : undefined }}>
				{selectionElement}
			</div>
		)
	}

	function goodsCategoryPackagingNumericInput(
		productId: string,
		methodWithPrice: ProductPackagingWithMethodWithPriceData,
		unit: string,
		amount: number,
	) {
		return (
			<ExpandableIncrementorPill
				defaultValue={amount}
				max={10000}
				text={unit}
				onChange={(value) => {
					if (value === null || value <= 0) {
						return
					}

					setSuggestedProducts((x) => {
						const index = x.findIndex((x) => x.type === "Goods" && x.productId === productId)

						if (index > -1) {
							const item = x[index]
							if (item?.type === "Goods") {
								item.selectedAmount = value
								x[index] = item
							}
						}

						return [...x]
					})
				}}
				size={40}
				multiplier={methodWithPrice.packagingMethod.multiplier}
				onBlur={(value) => {
					if (value === null || value <= 0) {
						setSuggestedProducts((x) => {
							const index = x.findIndex((x) => x.type === "Goods" && x.productId === productId)

							if (index > -1) {
								const item = x[index]
								if (item?.type === "Goods") {
									item.selectedAmount = 0
									x[index] = item
								}
							}

							return [...x]
						})
					}
				}}
			/>
		)
	}

	function goodsCategoryPackagingIncrementorInput(
		productId: string,
		methodWithPrice: ProductPackagingWithMethodWithPriceData,
		amount: number,
	) {
		return (
			<ExpandableIncrementor
				name={methodWithPrice.packagingMethod.id}
				defaultValue={amount}
				max={2000}
				min={0}
				onChange={(_, value) => {
					if (value > 0) {
						setSuggestedProducts((x) => {
							const index = x.findIndex((x) => x.type === "Goods" && x.productId === productId)

							if (index > -1) {
								const item = x[index]
								if (item?.type === "Goods") {
									item.selectedAmount = value
									x[index] = item
								}
							}

							return [...x]
						})
					}
				}}
				onBlur={(value) => {
					if (value === 0) {
						setSuggestedProducts((x) => {
							const index = x.findIndex((x) => x.type === "Goods" && x.productId === productId)

							if (index > -1) {
								const item = x[index]
								if (item?.type === "Goods") {
									item.selectedAmount = value
									x[index] = item
								}
							}

							return [...x]
						})
					}
				}}
			/>
		)
	}

	function goodsCategoryPackagingDropdownInput(
		productId: string,
		methodWithPrice: ProductPackagingWithMethodWithPriceData,
		amount: number,
	) {
		return (
			<ExpandableDropdown
				getItems={(indexes) => {
					return indexes.map((idx) => {
						return {
							key: `${(
								methodWithPrice.packagingMethod.multiplier *
								(idx + 1)
							).toString()} ${productServiceUnitToShortHumanText(
								methodWithPrice.packagingMethod.productUnit,
							)}`,
							value: idx + 1,
						}
					})
				}}
				unSelectionItemText={`0 ${productServiceUnitToShortHumanText(methodWithPrice.packagingMethod.productUnit)}`}
				onChange={(item) => {
					let amount = parseNumberSafe(item?.value, 0)

					setSuggestedProducts((x) => {
						const index = x.findIndex((x) => x.type === "Goods" && x.productId === productId)

						if (index > -1) {
							const item = x[index]
							if (item?.type === "Goods") {
								item.selectedAmount = amount
								x[index] = item
							}
						}

						return [...x]
					})
				}}
				defaultValue={amount}
			/>
		)
	}

	function totalWeightsBox() {
		return (
			<div className={cls(style.summaryBox, style.totalWeightsBox)}>
				<div className={style.title}>Mått</div>
				<div className={style.spacedRow}>
					<div>Area:</div>
					<div style={{ fontSize: "20px" }}>{numberFormatter(props.amount)} m²</div>
				</div>
				<hr />
				<div className={style.spacedRow}>
					<div>Uppskattad vikt:</div>
					<div style={{ fontSize: "20px" }}>
						<strong>{numberFormatter(Math.round(getApproximateTotalWeight() / 1000))} ton</strong>
					</div>
				</div>
			</div>
		)
	}

	function calculatedWeightsBox() {
		const regularProducts = props.selectedCalculator.calculation.products
		const customProducts = props.selectedCalculator.calculation?.customProducts || []

		const theProducts = [...regularProducts, ...customProducts]

		return (
			<div className={cls(style.summaryBox, style.calculatedWeightsBox)}>
				<div className={style.title}>Beräknade lager</div>
				<div>
					<div className={style.calcProductListHeader}>
						<div>Lager</div>
						<div>Höjd</div>
						<div>Vikt</div>
					</div>
					<div className={style.calcProductList}>
						{theProducts.map((calcProduct, index) => {
							let productName
							let key
							if ("productDefinitionId" in calcProduct) {
								const actualProduct = actualProducts.current[calcProduct.productDefinitionId]
								if (!actualProduct) {
									return null
								}
								key = calcProduct.productDefinitionId
								productName = actualProduct.name
							} else {
								key = calcProduct.id
								productName = calcProduct.customProductName
							}

							const weight = getCalculationProductWeight(calcProduct, true)
							return (
								<React.Fragment key={key}>
									<div className={style.calcProductListItem}>
										<div>
											{index + 1}. {calcProduct.name}
										</div>
										<div>{numberFormatter(calcProduct.millimeterThickness / 10)} cm</div>
										<div className={style.itemWeight}>
											≈ {numberFormatter(weight.compressedWeight)} kg
										</div>

										{calcProduct.compressionPercent > 0 ? (
											<div className={style.itemCompressionWeight}>
												<span>Varav {calcProduct.compressionPercent}% kompressionsvikt</span>
												<span>
													≈ {numberFormatter(weight.compressedWeight - weight.unCompressedWeight)}{" "}
													kg
												</span>
											</div>
										) : null}
										<div className={style.productName}>{productName}</div>
									</div>
									<hr />
								</React.Fragment>
							)
						})}
					</div>
				</div>
			</div>
		)
	}

	function suggestedProductsBox() {
		return (
			<div className={cls(style.summaryBox, style.suggestedProductsBox)}>
				<div className={style.title}>Föreslagna artiklar</div>

				<div className={style.suggestedProductsWrapper}>
					{suggestedProducts.map((suggestedProduct) => {
						let product: WasteProductDefinition | GoodsProductDefinition | null = null

						if (suggestedProduct.type === "Goods") {
							product = actualProducts.current[suggestedProduct.productId] || null
						}
						return suggestedProductBox(suggestedProduct, product)
					})}
				</div>
			</div>
		)
	}

	function suggestedProductBox(
		suggestedProduct: CalculationProduct,
		product: WasteProductDefinition | GoodsProductDefinition | null,
	) {
		const customProducts = props.selectedCalculator.calculation?.customProducts
		const hasCustomProducts = isArray(customProducts) && customProducts.length > 0
		return exhaustive(suggestedProduct, "type", {
			Goods: (it) => {
				if (!product) {
					return null
				}

				const measurement = it.selectedPackagingMethod?.packagingMethod?.measurement
				const quantityCalcProduct = props.selectedCalculator.calculation.products.find(
					(x) => x.productDefinitionId === it.productId,
				)
				let suggestedAmountNeeded = -1
				if (measurement && quantityCalcProduct) {
					suggestedAmountNeeded = getAmountNeeded(measurement, quantityCalcProduct)
				}

				return (
					<React.Fragment key={product.id}>
						<div className={style.suggestedProduct}>
							{"url" in product.images.main ? (
								<div
									className={cls(
										style.suggestedProductUrlImageWrapper,
										{
											[style.fill]: product.images.main.renderMode === ImageRenderMode.Fill,
										},
										{
											[style.fit]: product.images.main.renderMode === ImageRenderMode.Fit,
										},
									)}>
									<img src={product.images.main.url} alt="" />
								</div>
							) : (
								<ProductImage
									onClick={() => {}}
									client={client}
									categoryName={product.categoryName}
									image={ProductImageType[product.images.main.typeImage]}
									wrapperClassName={style.suggestedProductCard}
								/>
							)}
							<div className={style.suggestedProductInfo} style={{ position: "relative" }}>
								<div className={style.title}>{product.name}</div>
								{!hasCustomProducts ? (
									<>
										<div
											className={cls(
												style.dropdownSelector,
												"suggestedProductServiceSelectorWrapper",
											)}
											onClick={() => {
												setOpenedCalcProductDropdown((x) => {
													if (x === it.productId) {
														return null
													}

													return it.productId
												})
											}}>
											<span className={"oneLineClamp"}>
												{it.selectedPackagingMethod
													? it.selectedPackagingMethod.packagingMethod.name
													: "Välj tjänst"}
											</span>
											{openedCalcProductDropdown === it.productId ? (
												<FontAwesomeIcon icon={faAngleUp} className={cls(style.editIcon)} />
											) : (
												<FontAwesomeIcon icon={faAngleDown} className={cls(style.editIcon)} />
											)}
										</div>
										{openedCalcProductDropdownElement(it)}
									</>
								) : null}
							</div>
							{!hasCustomProducts ? (
								<div className={style.priceAndSelectorWrapper}>
									<div className={style.suggestedProductPrice}>
										{it.selectedPackagingMethod?.priceData
											? exhaustive(it.selectedPackagingMethod?.priceData, "type", {
													Hide: () => {},
													EnabledButMissingPrice: () => {
														return "Pris saknas"
													},
													EnabledWithoutPrice: () => {},
													DisabledWithCallUs: () => {
														return "Ring oss"
													},
													Article: (priceData) => {
														let price =
															priceData.article.resolvedLeaf.price *
															(1 + priceData.article.resolvedLeaf.taxPercentage)

														if (price > 0 && it.selectedPackagingMethod) {
															const packaging = it.selectedPackagingMethod?.packagingMethod

															if (packaging && packaging.multiplier > 1) {
																return `${currencyFormatter(price)} / ${
																	it.selectedPackagingMethod?.packagingMethod.multiplier
																} ${productServiceUnitToHumanText(
																	it.selectedPackagingMethod?.packagingMethod.productUnit,
																)}`
															} else {
																return `${currencyFormatter(price)}`
															}
														}

														return null
													},
											  })
											: null}
									</div>
									{it.selectedPackagingMethod ? (
										calcProductSelectionElement(
											it.productId,
											it.selectedPackagingMethod,
											it.selectedAmount,
										)
									) : (
										<div></div>
									)}
								</div>
							) : null}
						</div>
						{!hasCustomProducts &&
						suggestedAmountNeeded !== it.selectedAmount &&
						suggestedAmountNeeded !== -1 ? (
							<div className={style.suggestedProductAmountDiffers}>
								OBS: Det valda antalet skiljer sig från vårt föreslaga antal
							</div>
						) : null}
					</React.Fragment>
				)
			},
			Custom: (it) => {
				return (
					<React.Fragment key={it.id}>
						<div className={cls(style.suggestedProduct, style.suggestedCustomProduct)}>
							<div className={style.suggestedProductInfo}>
								<div className={style.title}>{it.customProductName}</div>
							</div>
						</div>
					</React.Fragment>
				)
			},
		})
	}

	function openedCalcProductDropdownElement(calcProduct: CalculationProductGoods): JSX.Element | null {
		if (openedCalcProductDropdown !== calcProduct.productId) {
			return null
		}

		return (
			<div ref={calcProductDropdownRef} className={style.dropdown}>
				{calcProduct.packagingMethods.map((calcProductPackaging) => {
					return (
						<div
							key={calcProduct.productId + calcProductPackaging.packagingMethod.id}
							onClick={() => {
								setSuggestedProducts((suggestedProducts) => {
									const index = suggestedProducts.findIndex(
										(x) => x.type === "Goods" && x.productId === calcProduct.productId,
									)

									if (index > -1) {
										const actualProduct = actualProducts.current[calcProduct.productId]
										const suggestedProduct = suggestedProducts[index]
										if (
											suggestedProduct &&
											suggestedProduct.type === "Goods" &&
											suggestedProduct.selectedPackagingMethod?.packagingMethod.id !==
												calcProductPackaging.packagingMethod.id &&
											actualProduct?.type === "GoodsProductDefinition"
										) {
											suggestedProduct.selectedPackagingMethod = getPackagingMethodWithPriceData(
												actualProduct,
												calcProductPackaging.packagingMethod.id,
											)
											const measurement =
												suggestedProduct.selectedPackagingMethod?.packagingMethod?.measurement
											const quantityCalcProduct = props.selectedCalculator.calculation.products.find(
												(x) => x.productDefinitionId === suggestedProduct.productId,
											)
											if (measurement && quantityCalcProduct) {
												suggestedProduct.selectedAmount = getAmountNeeded(
													measurement,
													quantityCalcProduct,
												)
											}

											if (suggestedProduct.selectedAmount <= 0) {
												suggestedProduct.selectedAmount = 1
											}
											suggestedProducts[index] = suggestedProduct
										}
									}

									return [...suggestedProducts]
								})
								setOpenedCalcProductDropdown(null)
							}}>
							{calcProductPackaging.packagingMethod.name}
						</div>
					)
				})}
			</div>
		)
	}

	function createBasketFromSuggestedProducts() {
		const customProducts = props.selectedCalculator.calculation?.customProducts
		const hasCustomProducts = isArray(customProducts) && customProducts.length > 0

		if (hasCustomProducts) {
			return
		}

		EventQueue.dispatchEvent("quantityCalculatorBasket", suggestedProducts)
		navigator.open("order")
	}

	function addressPricesBox() {
		const customProducts = props.selectedCalculator.calculation?.customProducts
		if (isArray(customProducts) && customProducts.length > 0) {
			// If we have custom products display nothing regarding prices and/or adding to basket,
			return null
		}

		if (!consumerCatalog.pricesEnabled) {
			const disabled = some(suggestedProducts, (x) => {
				return x.type === "Custom" || !x.selectedPackagingMethod || x.selectedAmount <= 0
			})
			return (
				<div className={cls(style.summaryBox, style.addressBoxPricesDisabled)}>
					<div className={style.title} style={{ marginBottom: 0 }}>
						Skapa varukorg
					</div>
					<div className={style.subTitle} style={{ marginBottom: "10px" }}>
						Klicka på knappen nedan för att automatiskt skapa en varukorg baserat på de föreslagna artiklarna
					</div>
					<AccentButton
						disabled={disabled}
						style={{ maxWidth: "220px", marginLeft: "auto", marginTop: "auto" }}
						onClick={() => {
							createBasketFromSuggestedProducts()
						}}>
						Lägg till i varukorgen
					</AccentButton>
					{disabled ? (
						<span className={style.disabledButtonText}>Välj en tjänst för alla artiklar först</span>
					) : null}
				</div>
			)
		}

		const someHiddenPrices = some(suggestedProducts, (x) => {
			return (
				x.type === "Goods" &&
				(x.selectedPackagingMethod?.priceData?.type === "Hide" ||
					x.selectedPackagingMethod?.priceData?.type === "DisabledWithCallUs")
			)
		})

		if (someHiddenPrices) {
			return (
				<div className={cls(style.summaryBox, style.addressBoxPricesDisabled)}>
					<div className={style.title} style={{ marginBottom: "10px" }}>
						Beställning ej möjlig
					</div>
					<div className={style.subTitle} style={{ marginBottom: "10px" }}>
						En eller flera priser saknas i de föreslagna produkterna med de nuvarande valda tjänsterna och
						beställning är tyvärr inte möjlig just nu.
					</div>
					<div className={style.subTitle} style={{ marginBottom: "10px" }}>
						Försök gärna igen senare eller kontakta oss för mer information.
					</div>
				</div>
			)
		}

		if (!googleMapsLoaded) {
			return null
		}

		return (
			<div className={cls(style.summaryBox, style.addressBox)}>
				<div className={style.title} style={{ marginBottom: "5px" }}>
					Ange adress
				</div>
				<div className={style.subTitle} style={{ marginBottom: "25px" }}>
					Ange din adress för att se sammanfattade priser inklusive transportkostnaden
				</div>

				{selectedAddress?.text ? (
					<div className={style.selectedAddressInputBox}>
						<span>{selectedAddress.text}</span>
						<PenIcon
							size={22}
							className={style.editIcon}
							onClick={() => {
								setSelectedAddress(null)
							}}
						/>
					</div>
				) : (
					<Autocomplete
						onLoad={(x) => (autocompleteRef.current = x)}
						restrictions={{ country: "se" }}
						onPlaceChanged={() => {
							const ref = autocompleteRef.current
							const inputRef = autoCompleteInputRef.current
							if (ref && inputRef) {
								setSelectedAddress({ place: ref.getPlace(), text: inputRef.value })
							}
						}}>
						<div className={style.inputWrapper} style={{ width: "100%" }}>
							<input
								ref={autoCompleteInputRef}
								type={"text"}
								className={style.input}
								autoComplete={"address-line1"}
								placeholder="Någongata 17"
								style={{ width: "100%", maxWidth: "unset" }}
							/>
							<span style={{ top: "22px" }}>
								<PinNoFillIcon size={26} className={style.addressIcon} />
							</span>
						</div>
					</Autocomplete>
				)}
				{priceSummarySection()}
			</div>
		)
	}

	function priceSummarySection(): JSX.Element | null {
		const location = selectedAddress?.place?.geometry?.location
		const transportPricing = consumerCatalog.transportPricing
		if (!consumerCatalog.pricesEnabled || !transportPricing || !location) {
			return null
		}

		const groupedByTransport = groupBy(suggestedProducts, (x) => {
			if (x.type === "Custom") {
				return "__nothing__"
			}

			const method = x.selectedPackagingMethod

			if (method) {
				return method.packagingMethod.transportationId
			}

			return "__nothing__"
		})
		delete groupedByTransport["__nothing__"]

		const disabledButton = some(suggestedProducts, (x) => {
			return x.type === "Custom" || !x.selectedPackagingMethod || x.selectedAmount <= 0
		})

		return (
			<>
				<div className={style.subTitle} style={{ marginBottom: "30px", marginTop: "30px" }}>
					Observera att de priser som visas här kan skilja sig från det totala slutpriset i varukorgen, beroende
					på antalet transporter som krävs för att leverera materialet
				</div>
				<div className={style.priceSummarySections}>
					{Object.entries(groupedByTransport).map(([transportId, calcProducts]) => {
						const transportSearch: TreeSearchObject = new Map([])
						const zoneId = getZoneIdFromPoint(client, location.toJSON())
						const transportation = client.possibleTransportations[transportId]

						if (!transportation) {
							return null
						}

						let zoneElement = null

						if (zoneId) {
							transportSearch.set(BranchTargetKeyEnum.Transportation, transportId)
							transportSearch.set(BranchTargetKeyEnum.Zone, zoneId)
						} else {
							zoneElement = (
								<div className={style.zoneRow}>
									Transportzon - <strong>Ej identifierbar zon</strong>
								</div>
							)
						}

						const article = ArticleResolverService.resolveArticleFromTree(
							transportSearch,
							transportPricing,
						) as ResolvedArticle | null

						if (zoneId && !article) {
							const transportZone = client.transportZones[zoneId]

							if (transportZone) {
								zoneElement = (
									<>
										<div className={style.zoneRow}>
											Transportzon - <strong>{transportZone.name}</strong>
										</div>
										<div>
											<strong>Pris ej identifierat</strong>
										</div>
									</>
								)
							}
						}

						if (zoneId && article) {
							const transportZone = client.transportZones[zoneId]

							if (transportZone) {
								zoneElement = (
									<>
										<div className={style.zoneRow}>
											Transportzon - <strong>{transportZone.name}</strong>
										</div>
										<div>{currencyFormatter(article.price * (1 + article.taxPercentage))}</div>
									</>
								)
							}
						}

						return (
							<div key={transportId} className={style.priceSummarySection}>
								<div className={style.priceSummaryTransport}>
									<div>
										<Tooltip
											id="transportMethodTooltip"
											variant={"light"}
											className={style.tooltipWrapper}
											classNameArrow="tooltipArrowBorder"
											openOnClick={true}
										/>
										<div
											data-tooltip-id="transportMethodTooltip"
											data-tooltip-html={renderToStaticMarkup(
												<div>
													<div style={{ fontSize: "20px", fontWeight: 600 }}>
														{transportation.name}
													</div>
													<TransportDescription
														id={"transportMethodTooltipSVG" + transportation.id}
														type={
															transportation.transportationDetails.type as TransportationType
														}
														data={getDataForTransportSvg(transportation)}
													/>
												</div>,
											)}
											onClick={() => {
												// Kinda hacky way of setting the data inside the transport svg
												// But useEffect's aren't called when using renderToStaticMarkup
												// And since we need to use renderToStaticMarkup to render inside the tooltip
												// We need to do this to add the data
												setTimeout(() => {
													const element = document.getElementById(
														"transportMethodTooltipSVG" + transportation.id,
													) as SVGSVGElement | null
													if (element) {
														setTransportSvgDetails(
															element,
															getDataForTransportSvg(transportation),
														)
													}
												}, 100)
											}}
											data-tooltip-place="bottom">
											<div className={style.tooltipIcon}>i</div>
										</div>
									</div>
									<span style={{ marginLeft: "6px" }}>{transportation.name}</span>
								</div>
								<div className={style.priceSummaryZone}>{zoneElement}</div>
								<div className={style.priceSummaryProductRows}>
									{calcProducts.map((calcProduct) => {
										if (calcProduct.type === "Custom") {
											return null
										}

										const method = calcProduct.selectedPackagingMethod
										if (method) {
											let price = -1

											if (method.priceData.article) {
												price =
													method.priceData.article.resolvedLeaf.price *
													(1 + method.priceData.article.resolvedLeaf.taxPercentage)
												price = price * calcProduct.selectedAmount
											}
											return (
												<div
													key={calcProduct.productId + "_priceRow"}
													className={style.priceSummaryProductRow}>
													<div className={style.priceSummaryProductInfo}>
														<div>
															{actualProducts.current[calcProduct.productId]?.name || ""}
														</div>{" "}
														- <div>{method.packagingMethod.name}</div>
													</div>
													<div className={style.suggestedProductPrice}>
														{price > 0 && method ? currencyFormatter(price) : null}
													</div>
												</div>
											)
										}

										return null
									})}
								</div>
							</div>
						)
					})}
				</div>

				<AccentButton
					disabled={disabledButton}
					style={{ maxWidth: "220px", marginLeft: "auto", marginTop: "auto" }}
					onClick={() => {
						createBasketFromSuggestedProducts()
					}}>
					Gå vidare till varukorgen
				</AccentButton>
				{disabledButton ? (
					<span className={style.disabledButtonText}>Välj en tjänst för alla artiklar först</span>
				) : null}
			</>
		)
	}

	return (
		<div className={style.wrapper}>
			<div></div>
			<div className={style.content}>
				<div className={style.contentHeader}>
					<div className={style.title}>Summering</div>
					{disclaimerText(client.clientInfo.clientName)}
				</div>
				<div className={style.summaryWrapper}>
					<div className={style.twoBoxWrapper}>
						{totalWeightsBox()}
						{calculatedWeightsBox()}
					</div>
					{suggestedProductsBox()}
					{addressPricesBox()}
				</div>
				{props.selectedCalculator.widgets?.thirdStep ? (
					<div className={style.widgetSection}>
						<div className={style.widgetTitle}>{props.selectedCalculator.widgets.thirdStep.title}</div>
						<WidgetRendererV2 apiWidgets={[props.selectedCalculator.widgets.thirdStep.widget]} />
					</div>
				) : null}
			</div>
		</div>
	)
}
