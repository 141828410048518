import { map, toNumber } from "lodash"
import { FC, ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { v4 } from "uuid"
import expandableIncrementorStyle from "../ExpandableIncrementor/ExpandableIncrementor.module.css"
import style from "./ExpandableDropdown.module.css"

type DropdownProps = {
	getItems: (indexes: number[], max?: number) => DropdownItem[]
	unSelectionItemText?: string
	onChange: (item: DropdownItem | null, componentId: string) => void
	defaultValue: number | string | null
	max?: number
	disabled?: boolean
	currentValue?: { value: number; show: boolean }
}

export type DropdownItem = {
	key: string
	value: number | string
	disabled?: boolean
}

export const Dropdown: FC<DropdownProps> = ({ getItems, onChange, unSelectionItemText, defaultValue, max, disabled }) => {
	const items = getItems([...Array(50).keys()], max)
	const [selectedItem, setSelectedItem] = useState<DropdownItem | null>(
		items.find((x) => x.value === defaultValue) || null,
	)

	const componentId = useRef(v4())

	return (
		<select
			disabled={disabled}
			value={selectedItem?.value}
			className={style.dropdown}
			onChange={(event) => {
				const value = !isNaN(toNumber(event.target.value)) ? toNumber(event.target.value) : event.target.value
				const item = items.find((x) => x.value === value) || null
				setSelectedItem(item)
				onChange(item, componentId.current)
			}}>
			{unSelectionItemText ? (
				<option key={v4()} value={0}>
					{unSelectionItemText}
				</option>
			) : null}
			{map(items, (item) => {
				return (
					<option disabled={item.disabled} key={v4()} value={item.value}>
						{item.key}
					</option>
				)
			})}
		</select>
	)
}

export type ExpandableDropdownRefProps = {
	id: string
	reset: () => void
}

export const ExpandableDropdown = forwardRef((props: DropdownProps, ref: ForwardedRef<ExpandableDropdownRefProps>) => {
	const items = props.getItems([...Array(50).keys()], props.max)
	const [selectedItem, setSelectedItem] = useState<DropdownItem | null>(
		items.find((x) => x.value === props.defaultValue) || null,
	)

	const componentId = useRef(v4())

	useEffect(() => {
		if (props.currentValue !== undefined) {
			setSelectedItem(items.find((x) => x.value === props.currentValue?.value) || null)
		}
	}, [props.currentValue])

	useImperativeHandle(
		ref,
		() => ({
			id: componentId.current,
			reset() {
				setSelectedItem(items.find((x) => x.value === props.defaultValue) || null)
			},
		}),
		[items, props],
	)

	return selectedItem ? (
		<select
			disabled={props.disabled}
			value={selectedItem.value}
			className={style.dropdown}
			onChange={(event) => {
				const value = !isNaN(toNumber(event.target.value)) ? toNumber(event.target.value) : event.target.value
				const item = items.find((x) => x.value === value) || null
				setSelectedItem(item)
				props.onChange(item, componentId.current)
			}}>
			{props.unSelectionItemText ? (
				<option key={v4()} value={0}>
					{props.unSelectionItemText}
				</option>
			) : null}
			{map(items, (item) => {
				return (
					<option disabled={item.disabled} key={v4()} value={item.value}>
						{item.key}
					</option>
				)
			})}
		</select>
	) : (
		<button
			onClick={(event) => {
				event.preventDefault()
				event.stopPropagation()
				setSelectedItem(items[0] || null)
				props.onChange(items[0] || null, componentId.current)
			}}
			type="button"
			className={expandableIncrementorStyle.buttons}>
			+
		</button>
	)
})
