import { SendUserRequestModal } from "CustomerPortal/SendUserRequestModal/SendUserInvitationModal"
import { isUndefined } from "lodash"
import { convertTextToInitials } from "Orders/Helpers"
import { FC, useCallback, useEffect, useState } from "react"
import { useAuth } from "../../Auth/AuthContext"
import { Loader } from "../../Loader/Loader"
import { API } from "../../network/API"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import { ConsumerState } from "../CustomerPortalConsumerInformation/CustomerPortalConsumerInformation"
import { EditUser } from "../EditUser/EditUser"
import { GetUserInvitation, UserInvitations } from "../UserInvitations/UsersInvitations"
import style from "./Users.module.css"

export type GetConsumerUsersResponse = {
	users: GetUser[]
	consumerState: ConsumerState
}

type GetClientUsersResponse = {
	users: GetUser[]
}

export type GetUser = {
	id: string
	username: string
	email?: string
	firstName?: string
	lastName?: string
	role: UserRole
	profilePictureUrl?: string
	phoneNumber?: string
}

enum UserRole {
	Admin = "Admin",
	Normal = "Normal",
}

type Props = {
	type: "Consumer" | "Client"
	clientIdentifier: string
	consumerId?: string
}
export const Users: FC<Props> = ({ clientIdentifier, consumerId, type }) => {
	const auth = useAuth()
	const permissions = usePermissions()

	const [users, setUsers] = useState<GetUser[]>([])
	const [dataLoading, setDataLoading] = useState(true)
	const [showUser, setShowUser] = useState<GetUser | null>(null)
	const [, setTick] = useState(0)
	const [newInviteId, setNewInviteId] = useState("")

	const init = useCallback(() => {
		setDataLoading(true)

		if (type === "Client") {
			if (!permissions.isAllowed(PermissionAreaLocation.Client_Users_Read)) {
				setDataLoading(false)
				return
			}

			API.getWithRetries<GetClientUsersResponse>(`/customer-portal/users-v1/client/${clientIdentifier}`, true)
				.then((res) => {
					setUsers(res.users)
					setDataLoading(false)
				})
				.catch(() => {
					setUsers([])
					setDataLoading(false)
				})
		} else {
			if (!permissions.isAllowed(PermissionAreaLocation.Consumer_Users_Read)) {
				setDataLoading(false)
				return
			}

			if (!consumerId) {
				return
			}
			API.getWithRetries<GetConsumerUsersResponse>(
				`/customer-portal/users-v1/consumer/${clientIdentifier}/${consumerId}`,
				true,
			)
				.then((res) => {
					setUsers(res.users)
					setDataLoading(false)
				})
				.catch(() => {
					setUsers([])
					setDataLoading(false)
				})
		}
	}, [clientIdentifier, consumerId, type, permissions])

	useEffect(() => {
		init()
	}, [init])

	function editUserContent() {
		return showUser ? (
			<EditUser
				type={type}
				user={showUser}
				disableEditing={!!(auth.Me && showUser.id !== auth.Me.userId)}
				consumerId={consumerId}
				onClose={() => {
					setShowUser(null)
				}}
				onDone={() => {
					setShowUser(null)
					init()
				}}
				onProfilePicChange={(userId, url) => {
					if (users.find((x) => x.id === userId)) {
						users.find((x) => x.id === userId)!.profilePictureUrl = url
						setUsers(users)
					}
				}}
			/>
		) : null
	}

	function usersContent() {
		if (!permissions.isAllowed(PermissionAreaLocation.Consumer_Users_Read)) {
			return null
		}

		return (
			<>
				<div style={{ marginBottom: "1.5rem" }} className={style.header}>
					Användare
				</div>
				<div className={style.usersWrapper}>
					{users.map((user) => {
						let pfpElement

						if (user.profilePictureUrl) {
							pfpElement = (
								<img
									src={user.profilePictureUrl}
									onError={() => {
										user.profilePictureUrl = undefined
										setTick((tick) => tick + 1)
									}}
									alt="Profilbild"
								/>
							)
						} else {
							pfpElement = (
								<div className={style.noProfilePic}>
									{convertTextToInitials(
										user.firstName || user.lastName
											? user.firstName + " " + user.lastName
											: user.username,
									)}
								</div>
							)
						}
						return (
							<div
								key={user.id}
								className={style.userRow}
								onClick={() => {
									setShowUser(user)
								}}>
								<div className={style.profilePic}>{pfpElement}</div>
								<div className={style.userRowName}>
									<div style={{ fontWeight: 600 }}>
										{!user.firstName && !user.lastName
											? user.username
											: `${user.firstName} ${user.lastName}`}
									</div>
									<div style={{ wordBreak: "break-word" }} className={style.twoLineClamp}>
										{user.email}
									</div>
								</div>
							</div>
						)
					})}
				</div>
			</>
		)
	}

	function inviteUserContent() {
		if (
			type !== "Consumer" ||
			isUndefined(consumerId) ||
			!permissions.isAllowed(PermissionAreaLocation.Consumer_User_Invitations_Create)
		) {
			return null
		}

		return (
			<SendUserRequestModal
				externalButton={false}
				onCreate={(newInvite: GetUserInvitation) => {
					setNewInviteId(newInvite.id)
				}}
				consumerRef={consumerId}
			/>
		)
	}

	function userInvitesContent() {
		if (
			type !== "Consumer" ||
			isUndefined(consumerId) ||
			!permissions.isAllowed(PermissionAreaLocation.Consumer_User_Invitations_Read)
		) {
			return null
		}

		return <UserInvitations clientIdentifier={clientIdentifier} consumerId={consumerId} newInviteId={newInviteId} />
	}

	function allUserStuffDisallowed() {
		return (
			!permissions.isAllowed(PermissionAreaLocation.Consumer_Users_Read) &&
			!permissions.isAllowed(PermissionAreaLocation.Consumer_User_Invitations_Read) &&
			!permissions.isAllowed(PermissionAreaLocation.Consumer_User_Invitations_Create)
		)
	}

	if (allUserStuffDisallowed()) {
		return null
	}

	return (
		<div className={style.wrapper}>
			{dataLoading ? (
				<div style={{ display: "flex", justifyContent: "center" }}>
					<Loader />
				</div>
			) : (
				<>
					{editUserContent()}
					{usersContent()}
				</>
			)}

			{inviteUserContent()}
			{userInvitesContent()}
		</div>
	)
}
