import log from "loglevel"
import Lottie from "lottie-react"
import React, { MutableRefObject, PropsWithChildren } from "react"
import { AbsolutCentered } from "../AbsolutCentered/AbsolutCentered"
import { Loader } from "../Loader/Loader"
import { LogEntry } from "../Logging/RemoteLogPusher"
import { sendLogEntries } from "../Logging/remoteLogSender"
import { fixLottieColor } from "../Lottie/Helpers"
import oops from "../Lottie/Oops.json"
import { API } from "../network/API"
import { parseNumberSafe } from "../Orders/Helpers"
import { anyToLoggableString } from "../Shared/anyToLoggableString"
import { cls } from "../Shared/cls"
import style from "./ErrorBoundary.module.css"
import { objectOfError } from "./objectOfError"

const MAX_RELOAD_AMOUNT = 25

export function getReloadCount() {
	try {
		return parseNumberSafe(localStorage.getItem("error-reload-count"), 0)
	} catch (e) {
		return 0
	}
}

function incrementReloadCount() {
	localStorage.setItem("error-reload-count", (getReloadCount() + 1).toString())
}

export function resetReloadCount() {
	localStorage.setItem("error-reload-count", "0")
}

export function incrementReloadCountAndReloadPage() {
	incrementReloadCount()
	window.location.reload()
}

export function reloadCountLimitReached(): boolean {
	return getReloadCount() >= MAX_RELOAD_AMOUNT
}

/**
 * This component doesn't have a logger, since if we end up here, something is broken, and we cannot really rely on anything else.
 */

type ErrorBoundaryProps = PropsWithChildren
export type ErrorBoundaryState = {
	hasError: boolean
	reportSuccessful?: boolean
	showLoader?: boolean
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	private readonly reloadButtonRef: MutableRefObject<HTMLButtonElement | null>
	private readonly reloadAndClearButtonRef: MutableRefObject<HTMLButtonElement | null>

	constructor(props: any) {
		super(props)
		this.state = { hasError: false, reportSuccessful: undefined }

		this.reloadButtonRef = React.createRef()
		this.reloadAndClearButtonRef = React.createRef()
	}

	static getDerivedStateFromError(error: Error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true }
	}

	private promiseRejectionHandler = (event: PromiseRejectionEvent) => {
		this.setState({
			...this.state,
			hasError: true,
		})
		this.logUncaughtError(event.reason, undefined)
	}

	private async logUncaughtError(error: Error, errorInfo: React.ErrorInfo | undefined) {
		if (process.env.NODE_ENV !== "production") {
			function printStackTrace(error: any) {
				console.error("", error)
				if (error.cause) {
					console.group("  caused by:")
					printStackTrace(error.cause)
					console.groupEnd()
				}
			}

			printStackTrace(error)
		}

		if (process.env.NODE_ENV === "production" || process.env.REACT_APP_POST_ERROR_TO_BACKEND === "true") {
			const errorObject = objectOfError(error)
			const dumpedLocalStorage = this.dumpLocalStorage()

			const postData: { [key: string]: any } = {
				error: errorObject,
				errorInfo: errorInfo,
				location: window.location,
				localStorage: dumpedLocalStorage,
			}

			let shouldReloadPage = false

			const errorAsString = anyToLoggableString(errorObject)

			/*
				If the error name is ChunkLoadError the user probably has a shitty internet connection
				and one or more chunks failed to load, as such we reload the page.

				If the error as a string contains webkit-masked-url anywhere inside of it, it's most likely
				an error from some browser plugin (safari if google doesn't lie) that for some reason triggered
				this boundary.

				For both of these cases we reload the page instead of keeping the user on the crash screen,
				however, we still log them, just in case they are actually our fault.
			 */
			if (errorObject?.name === "ChunkLoadError" || errorAsString?.includes("webkit-masked-url")) {
				shouldReloadPage = true
			}

			if (reloadCountLimitReached()) {
				shouldReloadPage = false

				postData["was-reloaded"] =
					"This error was uncaught and triggered the ErrorBoundary, but reload the limit was reached, so the crash screen was shown." +
					"It's most likely not an error caused by Skrappy code."
			}

			if (shouldReloadPage) {
				postData["was-reloaded"] =
					"This error was uncaught and triggered the ErrorBoundary but we reloaded the page instead of keeping the user on the crash screen." +
					"It's most likely not an error caused by Skrappy code."
				this.setState({ ...this.state, showLoader: true })
			} else {
				this.setState({ ...this.state, showLoader: false })
			}

			await API.post("/errors-v1/uncaught", postData)
				.then(() => {
					this.setState({ ...this.state, reportSuccessful: true })
				})
				.catch((failure) => {
					this.setState({ ...this.state, reportSuccessful: false })
					log.error(failure)
				})
				.finally(() => {
					if (shouldReloadPage) {
						setTimeout(() => {
							incrementReloadCountAndReloadPage()
						}, 500)
					}
				})
		}
	}

	private dumpLocalStorage() {
		try {
			let redactedLocalStorageDump = Object.entries(localStorage).map(([key, value]) => {
				if (key.endsWith(".auth-token")) {
					return [key, "*******"]
				}
				return [key, value]
			})
			return Object.fromEntries(redactedLocalStorageDump)
		} catch (error) {
			//Ignore
			return null
		}
	}

	private async logLocalDataClearAndThenReload() {
		const reloadButton = this.reloadButtonRef.current
		if (reloadButton) {
			reloadButton.disabled = true
		}

		const reloadAndClearButton = this.reloadAndClearButtonRef.current
		if (reloadAndClearButton) {
			reloadAndClearButton.disabled = true
		}

		const logEntry: LogEntry = {
			logger: "ErrorBoundary",
			logLevel: "debug",
			logCount: 1,
			dupeCount: 0,
			clientIdentifier: null,
			message: [
				"User was shown crash screen and clicked the reload page while also clearing all localstorage data button.",
			],
		}
		await sendLogEntries([logEntry]).catch(() => {})
		localStorage.clear()
		window.location.reload()
	}

	componentDidMount() {
		window.addEventListener("unhandledrejection", this.promiseRejectionHandler)
	}

	componentWillUnmount() {
		window.removeEventListener("unhandledrejection", this.promiseRejectionHandler)
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		this.logUncaughtError(error, errorInfo)
	}

	render() {
		if (this.state.showLoader) {
			return (
				<AbsolutCentered>
					<Loader />
				</AbsolutCentered>
			)
		} else if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div className={style.wrapper}>
					<h1 className={style.header}>
						Oops!
						<br />
						Något gick fel.
					</h1>
					<p className={style.description}>
						Vi håller på att åtgärda problemet.
						<br />
						Testa att{" "}
						<button
							ref={this.reloadButtonRef}
							type="button"
							onClick={() => {
								resetReloadCount()
								window.location.reload()
							}}
							style={{ cursor: "pointer" }}>
							ladda om sidan
						</button>{" "}
						eller kom tillbaka senare.
					</p>
					<p className={style.description}>
						Om felet kvarstår.
						<br />
						Testa att{" "}
						<button
							ref={this.reloadAndClearButtonRef}
							type="button"
							onClick={() => {
								this.logLocalDataClearAndThenReload()
							}}
							style={{ cursor: "pointer" }}>
							rensa lokal data och ladda om
						</button>{" "}
						eller kom tillbaka senare.
					</p>
					<p className={style.subDescription}>
						Om du rensar lokal data blir du utloggad och så kommer du behöva logga in igen!
					</p>
					<div className={style.lottieWrapper}>
						<Lottie animationData={fixLottieColor(oops)} autoPlay loop />
					</div>
					<div
						className={cls(
							style.reportStatus,
							{ [style.reportSuccessful]: this.state.reportSuccessful === true },
							{ [style.reportFailed]: this.state.reportSuccessful === false },
						)}
					/>
				</div>
			)
		}

		return this.props.children
	}
}
